import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Defect } from '../../../models/defects';
import { DashboardState } from '../../../models';
import remarksIcon from '../../../assets/icon-remarks.svg';
import placardsIcon from '../../../assets/icon-placards.svg';
import opIcon from '../../../assets/icon-operational-procedure.svg';
import Card from './Card';
import ConfirmationCard from './ConfirmationCard';
import Label from './Label';
import FlexWrapper from './FlexWrapper';
import StyledInput from './StyledInput';

interface ConfirmSectionProps {
  defect: Defect | null;
  onChange?: (confirmationStatuses: object) => void;
  updateDefectData: (changes: { key: string; value: any }[]) => void;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({ defect, onChange, updateDefectData }) => {
  const [remarks, setRemarks] = useState('');
  const [placards, setPlacards] = useState('');
  const [operationalProcedures, setOperationalProcedures] = useState('');
  const [maintenanceProcedures, setMaintenanceProcedures] = useState('');
  const [confirmationStatuses, setConfirmationStatuses] = useState({});

  const updateConfirmationStatuses = (changeObject: object): void => {
    const newConfirmationStatuses = {
      ...confirmationStatuses,
      ...changeObject,
    };
    const newKey = Object.keys(changeObject);

    updateDefectData([{ value: true, key: newKey[0] }]);
    setConfirmationStatuses(newConfirmationStatuses);
  };

  useEffect(() => {
    if (onChange) {
      onChange(confirmationStatuses);
    }
  }, [onChange, confirmationStatuses]);

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const showRichTextMel = aircraftMap.get(id)?.standard_fields?.rich_text_mel?.enabled;

  const { formatMessage } = useIntl();
  const placardsExtraContent = (
    <FlexWrapper marginTop={30} column>
      <Label fontWeight={600} marginBottom={5}>
        {formatMessage({ id: 'text.placardsNumberOptional' })}
      </Label>
      <StyledInput id="placards_number" type="text" placeholder="Please enter placards number..." />
    </FlexWrapper>
  );

  useEffect(() => {
    const statuses = {} as any;

    if (remarks) {
      statuses.remarksCard = {
        isChecked: false,
      };
    }

    if (placards) {
      statuses.placardsCard = {
        isChecked: false,
      };
    }

    if (operationalProcedures) {
      statuses.opsCard = {
        isChecked: false,
      };
    }

    if (maintenanceProcedures) {
      statuses.maintenanceCard = {
        isChecked: false,
      };
    }

    setConfirmationStatuses(statuses);
  }, [remarks, placards, operationalProcedures, maintenanceProcedures]);

  useEffect(() => {
    if (defect && defect.mel_item) {
      const { mel_item } = defect;
      if (showRichTextMel) {
        setRemarks(mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)?.remarks_json);
        setPlacards(
          mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)?.placard_procedure_json,
        );
        setOperationalProcedures(
          mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)
            ?.operational_procedure_json,
        );
        setMaintenanceProcedures(
          mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)
            ?.maintenance_procedure_json,
        );
      } else {
        setRemarks(mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)?.remarks);
        setPlacards(
          mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)?.placard_procedure,
        );
        setOperationalProcedures(
          mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)?.operational_procedure,
        );
        setMaintenanceProcedures(
          mel_item?.mel_rectifications?.find((rec) => rec.id === defect?.mel_rectification_id)?.maintenance_procedure,
        );
      }
    }
  }, [defect, showRichTextMel]);

  return (
    <>
      {((remarks && remarks.length > 0) ||
        (placards && placards.length > 0) ||
        (operationalProcedures && operationalProcedures.length > 0)) && (
        <Card marginTop={30}>
          <Label fontWeight={600} identifier="ConfirmSectionTitle">
            {formatMessage({ id: 'text.confirmReviewedAndActioned' })}
          </Label>
          {remarks && !remarks.includes(null) && (
            <ConfirmationCard
              id="remarksCard"
              title="Remarks"
              text={remarks}
              icon={remarksIcon}
              onChange={updateConfirmationStatuses}
            />
          )}
          {placards && !placards.includes(null) && (
            <ConfirmationCard
              id="placardsCard"
              title="Placards"
              text={placards}
              icon={placardsIcon}
              extraContent={placardsExtraContent}
              onChange={updateConfirmationStatuses}
            />
          )}
          {operationalProcedures && !operationalProcedures.includes(null) && (
            <ConfirmationCard
              id="opsCard"
              title="Operational Procedures"
              text={operationalProcedures}
              icon={opIcon}
              onChange={updateConfirmationStatuses}
            />
          )}
          {maintenanceProcedures && !maintenanceProcedures.includes(null) && (
            <ConfirmationCard
              id="maintenanceCard"
              title="Maintenance Procedures"
              text={maintenanceProcedures}
              icon={opIcon}
              onChange={updateConfirmationStatuses}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default ConfirmSection;
