/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { queryMaintenance } from '../../../services/api';
import { getFlightsForAircraft } from '../../../models/flights/actions';
import { getAllAircraft } from '../../../models/aircraft/actions';
import { DashboardState } from '../../../models';
import Loading from '../../TFLoading/index';
import TFSearchableSelect from '../../TFSearchableSelect/TFSearchableSelect';
import PublicShareLink from '../../PublicShareLink/PublicShareLink';
import DocumentLink from '../../DocumentLink/DocumentLink';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
  setDrawerId,
} from '../../../models/drawer';
import FlightDrawer from '../../FlightDrawer/FlightDrawer';
import { Defect } from '../../../models/defects';
import backArrow from '../../../assets/icon-back.svg';
import StatusBadge from '../../Status/StatusBadge';
import { AircraftAuthenticationWrapper, hasAircraftPermission } from '../../_utils/AuthenticationWrapper';
import getOperatorSetting from '../../../utils/getOperatorSetting';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import AuthDropdownMenu from '../../AuthDropdownMenu/AuthDropdownMenu';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import DrawerBanner from '../../TripDrawer/DrawerBanner';
import CRSButton from '../../CRSButton/CRSButton';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import styles from './DefectHeader.module.less';

interface DefectHeaderProps {
  defect: Defect | null;
  acId: string;
  setAircraftId: (value: string) => void;
  updateDefectData?: (changes: any[]) => void;
  disableFlightSelection?: boolean;
  editResolvedLocal?: boolean;
}

const Header = styled.div``;

const DefectTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile, width }): string => (isMobile ? '150px' : `${width}` || 'auto')};
  column-span: ${({ colSpan, editDefect }): string => (colSpan && editDefect ? `${colSpan}` : 'none')};
  span {
    display: flex;
    align-items: center;
    text-transform: ${({ viewMode }): string => (viewMode ? 'uppercase' : 'none')};
  }
  margin-right: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '20px')};
`;

const StyledLinkWrapper = styled.div`
  border-left: ${({ tripId }): string => (tripId ? 'solid 1px rgba(36, 45, 65, 0.2)' : '')};
  padding-left: 6px;
  margin-left: 5px;
  position: relative;
  min-width: ${({ res }): string => (res ? '125px' : '25px')};
  a {
    color: #126fd6;
    flex-shrink: 1;
  }
`;

const DocumentLinkDivider = styled.div`
  border-right: solid 1px rgba(36, 45, 65, 0.2);
  padding-right: 6px;
  margin-right: 5px;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: ${({ flex }): string => (flex ? 'flex' : 'block')};
  flex-direction: ${({ column }): string => (column ? 'column' : 'row')};
`;

const DateWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: -25px;
  @media (max-width: 450px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const StatusText = styled.span`
  flex-shrink: 0;
`;

const StyledButton = styled.button`
  color: #126fd6;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  padding: 0;
`;

const StyledBackButton = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  svg {
    height: 20px;
    width: 15px;
  }
`;

const ThirdPartyWrapper = styled.span`
  font-size: 18px;
  margin-left: 4px;
  span {
    margin-left: 4px;
  }
`;

const DefectHeader: React.FC<DefectHeaderProps> = ({
  defect,
  acId,
  updateDefectData,
  setAircraftId,
  disableFlightSelection,
  editResolvedLocal,
}) => {
  const [flights, setFlights] = useState([]);
  const [chosenFlight, setChosenFlight] = useState(null);
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [chosenAircraft, setChosenAircraft] = useState(null);
  const [tripId, setTripId] = useState('');
  const [aircraftLoading, setAircraftLoading] = useState(false);
  const [flightLoading, setFlightLoading] = useState(false);
  const [sector, setSector] = useState('');
  const [tripNumber, setTripNumber] = useState('');
  const [localFlightTitles, setLocalFlightTitles] = useState([]);
  const [mxItems, setMxItems] = useState(null);
  const [defectStatusText, setDefectStatusText] = useState(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap, lastFetched: aircraftLastFetched = 0 },
    flights: { aircraftFlightsMaps },
    drawer: { mode, drawerHistory, editResolved },
    userSettings,
  } = useSelector((state: DashboardState) => state);
  const { flightsMap = new Map() } =
    aircraftFlightsMaps.get(chosenAircraft?.value) || aircraftFlightsMaps.get(defect?.aircraft?.id) || {};

  const operatorSettings = userSettings?.details?.operators.find(
    (op) => op.id === aircraftMap.get(id)?.operator_id,
  )?.operator_setting;

  const sectorName = getOperatorSetting('sector_name', id);

  const updateFlightDetails = (): void => {
    if (defect?.flight_id) {
      const newFlight = flights.find((flight) => flight.id === defect?.flight_id);
      if (newFlight) {
        const {
          sector_number,
          trip: { number },
        } = newFlight;
        const newChosenFlight = localFlightTitles.find((flight) => flight.value === newFlight.id);
        setSector(sector_number);
        setTripNumber(number);
        setChosenFlight(newChosenFlight);
      }
    } else {
      setSector(null);
      setTripNumber(null);
    }
  };

  const handleChangeOfFlight = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenFlight(option);
    updateDefectData([{ value: option.value, key: 'flight_id' }]);
  };

  const handleChangeOfAircraft = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenAircraft(option);
    setAircraftId(option.value);
  };

  const handleDateChange = (newDate): void => {
    updateDefectData([{ value: newDate, key: 'raised_at' }]);
  };

  const handleDeferDefect = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
    updateDefectData([{ value: true, key: 'deferred' }]);
  };

  const handleBackClick = (): void => {
    dispatch(handleBackButtonClick());
  };

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevFlights = usePrevious(aircraftFlightsMaps);

  useEffect(() => {
    if (aircraftFlightsMaps.get(chosenAircraft?.value)) {
      setFlightLoading(false);
    }
  }, [aircraftFlightsMaps, prevFlights]);

  useEffect(() => {
    if (defect?.flight_id && flights.length) {
      updateFlightDetails();
    }
  }, [flights, defect?.flight_id]);

  useEffect(() => {
    if (chosenFlight) {
      updateFlightDetails();
    }
  }, [chosenFlight]);

  useEffect(() => {
    setFlights([]);
    setTripNumber('');
    setSector('');
    setChosenFlight(null);
    if (defect && !defect?.raised_at) {
      updateDefectData([{ value: moment.utc().toISOString(), key: 'raised_at' }]);
    }
  }, [defect]);

  useEffect(() => {
    if (aircraftOptions?.length) {
      if (defect?.aircraft?.id) {
        const existingAircraft = aircraftOptions.find((aircraft) => aircraft?.value === defect?.aircraft?.id);
        if (existingAircraft) {
          setChosenAircraft(existingAircraft);
          setAircraftId(existingAircraft.value);
        }
      } else if (acId) {
        setChosenAircraft(aircraftOptions.find((aircraft) => aircraft?.value === acId));
      } else {
        setChosenAircraft(aircraftOptions[0]);
      }
    } else {
      setChosenAircraft({ value: aircraftMap.get(id)?.id });
    }
  }, [aircraftOptions, defect]);

  useEffect(() => {
    if (chosenAircraft?.value) {
      setFlightLoading(true);
      dispatch(getFlightsForAircraft({ payload: { id: chosenAircraft?.value } }));
    }
  }, [chosenAircraft?.value]);

  useEffect(() => {
    const createFlightTitle = (input): void => {
      let currentFlightIndex = 0;
      const titleArray = input.map((flight, index) => {
        if (flight.id === defect?.flight_id) {
          currentFlightIndex = index + 1;
        }

        return {
          title: `${flight.departure_airport}${
            flight.time_takeoff !== null ? `(${moment(flight.time_takeoff).utc().format('HH:mm')})` : ''
          }-${flight.arrival_airport || flight.actual_arrival_airport}${
            flight.time_landing !== null ? `(${moment(flight.time_landing).utc().format('HH:mm')})` : ''
          } ${flight?.trip?.date}`,
          value: flight.id,
        };
      });
      titleArray.unshift({ title: 'None', value: null });
      setLocalFlightTitles(titleArray);
      setChosenFlight(titleArray[currentFlightIndex]);
      if (defect?.flight_id && defect?.flight_id !== titleArray[currentFlightIndex].value)
        updateDefectData([{ value: titleArray[currentFlightIndex].value, key: 'flight_id' }]);
    };
    if (flightsMap && flightsMap.size !== 0 && chosenAircraft) {
      const values = Array.from(flightsMap.values());
      let filteredValues = [];
      if (chosenAircraft) {
        filteredValues = values
          .filter((item) => item?.aircraft?.id === chosenAircraft?.value)
          .sort((a, b) => {
            if (a.trip.date === b.trip.date) {
              return moment(b.time_takeoff).diff(moment(a.time_takeoff));
            }
            return moment(b.trip.date, 'YYYY-MM-DD').diff(moment(a.trip.date, 'YYYY-MM-DD'));
          });
      }
      createFlightTitle(filteredValues);
      setFlights(filteredValues);
      updateFlightDetails();
    } else if (flightsMap && flightsMap.size === 0 && chosenAircraft && localFlightTitles?.length !== 0) {
      setLocalFlightTitles([]);
    }
  }, [flightsMap, chosenAircraft]);

  useEffect(() => {
    if (flights.length > 0) {
      const currentFlight = flights.find((flight) => flight.id === defect?.flight_id);
      if (currentFlight) {
        setTripId(currentFlight?.trip?.id);
      } else {
        setTripId(null);
      }
    }
  }, [defect?.flight_id, flights, tripId]);

  useEffect(() => {
    if (chosenFlight?.value !== null && chosenFlight?.value !== defect?.flight_id) {
      const foundFlight = flights.find((item) => item.id === chosenFlight.value);
      if (foundFlight) {
        const newDate = foundFlight?.time_landing;
        updateDefectData([{ value: newDate, key: 'raised_at' }]);
      }
    }
  }, [chosenFlight?.value]);

  useEffect(() => {
    if (Array.from(aircraftMap.values()).length === 0 && Date.now() - aircraftLastFetched >= 300000) {
      setAircraftLoading(true);
      getAllAircraft();
    }
  }, [aircraftLastFetched, aircraftMap]);

  useEffect(() => {
    if (Array.from(aircraftMap.values()).length > 0) {
      setAircraftLoading(false);
    }
  }, [aircraftMap]);

  useEffect(() => {
    if (id) {
      const res = queryMaintenance({ aircraft_id: id });
      Promise.all([res]).then((data: any) => {
        if (data[0]) setMxItems(data[0]?.scheduled_mx_items);
      });
    }
  }, [id, defect?.status]);

  useEffect(() => {
    if (defect?.status === 'deferral_pending') {
      setDefectStatusText('Deferral pending');
    } else {
      setDefectStatusText(defect?.status);
    }
  }, [defect, mxItems]);

  useEffect(() => {
    if (document.location.pathname === '/defects') {
      const createAircraftOptions = (values): void => {
        const newAircraftOptions = [];
        values.forEach((aircraft) => {
          const displayName = {
            title: `${aircraft?.registration} (${aircraft?.aircraft_type.designator})`,
            value: aircraft.id,
          };
          newAircraftOptions.push(displayName);
        });
        newAircraftOptions.sort((a, b) => a.title.localeCompare(b.title));
        setAircraftOptions(newAircraftOptions);
        if (mode === 'add') {
          const [selectAircraft = newAircraftOptions[0]] = newAircraftOptions.filter((item) => item.value === acId);
          setChosenAircraft(selectAircraft);
          setAircraftId(selectAircraft.value);
        }
      };
      if (aircraftMap && Array.from(aircraftMap.values()).length > 0) {
        const values = Array.from(aircraftMap.values()).filter((item) => {
          const usersPermissions = userSettings?.details?.people?.find((person) =>
            person?.permission_groups[1]?.aircraft.includes(item?.id),
          );
          return hasAircraftPermission(
            item,
            AircraftResource.AIRCRAFT,
            AircraftPermission.READ,
            usersPermissions?.permission_groups[1],
          );
        });
        createAircraftOptions(values);
      }
    }
  }, [aircraftMap, defect]);

  const isMobile = window.innerWidth < 451;

  const hasRectificationIntervalExtension = (): null | object => {
    return defect?.rectification_interval_extension;
  };

  const hasExtensionDateDue = (): string => {
    return hasRectificationIntervalExtension() && defect?.rectification_interval_extension.extension_date_due;
  };

  const drawerBanner = (): ReactElement | null => {
    if (hasRectificationIntervalExtension() && !hasExtensionDateDue()) {
      return <DrawerBanner message={formatMessage({ id: 'text.defectWillNotExpire' })} />;
    }

    return null;
  };

  const displayThirdPartyReferences = () => {
    if (
      (defect?.third_party_references && Object.entries(defect?.third_party_references).length === 0) ||
      !defect?.third_party_references
    ) {
      return null;
    }
    return (
      <ThirdPartyWrapper data-testid="DefectHeader--ThirdPartyReferences">
        {Object.entries(defect?.third_party_references).map(([key, value]) => {
          return (
            <span key={key}>
              {`- ${key}:`} <strong>{`${value}\n`}</strong>
            </span>
          );
        })}
      </ThirdPartyWrapper>
    );
  };

  let defectButtonText = formatMessage({ id: 'form.button.addCRS' }, { field: operatorSettings?.crs_short_name });
  if (operatorSettings?.approval_release_mode === 'after_any_work_completed_release') {
    defectButtonText = formatMessage({ id: 'form.button.submitResolution' });
    if (defect?.status === 'resolved')
      defectButtonText = formatMessage({ id: 'form.button.addCRS' }, { field: operatorSettings?.crs_short_name });
  } else if (defect?.status === 'resolved') {
    defectButtonText = formatMessage({ id: 'form.button.viewCRS' }, { field: operatorSettings?.crs_short_name });
  }

  return (
    <Header>
      <DefectTitle id="DefectTitle" data-testid="DefectHeader--Title">
        {drawerHistory?.length ? (
          <StyledBackButton onClick={handleBackClick}>
            <img src={backArrow} alt="back arrow" />
          </StyledBackButton>
        ) : null}
        {mode === 'edit' || mode === 'add' ? (
          <>
            {defect && Object.keys(defect).length > 0 && (
              <span>
                {mode === 'add'
                  ? formatMessage({ id: 'menu.Add.Defect' }, { field: operatorSettings?.defect_name })
                  : formatMessage({ id: 'title.editDefect' }, { field: operatorSettings?.defect_name })}
                <strong>{defect?.number}</strong>
              </span>
            )}
            {!defect && (
              <span>{formatMessage({ id: 'title.addNewDefect' }, { field: operatorSettings?.defect_name })}</span>
            )}
          </>
        ) : (
          <>
            <span>
              {operatorSettings?.defect_name}:<strong>{defect && defect.number}</strong>
              {displayThirdPartyReferences()}
            </span>
            {!(defect?.status === 'resolved' && editResolvedLocal) && !editResolved ? (
              <FlexWrapper marginLeft={10}>
                <AuthDropdownMenu
                  options={{
                    read: false,
                    update: true,
                    delete: false,
                    custom: !defect?.deferred,
                  }}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
                  resource={AircraftResource.DEFECT}
                  aircraftId={acId}
                  customText="Defer Defect"
                  customCallback={(): any => handleDeferDefect()}
                  editCallback={(): any =>
                    dispatch(changeDrawerMode({ payload: defect?.status === 'resolved' ? 'editResolved' : 'edit' }))
                  }
                  noOffset
                />
              </FlexWrapper>
            ) : null}
          </>
        )}
      </DefectTitle>
      {drawerBanner()}
      <FlexWrapper justifyContent="space-between">
        <FlexWrapper flexWrap="wrap" column={isMobile} width="100%">
          {document.location.pathname === '/defects' ? (
            <InfoSection
              isMobile={isMobile}
              margin
              colSpan={2}
              editDefect
              data-testid="DefectHeader--AircraftSection"
              viewMode={mode === 'view'}
            >
              <Label
                marginBottom={4}
                fontSize="12px"
                color={mode === 'edit' || mode === 'add' ? '#242d41' : 'rgba(36, 45, 65, 0.4)'}
              >
                {formatMessage({ id: 'title.aircraft' })}
              </Label>
              {mode === 'edit' || mode === 'add' ? (
                <SelectWrapper>
                  <Loading loading={aircraftLoading} contain width={20} height={20} />
                  <TFSearchableSelect
                    initial={chosenAircraft}
                    options={aircraftOptions}
                    handleSelectChange={handleChangeOfAircraft}
                    inputWidth={200}
                  />
                </SelectWrapper>
              ) : (
                <FlexWrapper>{chosenAircraft?.title}</FlexWrapper>
              )}
            </InfoSection>
          ) : null}
          <FlexWrapper>
            <InfoSection
              isMobile={isMobile}
              width={id && mode === 'view' ? '150px' : ''}
              margin
              colSpan={2}
              editDefect
              data-testid="DefectHeader--SectorSection"
              viewMode={mode === 'view'}
            >
              <Label
                marginBottom={4}
                fontSize="12px"
                color={mode === 'edit' || mode === 'add' ? '#242d41' : 'rgba(36, 45, 65, 0.4)'}
                identifier="sector"
              >
                {sectorName || formatMessage({ id: 'title.sector' })}
              </Label>
              {mode === 'edit' || mode === 'add' ? (
                <SelectWrapper column={isMobile} flex={!!id}>
                  <Loading loading={flightLoading} contain height={20} width={20} />
                  <TFSearchableSelect
                    options={chosenFlight?.value === null && disableFlightSelection ? [] : localFlightTitles}
                    inputWidth={340}
                    handleSelectChange={handleChangeOfFlight}
                    initial={chosenFlight?.value === null && disableFlightSelection ? '' : chosenFlight}
                    disabled={disableFlightSelection}
                  />
                  {id ? (
                    <DateWrapper data-testid="DefectHeader--DateSection">
                      <DateTimePicker
                        headerDate="Date Recorded"
                        headerTime="Time Recorded"
                        handleDateTimeChange={handleDateChange}
                        dateTime={defect?.raised_at}
                        noFuture
                      />
                    </DateWrapper>
                  ) : null}
                </SelectWrapper>
              ) : (
                <FlexWrapper>
                  <Loading loading={flightLoading} contain height={20} width={20} />
                  {sector && tripNumber ? (
                    <>
                      <DocumentLinkDivider>
                        <StyledButton
                          type="button"
                          onClick={(): void => {
                            dispatch(changeDrawerVisibility({ payload: true }));
                            dispatch(changeDrawerContent({ payload: { content: <FlightDrawer /> } }));
                            dispatch(setDrawerId({ payload: chosenFlight?.value }));
                          }}
                        >
                          {`${sector} ${`(Trip #${tripNumber})`}`}
                        </StyledButton>
                      </DocumentLinkDivider>
                      <DocumentLink id={tripId} aircraftId={acId} docType="srp" />
                    </>
                  ) : (
                    '-'
                  )}
                </FlexWrapper>
              )}
            </InfoSection>
            <InfoSection
              margin
              isMobile={isMobile}
              marginBottom="0"
              width={id && mode === 'view' ? '150px' : ''}
              editDefect={mode === 'edit' || mode === 'add'}
              data-testid="DefectHeader--DateSection"
              className={styles.dateWrapper}
              viewMode={mode === 'view'}
            >
              {mode === 'view' ? (
                <Label
                  identifier="date"
                  marginBottom={4}
                  fontSize="12px"
                  color={mode !== 'view' ? '#242d41' : 'rgba(36, 45, 65, 0.4)'}
                >
                  {formatMessage({ id: 'text.dateRecorded' })}
                </Label>
              ) : null}
              {mode !== 'view' ? (
                <>
                  {!id && (
                    <DateTimePicker
                      headerDate="Date Recorded"
                      headerTime="Time Recorded"
                      handleDateTimeChange={handleDateChange}
                      dateTime={defect?.raised_at || moment.utc().toISOString()}
                      noFuture
                    />
                  )}
                </>
              ) : (
                <span>{moment(defect?.raised_at).utc().format(`${userSettings.dateFormat} HH:mm`) || '-'}</span>
              )}
            </InfoSection>
          </FlexWrapper>
          {mode === 'view' ? (
            <FlexWrapper width={isMobile || !id ? 'auto' : '340px'}>
              {mode === 'view' && (
                <>
                  <InfoSection margin isMobile={isMobile} data-testid="DefectHeader--StatusSection">
                    <Label marginBottom={4} fontSize="12px" color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'title.statusCaps' })}
                    </Label>
                    <FlexWrapper width="150px" flexShrink="1">
                      {(defect && defect.status && (
                        <StatusWrapper>
                          <StatusBadge status={defect?.status} />
                          <StatusText>{defectStatusText}</StatusText>
                        </StatusWrapper>
                      )) ||
                        '-'}
                      <StyledLinkWrapper res={defectButtonText === 'Submit Resolution'}>
                        <AircraftAuthenticationWrapper
                          aircraftId={defect?.aircraft?.id}
                          requiredResource={AircraftResource.RELEASE}
                          requiredPermissionLevel={
                            defect?.status === 'resolved' ? AircraftPermission.READ : AircraftPermission.CREATE
                          }
                        >
                          <Loading loading={flightLoading} contain height={20} width={20} />
                          <CRSButton crsItemId={defect?.id} status={defect?.status} type="defects" />
                        </AircraftAuthenticationWrapper>
                      </StyledLinkWrapper>
                    </FlexWrapper>
                  </InfoSection>
                </>
              )}
              {mode === 'view' && (id || isMobile) && <PublicShareLink type="defects" id={defect?.id} />}
            </FlexWrapper>
          ) : null}
          {mode === 'view' && !id && !isMobile && (
            <FlexWrapper
              position="relative"
              alignItems="center"
              width="100%"
              marginBottom={20}
              justifyContent="flex-end"
            >
              <PublicShareLink type="defects" id={defect?.id} />
            </FlexWrapper>
          )}
        </FlexWrapper>
      </FlexWrapper>
    </Header>
  );
};

export default DefectHeader;
