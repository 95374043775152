import React, { useEffect, useState, useRef, ReactElement } from 'react';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button, FilterSideBar, Loading, Search } from '@arcflight/tf-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import { usePrevious } from '../../utils/utils';
import RadioInputButton from '../../components/RadioInputButton/RadioInputButton';
import { getCabinSVG, setSelectedCabinIssueLocation } from '../../models/cabinIssues/action';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import EmptyStateIntermittent from '../../assets/emptyState/empty-state-intermittent-faults.svg';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { DashboardState } from '../../models';
import plusIcon from '../../assets/plus.svg';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { fetchImage } from '../../services/api';
import { CabinIssuesActionTypes } from '../../models/cabinIssues';
import EmptyState from '../../components/EmptyState/EmptyState';
import redPin from './icon-pin-red.svg';
import amberPin from './icon-pin-amber.svg';
import CabinLogTable from './CabinLogTable';
import CabinIssueDrawer from './CabinIssueDrawer';
import cabinIssueFilterModel from './cabinIssueFilterModel';
import useQueryCabinIssues from './QueryCalls/useQueryCabinIssues';
import useQueryCabinIssueTotals from './QueryCalls/useQueryCabinIssueTotals';

const Card = styled.div`
  background-color: #fafafa;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-right: 20px;
`;

const SVGContentWrapper = styled.div`
  display: flex;
`;

const SVGContent = styled.div`
  width: auto;
  position: relative;
  @media (max-width: 1450px) {
    display: none;
  }
`;

const SVGContentMobile = styled.div`
  width: 100%;
  display: none;
  position: relative;
  @media (max-width: 1450px) {
    position: relative;
    display: block;
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 150px;
`;

const TableActions = styled.div`
  display: flex;
`;

const SearchWrapper = styled.div`
  margin-right: 12px;
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
`;

const LocationText = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
`;

const LocationWrapper = styled.div`
  margin-left: 10px;
`;

const CabinLog: React.FC = () => {
  const {
    aircraft: { aircraftMap },
    cabinIssues: { cabinIssueSVG },
    userSettings: { details },
  } = useSelector((state: DashboardState) => state);

  const iframeRef = useRef(null);
  const locationRef = useRef(null);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const operatorSettings = details?.operators?.find(
    (op) => aircraftMap.get(id)?.operator_id === op?.id,
  )?.operator_setting;

  const cabinIssueOverride = operatorSettings?.cabin_issue_name_singular;

  const mobileView = window.innerWidth < 451;

  const [searchValue, setSearchValue] = useState('');
  const [reset, setReset] = useState(false);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState([]);
  const [resetToOne, setResetToOne] = useState(false);
  const [cabinIssuesCount, setCabinIssuesCount] = useState(0);
  const [cabinIssues, setCabinIssues] = useState([]);
  const [cabinIssuesOriginal, setCabinIssuesOriginal] = useState([]);
  const [filterModel, setFilterModel] = useState(cabinIssueFilterModel());
  const [svgContent, setsvgContent] = useState(null);
  const [issuePoints, setIssuePoints] = useState([]);
  const [iframeWidth, setIframeWidth] = useState(null);
  const [iframeHeight, setIframeHeight] = useState(null);
  const [location, setLocation] = useState('location');
  const [timer, setTimer] = useState(null);

  const previousFilters = usePrevious(filters);
  const previousSearchValue = usePrevious(searchValue);

  const { data: cabinData, isFetching } = useQueryCabinIssues({
    aircraft_id: id,
    page,
    limit,
    sortBy,
    searchValue,
    filters,
  });

  const { data: cabinTotals } = useQueryCabinIssueTotals({ aircraft_id: id });

  const handleAddNewIssue = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({ payload: { content: <CabinIssueDrawer issue={null} /> } }));
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  const onSortChange = (input): void => {
    setSortBy(input);
  };
  const resetFilters = (): void => {
    setReset(true);
    setSortBy([]);
    setSearchValue('');
    setPage(1);
  };

  const updateCabinIssueArray = (array): void => {
    setCabinIssues(array);
    setReset(false);
  };

  const locationToggle = (): ReactElement => {
    return (
      <LocationWrapper>
        <RadioWrapper>
          <RadioInputButton
            text="Location"
            active={location === 'location'}
            checkbox
            marginRight="8px"
            onClick={(): void => setLocation('location')}
          />
          <RadioInputButton
            text="Custom"
            active={location === 'custom'}
            checkbox
            marginRight="8px"
            onClick={(): void => setLocation('custom')}
          />
        </RadioWrapper>
        <LocationText>
          {location === 'location' ? 'Click on a location in the drawing' : 'Click anywhere on the image below'}
        </LocationText>
      </LocationWrapper>
    );
  };

  const handlePointClick = (item): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(setDrawerId({ payload: item.associated_record_id }));
    dispatch(changeDrawerContent({ payload: { content: <CabinIssueDrawer issue={item} /> } }));
  };

  const handleSearchChange = (): void => {
    setPage(1);
    setResetToOne(true);

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['cabinIssues', id] });
        setTimer(null);
      }, 300),
    );
  };

  useEffect(() => {
    return (): void => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (previousSearchValue && !_.isEqual(searchValue, previousSearchValue)) {
      handleSearchChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (previousFilters && !_.isEqual(filters, previousFilters)) {
      setResetToOne(true);
      setPage(1);
      queryClient.invalidateQueries({ queryKey: ['cabinIssues', id] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (cabinData) {
      setCabinIssues([...cabinData.cabin_issues]);
      setCabinIssuesOriginal([...cabinData.cabin_issues]);
      setCabinIssuesCount(cabinData.count);
    }
  }, [cabinData]);

  useEffect(() => {
    dispatch(getSingleAircraft({ payload: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cabinTotals) {
      const newFilterModel = cabinIssueFilterModel(cabinTotals);
      setFilterModel(newFilterModel);
    }
  }, [cabinTotals]);

  useEffect(() => {
    if (id) {
      setsvgContent(null);
      const svgId = aircraftMap?.get(id)?.active_aircraft_drawing?.id;
      if (svgId) {
        dispatch(getCabinSVG({ payload: { aircraft_id: id, id: svgId } }));
      } else {
        dispatch({
          type: CabinIssuesActionTypes.GET_CABIN_ISSUE_SVG,
          payload: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, aircraftMap]);

  useEffect(() => {
    // function to handle seat click
    const handleMessage = ({ data }) => {
      if (data) {
        const seatState = data?.current?.split('-')[2];

        const seatData = {
          id: data?.id,
          state: seatState,
        };

        if (locationRef.current === 'location') {
          dispatch(setSelectedCabinIssueLocation({ payload: seatData }));
          dispatch(changeDrawerVisibility({ payload: true }));
          dispatch(changeDrawerMode({ payload: seatState === 'normal' ? 'add' : 'view' }));
          dispatch(changeDrawerContent({ payload: { content: <CabinIssueDrawer issue={seatData} /> } }));
        }
      }
    };

    if (cabinIssueSVG) {
      window.addEventListener('message', handleMessage);

      const fetchSVG = async () => {
        try {
          const newRes: any = await fetchImage(cabinIssueSVG?.attachment?.id);
          const parser = new DOMParser();

          const doc = parser.parseFromString(await newRes.text(), 'image/svg+xml');
          const scriptElement = doc.querySelector('script');
          const pattern = /\d/g;

          const selectLocationFunction = `
          function selectLocation(id, symbolType) {
            const current = document.getElementById(id)?.getAttribute("href");
            const currentStyle = current?.split('-')[2];
            window.parent.postMessage({id, current});
          }

          function alterLocation(id, styleState) {
            const element = document.getElementById(id);
            const seatId = id?.replace(${pattern}, '');
            if (element) {
              // desired style to look like "#icon-seata-amber"
              element.setAttribute("href", '#icon-' + seatId + '-' + styleState);
            }
          }
          `;

          if (scriptElement) {
            const existingScript = scriptElement.textContent;
            const newScript = `${existingScript}\n${selectLocationFunction}`;
            // const newScript = selectLocationFunction;
            scriptElement.textContent = newScript;
          }

          setsvgContent(doc.documentElement.outerHTML);
        } catch (error) {
          console.error(error);
        }
      };
      fetchSVG();
    } else {
      setsvgContent(null);
    }
    return (): void => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinIssueSVG, cabinIssues]);

  useEffect(() => {
    if (cabinIssues) {
      const openIssues = cabinIssues.filter(
        (issue) => issue.status === 'open' && issue?.aircraft_location_issues?.length > 0,
      );
      const issuesToBeStyled = openIssues?.filter((issue) =>
        issue?.aircraft_location_issues?.some((item) => item?.issue_type_id),
      );
      // function to handle custom point click
      const handleClick = (event, content, element): void => {
        const loc = locationRef.current;
        if (loc === 'location') return;
        const { scrollX, scrollY } = content.defaultView;
        const { width, height } = element.getBoundingClientRect();
        const x = (event.clientX + scrollX) / width;
        const y = (event.clientY + scrollY) / height;
        dispatch(setSelectedCabinIssueLocation({ payload: { x, y } }));
        dispatch(changeDrawerVisibility({ payload: true }));
        dispatch(changeDrawerMode({ payload: 'edit' }));
        dispatch(changeDrawerContent({ payload: { content: <CabinIssueDrawer issue={{ x, y }} /> } }));
      };

      let iframe = document.getElementById('cabinlayout') as any;

      if (window.innerWidth < 1450) iframe = document.getElementById('cabinlayoutmobile') as any;
      iframeRef.current = iframe;
      const handleIframeUpdates = () => {
        const iframeWindow = iframe?.contentWindow;
        if (iframeWindow && iframeWindow.alterLocation) {
          // reset svg styling
          const allLocations = cabinIssueSVG?.aircraft_location_groups?.flatMap((outer) => outer?.aircraft_locations);
          if (allLocations) {
            allLocations.forEach((loc) => {
              iframeWindow.alterLocation(loc?.element_identifier, 'normal');
            });
          }
          setIssuePoints([]);
          // add current stylying
          const aircraftIssueTypes = aircraftMap.get(id)?.issue_types;
          issuesToBeStyled.forEach((issue) => {
            issue.aircraft_location_issues
              .filter((item) => item?.issue_type_id)
              .forEach((item) => {
                if (item?.aircraft_location_id) {
                  iframeWindow.alterLocation(
                    cabinIssueSVG?.aircraft_location_groups
                      ?.flatMap((outer) => outer?.aircraft_locations)
                      ?.find((inner) => inner.id === item?.aircraft_location_id)?.element_identifier,
                    aircraftIssueTypes.find((type) => type.id === item?.issue_type_id)?.status,
                  );
                } else if (item?.position_x) {
                  const svgElement = iframe?.contentDocument?.querySelector('svg');
                  const svgWidth = parseFloat(svgElement.getAttribute('width'));
                  const svgHeight = parseFloat(svgElement.getAttribute('height'));
                  const pointStatus = aircraftIssueTypes?.find((iss) => iss.id === item?.issue_type_id)?.status;
                  // -15 on each to make it the center of the 30 x 30 dot.
                  const adjustedX = svgWidth * (item.position_x - svgElement.scrollLeft) - 15;
                  // add 90 to account for the custom location picker
                  const adjustedY = svgHeight * (item.position_y - svgElement.scrollTop) + 90 - 15;

                  const foundIssue = cabinIssues.find((localIssue) => localIssue.id === item.associated_record_id);
                  issuePoints.push(
                    <StyledButton key={item?.id} onClick={(): void => handlePointClick(foundIssue)}>
                      <img
                        src={pointStatus === 'red' ? redPin : amberPin}
                        alt="point"
                        style={{
                          position: 'absolute',
                          left: `${adjustedX}px`,
                          top: `${adjustedY}px`,
                          width: '30px',
                          height: '30px',
                        }}
                        id={item?.id}
                      />
                    </StyledButton>,
                  );
                  const newPoints = [...issuePoints];
                  setIssuePoints(newPoints);
                }
              });
          });
        }
      };

      const observeIframeContent = () => {
        const observer = new MutationObserver(handleIframeUpdates);
        const config = { childList: true, subtree: true };
        observer.observe(iframeRef.current.contentDocument.documentElement, config);
      };

      locationRef.current = location;

      if (iframeRef.current) {
        iframeRef.current.addEventListener('load', () => {
          handleIframeUpdates();
          observeIframeContent();
          // listen for custom clicks
          const iframeContent = iframe.contentDocument;
          const element = iframeContent.querySelector('svg');
          const bodyElement = iframeContent.querySelector('body');
          if (bodyElement) bodyElement.style.margin = '0';
          if (element) {
            setIframeWidth(parseFloat(element.getAttribute('width')) + 4);
            setIframeHeight(parseFloat(element.getAttribute('height')) + 4);
            element.addEventListener('click', (e) => handleClick(e, iframeContent, element));
          }
        });
      }
      handleIframeUpdates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinIssues, svgContent, location, cabinIssueSVG]);

  const buildPoints = (): ReactElement[] => {
    return issuePoints.map((point) => point);
  };

  let subText = 'Try adjusting your filters or searching with another term.';
  let buttonText = 'Clear all';
  if (cabinIssues.length === 0) {
    subText = `You can add your first ${operatorSettings?.cabin_issue_name_singular} now.`;
    buttonText = `Add ${operatorSettings?.cabin_issue_name_singular}`;
  }
  if (!cabinIssueSVG) {
    subText = (
      <span>
        {`Your aircraft doesn’t have a cabin schematic loaded. If you believe this is an error or you would like to
        enquire about the Cabin Log please contact `}
        <a href="mailto:support@trustflight.com">support@trustflight.com</a>
      </span>
    ) as any;
    buttonText = null;
  }

  return (
    <InnerMenuLayout>
      <>
        <SVGContentWrapper>
          <Card>
            <TableWrapper>
              <PageHeader>
                <PageTitle>{cabinIssueOverride || 'Cabin issue'}</PageTitle>
                <TableActions>
                  <SearchWrapper>
                    <Search
                      onChange={(e): void => setSearchValue(e.target.value)}
                      onClear={(): void => setSearchValue('')}
                      reset={reset}
                    />
                  </SearchWrapper>
                  {cabinIssueSVG ? (
                    <Button size={ButtonSize.MEDIUM} onClick={handleAddNewIssue}>
                      <div>
                        <img src={plusIcon} alt="plus icon" />{' '}
                        {mobileView
                          ? formatMessage({ id: 'form.button.add' })
                          : formatMessage({ id: 'text.addCabinIssue' })}
                      </div>
                    </Button>
                  ) : null}
                </TableActions>
              </PageHeader>
              <>
                {isFetching ? (
                  <Loading loading={isFetching} />
                ) : (
                  <>
                    {cabinIssueSVG && cabinIssues?.length > 0 ? (
                      <CabinLogTable
                        data={cabinIssues}
                        onPaginationChange={(currentPage, numberOfItems): void => {
                          if (numberOfItems !== limit) {
                            setPage(1);
                            setLimit(numberOfItems);
                          } else if (currentPage !== page) {
                            setPage(currentPage);
                          }
                        }}
                        onSortChange={(sort): void => onSortChange(sort)}
                        total={cabinIssuesCount}
                        pageSize={limit}
                        pageIndex={page - 1}
                        resetToOne={resetToOne}
                      />
                    ) : (
                      <EmptyState
                        image={EmptyStateIntermittent}
                        text={
                          cabinIssuesOriginal.length === 0
                            ? `No ${operatorSettings?.cabin_issue_name_plural}`
                            : `We couldn't find any matching ${operatorSettings?.cabin_issue_name_plural}`
                        }
                        subText={subText}
                        button={buttonText}
                        buttonAction={cabinIssuesOriginal.length === 0 ? handleAddNewIssue : resetFilters}
                        subTextWidth="400px"
                      />
                    )}
                  </>
                )}
              </>
            </TableWrapper>
            <FilterSideBar
              data={cabinIssuesOriginal}
              updateArray={updateCabinIssueArray}
              filterGroups={filterModel}
              reset={reset}
              onChange={(e): void => {
                if (e.length === 0) {
                  setReset(false);
                  setFilters([]);
                } else {
                  const newFilters = e.reduce((newObject, object) => {
                    const workingObject = newObject;
                    if (!workingObject[object.key]) {
                      workingObject[object.key] = [object.value];
                    } else {
                      workingObject[object.key].push(object.value);
                    }
                    return workingObject;
                  }, {});
                  setFilters(newFilters);
                  setReset(false);
                }
              }}
            />
          </Card>
          {svgContent ? (
            <SVGContent>
              {locationToggle()}
              <iframe
                id="cabinlayout"
                title="Cabin Layout"
                srcDoc={svgContent}
                width={iframeWidth || 400}
                height={iframeHeight || 1000}
              />
              {buildPoints()}
            </SVGContent>
          ) : null}
        </SVGContentWrapper>
        {svgContent ? (
          <SVGContentMobile>
            {locationToggle()}
            <iframe
              id="cabinlayoutmobile"
              title="Cabin Layout"
              srcDoc={svgContent}
              width={iframeWidth || '100%'}
              height={iframeHeight || 1000}
            />
            {buildPoints()}
          </SVGContentMobile>
        ) : null}
      </>
    </InnerMenuLayout>
  );
};

export default CabinLog;
