import React from 'react';
import { RadioWrapper } from '../CommonStyledComponents/CommonStyledComponents';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

interface TFRadioInputProps {
  options: { title: string; value: string | boolean }[];
  onChange: (value: string | boolean) => void;
  label?: string;
  id?: string;
  value?: string | boolean;
  tooltip?: string;
  optional?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const defaultProps = {
  label: '',
  id: '',
  value: '',
  tooltip: '',
  optional: false,
  disabled: false,
  readOnly: false,
};

const TFRadioInput: React.FC<TFRadioInputProps> = ({
  options,
  onChange,
  label,
  id,
  value,
  tooltip,
  optional,
  disabled,
  readOnly,
}) => {
  return (
    <div data-testid="TFRadioInput--Wrapper">
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} readOnly={readOnly} />}
      {readOnly ? (
        <div data-testid={`TFRadioInput--${id}--Label`}>{options.find((item) => item.value === value)?.title}</div>
      ) : (
        <RadioWrapper data-testid={`TFRadioInput--${id}`}>
          {options.map((item) => {
            return (
              <RadioInputButton
                text={item.title}
                active={value === item.value}
                onClick={(): void => onChange(item.value)}
                key={`${id}-${item.title}`}
                marginRight="8px"
                dataTestId={`TFRadioInput--${id}--${item.title}`}
                disabled={disabled}
              />
            );
          })}
        </RadioWrapper>
      )}
    </div>
  );
};

TFRadioInput.defaultProps = defaultProps;

export default TFRadioInput;
