import React from 'react';
import styled from 'styled-components';
import TFFormLabel from '../TFFormLabel/TFFormLabel';
import { Status, StatusIconMap } from '../TFInput/TFInput';

interface TFNumberInputProps {
  margin?: string;
  value?: string | number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  width?: string;
  id?: string;
  label?: string;
  optional?: boolean;
  tooltip?: string;
  disabled?: boolean;
  status?: Status;
  statusMessage?: string;
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

const Wrapper = styled.div`
  margin: ${({ margin }): string => (margin ? `${margin}` : '0')};
  width: ${({ width }): string => (width ? `${width}` : '100%')};
`;

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
  ${({ disabled, theme }: { disabled?: boolean; theme: any }): string =>
    disabled &&
    `
    cursor: not-allowed;
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

const InputFeedback = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: -0.4px;
  &.error {
    color: ${({ theme }): string => theme.colours.red};
  }
  &.valid {
    color: ${({ theme }): string => theme.colours.green};
  }
  &.warning {
    color: ${({ theme }): string => theme.colours.orange};
  }
`;

const TFNumberInput: React.FC<TFNumberInputProps> = ({
  margin,
  value = '',
  onChange,
  min,
  max,
  width,
  id = '',
  label,
  optional,
  tooltip,
  disabled,
  status,
  statusMessage,
}) => {
  const handleChange = (input: string): void => {
    const numericValue = parseInt(input, 10);
    onChange(numericValue);
  };

  return (
    <Wrapper margin={margin} width={width} data-testid="TFNumberInput--Wrapper">
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <InputContainer>
        <StyledInput
          type="number"
          min={min}
          max={max}
          value={value}
          onChange={(e): void => handleChange(e.target.value)}
          data-testid={`TFNumberInput-Input-${id}`}
          disabled={disabled}
        />
        {status && (
          <IconContainer>
            <img data-testid={`TFInput-Img-${id}`} src={StatusIconMap[status]} alt="info icon" />
          </IconContainer>
        )}
      </InputContainer>
      {statusMessage && (
        <InputFeedback data-testid={`TFInput-Feedback-${id}`} className={status}>
          {statusMessage}
        </InputFeedback>
      )}
    </Wrapper>
  );
};

TFNumberInput.defaultProps = {
  margin: '',
  value: '',
  min: null,
  max: null,
  width: '',
  id: '',
  label: '',
  optional: false,
  tooltip: '',
  disabled: false,
  status: null,
  statusMessage: null,
};

export default TFNumberInput;
