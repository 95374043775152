/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import { Aircraft, AircraftResource } from '../../models/aircraft';
import { DashboardState } from '../../models';
import { Trip } from '../../models/trips';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  updateDrawerContent,
  revertChanges as reduxRevertChanges,
  changeModalVisibility,
  changeModalContent,
} from '../../models/drawer';
import completeIcon from '../../assets/icon-status-complete.svg';
import { getTripDetails, saveTripEntry, updateTripFromDrawer } from '../../models/trips/actions';
import { getMinMaxFields, getPrecisionFields, getRequiredFields } from '../../utils/customFieldUtils';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { deleteAttachment } from '../../services/api';
import checkPrecisionOfNumber from '../../utils/checkPrecisionOfNumber';
import incompleteIcon from '../../assets/icon-status-incomplete.svg';
import FlightDrawer from '../FlightDrawer/FlightDrawer';
import backArrow from '../../assets/icon-back.svg';
import draftIcon from '../../assets/icon-status-unset.svg';
import HistorySection from '../HistoryTable/HistorySection';
import buildCompleteOption from '../../utils/tripUtils';
import TripAttachments from './TripAttachments';
import TripCrewDutyLogs from './TripCrewDutyLogs';
import TripDetailsSection from './TripDetailsSection';
import TripSectors from './TripSectors';
import TripTrendingSection from './TripTrendingSection';
import SRPSection from './SRPSection';
import ButtonSection from './ButtonSection';
import DrawerBanner from './DrawerBanner';
import AdditionalCrewSection from './AdditionalCrewSection';

interface TripDrawerProps {
  trip: Trip;
  handleDelete: (id: string) => void;
  scroll?: string;
  aircraft: Aircraft;
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: ${({ bannerVisible }): string => (bannerVisible ? '16px' : '24px')};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
  }
`;

const HeavyTitle = styled.span`
  font-size: 18px;
  color: #242d41;
  font-weight: 500;
  margin: 0 4px;
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 20px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const TripDrawer: React.FC<TripDrawerProps> = ({ trip, handleDelete, scroll, aircraft }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode, drawerHistory, drawerId, revertChanges, drawerContent },
    trips: { tripsMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const displayDutyLogs = aircraft?.standard_fields?.duty_log?.enabled;

  const trendMonitoring = aircraft?.standard_fields?.trend_monitoring;

  const displayCallSign = aircraft?.standard_fields?.trip_callsign?.enabled;

  const [tripData, setTripData] = useState(null);
  const [originaltrip, setOriginalTrip] = useState(null);
  const [resetAttachments, setResetAttachments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tripStatus, setTripStatus] = useState(null);
  const [bannerVisible, setBannerVisible] = useState(false);

  const dispatchErrorToast = ({ title, message }): void => {
    dispatch(
      addToast({
        payload: {
          title,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
          message,
        },
      }),
    );
  };

  const validateCustomData = (): boolean => {
    const requiredFields = getRequiredFields(aircraft?.custom_fields);
    const precisionFields = getPrecisionFields(aircraft?.custom_fields);
    const minMaxFields = getMinMaxFields(aircraft?.custom_fields);
    let valid = true;
    requiredFields.forEach((field) => {
      if (field?.view === 'trip') {
        if (
          tripData?.custom_data &&
          (tripData?.custom_data[`${field.key}`] === null || tripData?.custom_data[`${field.key}`] === undefined)
        ) {
          dispatchErrorToast({
            title: `Custom Data error`,
            message: `Trip custom data ${field?.title.toLowerCase()} must be completed`,
          });
          valid = false;
        }
      }
    });
    precisionFields.forEach((field) => {
      if (field?.view === 'trip') {
        if (
          tripData.custom_data &&
          field?.precision &&
          !checkPrecisionOfNumber(tripData.custom_data[`${field.key}`], field?.precision)
        ) {
          dispatchErrorToast({
            title: `Custom Data error`,
            message: `Trip custom data ${field?.title.toLowerCase()} must be to ${field?.precision} decimal places`,
          });
          valid = false;
        }
      }
    });
    minMaxFields.forEach((field) => {
      if (field?.view === 'trip') {
        if (tripData.custom_data && tripData.custom_data[`${field.key}`] < field?.min) {
          dispatchErrorToast({
            title: `Custom Data error`,
            message: `Trip custom data ${field?.title.toLowerCase()} must be greater than ${field?.min} ${
              field?.type === 'number' ? '' : 'characters'
            }`,
          });
          valid = false;
        }
        if (tripData.custom_data && tripData.custom_data[`${field.key}`] > field?.max) {
          dispatchErrorToast({
            title: `Custom Data error`,
            message: `Trip custom data ${field?.title.toLowerCase()} must be less than ${field?.max} ${
              field?.type === 'number' ? '' : 'characters'
            }`,
          });
          valid = false;
        }
      }
    });
    return valid;
  };

  const validateTripData = (): boolean => {
    if (tripData && !tripData.date) {
      dispatchErrorToast({
        title: 'Date must be entered',
        message: '',
      });
      return false;
    }
    if (tripData && !tripData.callsign && displayCallSign !== false) {
      dispatchErrorToast({
        title: 'Callsign must be entered',
        message: '',
      });
      return false;
    }
    if (tripData && !tripData.captain_id) {
      dispatchErrorToast({
        title: 'Pilot flying must be selected',
        message: '',
      });
      return false;
    }
    if (!validateCustomData()) {
      return false;
    }
    return true;
  };

  const submitTrip = async (): Promise<void> => {
    if (mode === 'add' && !tripData?.id) {
      if (validateTripData()) {
        setLoading(true);
        if (tripData?.attachments)
          tripData.attachments.forEach((item) => {
            if (Object.keys(item)?.includes('_destroy')) {
              deleteAttachment(item.id);
            }
          });
        const newAttachments = tripData?.attachments?.filter(
          (item) => !Object.keys(item)?.includes('_destroy') && !originaltrip?.attachments?.includes(item),
        );
        const addTripData = {
          ...tripData,
          attachments_attributes: newAttachments,
        };
        // this is broken up into two parts as core will not accept additional crew on a trip that doesn't already exist
        delete addTripData.additional_crews_attributes;
        const addPromise = dispatch(saveTripEntry({ payload: { id, trip: addTripData } }));
        Promise.all([addPromise]).then((res) => {
          const newTrip = res[0] as any;
          setTripData(newTrip);
          setOriginalTrip(newTrip);
          setLoading(false);
          const updateAdditionalCrewPayload = {
            id: newTrip?.id,
            duty_logs_attributes: tripData?.duty_logs,
            additional_crews_attributes: tripData?.additional_crews_attributes,
          };
          const promise = dispatch(updateTripFromDrawer({ trip: updateAdditionalCrewPayload, id: newTrip?.id }));
          Promise.all([promise]).then((res2) => {
            const newTripData = res2[0];
            setTripData(newTripData);
            setOriginalTrip(newTripData);
            setLoading(false);
            dispatch(changeDrawerContent({ payload: { content: <FlightDrawer trip={newTrip} />, backButton: true } }));
          });
        });
      }
    } else if (validateTripData()) {
      tripData.attachments.forEach((item) => {
        if (Object.keys(item).includes('_destroy')) {
          deleteAttachment(item.id);
        }
      });
      const newAttachments = tripData.attachments.filter(
        (item) => !Object.keys(item).includes('_destroy') && !originaltrip?.attachments?.includes(item),
      );
      const newTripData = {
        ...tripData,
        duty_logs_attributes: tripData?.duty_logs,
        attachments_attributes: newAttachments,
      };
      setLoading(true);
      const promise = dispatch(updateTripFromDrawer({ trip: newTripData, id: tripData?.id }));
      Promise.all([promise]).then((res) => {
        const newTrip = res[0];
        setTripData(newTrip);
        setOriginalTrip(newTrip);
        setLoading(false);
      });
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleCancelClick = (): void => {
    if (mode === 'add') {
      dispatch(changeDrawerVisibility({ payload: false }));
    } else {
      setTripData(originaltrip);
      dispatch(changeDrawerMode({ payload: 'view' }));
      setResetAttachments(true);
    }
  };

  const handleSaveClick = (): void => {
    submitTrip();
  };

  const handleBackClick = (): void => {
    dispatch(handleBackButtonClick());
  };

  const updateTripDataMulti = (changes: { value: any; key: string }[]): void => {
    const newTripData = tripData ? JSON.parse(JSON.stringify(tripData)) : {};
    changes.forEach(({ value, key }) => {
      newTripData[key] = value;
    });
    setTripData(newTripData);
  };

  const updateTripData = (key: string, value: any, secondKey?: string, secondValue?: any): void => {
    if (key === 'date' && value !== originaltrip?.date && mode !== 'add') {
      setBannerVisible(true);
    } else {
      setBannerVisible(false);
    }
    let newTripData = {
      ...tripData,
      [key]: value,
    };
    if (secondKey) {
      newTripData = {
        ...newTripData,
        [secondKey]: secondValue,
      };
    }
    if (key === 'first_officer_id' && (value === undefined || value === null)) {
      newTripData = {
        ...newTripData,
        first_officer: {},
        first_officer_id: null,
      };
    }
    setTripData(newTripData);
  };

  useEffect(() => {
    if (drawerId && !trip) {
      const newTrip = tripsMap.get(drawerId);
      setTripData(newTrip);
      setOriginalTrip(newTrip);
    }
  }, [drawerId, tripsMap]);

  useEffect(() => {
    if (!tripData) {
      if (aircraft?.custom_fields) {
        const customFields = Object.entries(aircraft?.custom_fields as Record<string, any>)
          ?.filter(([fieldKey, data]) => data?.view === 'trip')
          .map(([fieldKey, data]) => {
            return { [`${data.key}`]: null };
          });
        setTripData({ date: moment().format('YYYY-MM-DD'), custom_data: customFields });
        setOriginalTrip({ date: moment().format('YYYY-MM-DD'), custom_data: customFields });
      } else {
        setTripData({ date: moment().format('YYYY-MM-DD') });
        setOriginalTrip({ date: moment().format('YYYY-MM-DD') });
      }
    }
  }, []);

  useEffect(() => {
    if (trip && trip?.id) {
      dispatch(getTripDetails({ payload: trip?.id }));
    }
  }, [trip, drawerContent]);

  useEffect(() => {
    if (trip && trip?.id) {
      const newTripData = tripsMap.get(trip.id);
      setOriginalTrip(newTripData);
      setTripData(newTripData);
      if (newTripData?.status) setTripStatus(newTripData.status);
    }

    const updatedTrip = tripsMap.get(drawerId);
    if (updatedTrip) {
      setTripData(updatedTrip);
      dispatch(updateDrawerContent({ payload: false }));
    }
  }, [tripsMap]);

  useEffect(() => {
    if (!_.isEqual(tripData, originaltrip) && mode !== 'view') {
      dispatch(setDrawerChanges({ payload: true }));
    }
  }, [tripData, originaltrip]);

  useEffect(() => {
    if (revertChanges) {
      setTripData(originaltrip);
      setBannerVisible(false);
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
  }, [revertChanges]);

  useEffect(() => {
    if (scroll && mode === 'view') {
      const element = document.getElementById('attachments');
      if (element) element.scrollIntoView();
    }
  }, [scroll, tripData]);

  let statusIcon = completeIcon;
  if (tripStatus === 'partial') statusIcon = incompleteIcon;
  if (tripStatus === 'planned') statusIcon = draftIcon;
  if (tripStatus === 'in_progress') statusIcon = incompleteIcon;
  if (mode === 'add') statusIcon = draftIcon;

  let statusText = tripData?.status;
  if (tripData?.status === 'in_progress') statusText = 'in progress';

  let displayTrendSection = false;
  if (
    tripData?.custom_data?.trend_monitoring?.justifications ||
    tripData?.custom_data?.trend_monitoring?.press_alt ||
    tripData?.custom_data?.trend_monitoring?.ioat ||
    tripData?.custom_data?.trend_monitoring?.ias ||
    mode !== 'view'
  )
    displayTrendSection = true;
  if (tripData?.custom_data?.trend_monitoring?.engine_1) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (tripData?.custom_data?.trend_monitoring?.engine_2) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (tripData?.custom_data?.trend_monitoring?.engine_3) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (tripData?.custom_data?.trend_monitoring?.engine_4) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (trendMonitoring && trendMonitoring?.enabled === false) displayTrendSection = false;
  let displayDutyLogSection = true;

  if (tripData?.duty_logs && tripData?.duty_logs.length === 0 && mode === 'view') displayDutyLogSection = false;
  if (displayDutyLogs === false) displayDutyLogSection = false;

  const operators = userSettings?.details?.operators || [];
  const currentOp = operators?.find((op) => op.id === aircraft?.operator_id);
  const sectorNameOverride = currentOp?.operator_setting.sector_name || formatMessage({ id: 'text.sector' });

  const dropdownOptions: any = {
    read: false,
    update: true,
    delete: !!tripData?.id,
  };

  if (mode === 'view' && tripData?.number && tripData?.status !== 'complete') {
    dropdownOptions.extraItems = [buildCompleteOption(tripData.id, dispatch, formatMessage)];
  }

  return (
    <DrawerWrapper data-testid="TripDrawer-DrawerWrapper">
      {!loading && (tripData || mode === 'add') ? (
        <>
          <Title data-testid="TripDrawer-Title" bannerVisible={bannerVisible}>
            {drawerHistory.length ? (
              <StyledButton onClick={handleBackClick}>
                <img src={backArrow} alt="back arrow" />
              </StyledButton>
            ) : null}
            {formatMessage({ id: 'text.trip' })}:{' '}
            <HeavyTitle>{`${tripData?.number ? tripData?.number : ''}`}</HeavyTitle> -{' '}
            <img src={statusIcon} alt="status icon" /> {statusText}
            {mode === 'view' ? (
              <div data-testid="TripDrawer-AuthDropdownMenu">
                <AuthDropdownMenu
                  options={dropdownOptions}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
                  resource={AircraftResource.TRIP}
                  aircraftId={tripData?.aircraft_id}
                  editCallback={(): void => handleEditClick()}
                  handleDelete={(): void => handleDelete(trip?.id)}
                />
              </div>
            ) : null}
          </Title>
          {bannerVisible ? (
            <DrawerBanner
              message={formatMessage({ id: 'text.tripDateChanged' }, { sector: sectorNameOverride.toLowerCase() })}
            />
          ) : null}
          {mode !== 'add' ? (
            <div data-testid="TripDrawer-SRPSection">
              <SRPSection trip={tripData} editable={mode !== 'view'} updateTripData={updateTripData} />
            </div>
          ) : null}
          <InfoDivWrapper data-testid="TripDrawer-TripDetailsSection">
            <TripDetailsSection
              trip={tripData}
              editable={mode !== 'view'}
              updateTripData={updateTripData}
              aircraftId={id}
              updateTripDataMulti={updateTripDataMulti}
            />
          </InfoDivWrapper>
          <AdditionalCrewSection trip={tripData} updateTripData={updateTripData} currentOp={currentOp} />
          {displayDutyLogSection ? (
            <TripCrewDutyLogs
              trip={tripData}
              editable={mode !== 'view'}
              updateTripData={updateTripData}
              aircraftId={id}
              currentOp={currentOp}
            />
          ) : null}
          {tripData?.id ? <TripSectors trip={tripData} /> : null}
          {displayTrendSection ? (
            <TripTrendingSection
              trip={tripData}
              editable={mode !== 'view'}
              updateTripData={updateTripData}
              currentOp={currentOp}
            />
          ) : null}
          <InfoDivWrapper id="attachments" data-testid="TripDrawer-TripAttachments">
            <TripAttachments
              trip={tripData}
              editable={mode !== 'view'}
              updateTripData={updateTripData}
              resetAttachments={resetAttachments}
              setResetAttachments={setResetAttachments}
            />
          </InfoDivWrapper>
          {mode === 'view' ? <HistorySection versions={tripData?.versions} title="trip" /> : null}
          {mode !== 'view' ? (
            <ButtonSection handleCancelClick={handleCancelClick} handleSaveClick={handleSaveClick} loading={loading} />
          ) : null}
        </>
      ) : (
        <Loading loading contain />
      )}
    </DrawerWrapper>
  );
};

export default TripDrawer;
