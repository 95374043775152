import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Label } from '../CommonStyledComponents/CommonStyledComponents';
import { formatTime } from '../../utils/utils';
import TFProgressBar from '../TFProgressBar/TFProgressBar';
import { Maintenance } from '../../models/maintenance';

interface BuildProgressBarProps {
  itemData: Maintenance;
  type: string;
}

const ValueDiv = styled.div`
  font-weight: 500;
  line-height: 1.86;
  color: ${({ barColour, theme }): string => {
    if (barColour === 'amber') {
      return theme.colours.amber;
    }
    if (barColour === 'red') {
      return theme.colours.red;
    }
    return theme.colours.green;
  }};
`;

const BuildProgressBar: React.FC<BuildProgressBarProps> = ({ itemData, type }) => {
  const [percentage, setPercentage] = useState(100);
  const [title, setTitle] = useState('');
  const [barColour, setBarColour] = useState('green');
  const [value, setValue] = useState('');

  const { formatMessage } = useIntl();

  const getBarColour = ({
    tolerance,
    remaining,
    passedPercentage,
    threshold,
  }: {
    tolerance: number;
    remaining: string;
    passedPercentage: number;
    threshold: string;
  }) => {
    if (tolerance && remaining !== '-' && parseInt(remaining, 10) > 0) {
      setBarColour('amber');
    } else if (passedPercentage > 0) {
      setBarColour('green');
    } else if (passedPercentage <= 0 && threshold === 'critical') {
      setBarColour('amber');
    } else if (passedPercentage <= 0) {
      setBarColour('red');
    }
  };

  const getPercentage = ({ remaining, visibleTolerance }) => {
    let newPercentage = (remaining / 100) * 100 > 100 ? 100 : (remaining / 100) * 100;
    if (visibleTolerance) {
      newPercentage = ((remaining + visibleTolerance) / 100) * 100 > 100 ? 100 : (remaining / 100) * 100;
    }
    return newPercentage;
  };

  const getPercentageForDays = ({ remaining, visibleTolerance }) => {
    let newPercentage = 0;
    if (itemData?.unit_of_time === 'days') {
      const tolerance = visibleTolerance || 0;
      newPercentage = ((remaining + tolerance) / 365) * 100 > 100 ? 100 : ((remaining + tolerance) / 365) * 100;
    } else {
      const tolerance = visibleTolerance || 0;
      newPercentage =
        ((remaining + tolerance * 30) / 30 / 12) * 100 > 100 ? 100 : ((remaining + tolerance * 30) / 30 / 12) * 100;
    }
    return newPercentage;
  };

  useEffect(() => {
    if (type === 'hours') {
      const remaining = itemData.flight_seconds_remaining / 3600;
      const newPercentage = getPercentage({ remaining, visibleTolerance: itemData.flight_seconds_visible_tolerance });
      setPercentage(newPercentage);
      setTitle(formatMessage({ id: 'text.hoursLeft' }));
      let newValue =
        itemData?.flight_seconds_remaining === null
          ? '-'
          : formatTime(
              itemData.flight_seconds_remaining +
                (itemData.flight_seconds_visible_tolerance ? itemData.flight_seconds_visible_tolerance : 0),
            );
      if (parseInt(newValue, 10) < 0) {
        newValue = '0';
      }
      setValue(newValue);
      const currentThreshold =
        itemData.flight_seconds_visible_tolerance !== null &&
        itemData.flight_seconds_remaining < 0 &&
        itemData.flight_seconds_remaining + itemData.flight_seconds_visible_tolerance > 0
          ? 'critical'
          : '';
      getBarColour({
        tolerance: itemData.flight_seconds_visible_tolerance,
        remaining: newValue,
        passedPercentage: newPercentage,
        threshold: currentThreshold,
      });
    }
    if (type === 'apu') {
      const remaining = itemData.apu_seconds_remaining / 3600;
      const newPercentage = getPercentage({ remaining, visibleTolerance: itemData.apu_seconds_visible_tolerance });
      setPercentage(newPercentage);
      setTitle(formatMessage({ id: 'text.apuHoursLeft' }));
      let newValue =
        itemData?.apu_seconds_remaining === null
          ? '-'
          : formatTime(
              itemData.apu_seconds_remaining +
                (itemData.apu_seconds_visible_tolerance ? itemData.apu_seconds_visible_tolerance : 0),
            );
      if (parseInt(newValue, 10) < 0) {
        newValue = '0';
      }
      setValue(newValue);
      const currentThreshold =
        itemData.apu_seconds_visible_tolerance !== null &&
        itemData.apu_seconds_remaining < 0 &&
        itemData.apu_seconds_remaining + itemData.apu_seconds_visible_tolerance > 0
          ? 'critical'
          : '';
      getBarColour({
        tolerance: itemData.apu_seconds_visible_tolerance,
        remaining: newValue,
        passedPercentage: newPercentage,
        threshold: currentThreshold,
      });
    }
    if (type === 'cycles') {
      const remaining = itemData.cycles_remaining;
      const newPercentage = getPercentage({ remaining, visibleTolerance: itemData.cycles_visible_tolerance });
      setPercentage(newPercentage);
      setTitle(formatMessage({ id: 'text.cyclesLeft' }));
      let newValue =
        itemData?.cycles_remaining === null
          ? '-'
          : (
              itemData.cycles_remaining + (itemData.cycles_visible_tolerance ? itemData.cycles_visible_tolerance : 0)
            ).toString();
      if (parseInt(newValue, 10) < 0) {
        newValue = '0';
      }
      setValue(newValue);
      const currentThreshold =
        itemData.cycles_visible_tolerance !== null &&
        itemData.cycles_remaining < 0 &&
        itemData.cycles_remaining + itemData.cycles_visible_tolerance > 0
          ? 'critical'
          : '';
      getBarColour({
        tolerance: itemData.cycles_visible_tolerance,
        remaining: newValue,
        passedPercentage: newPercentage,
        threshold: currentThreshold,
      });
    }
    if (type === 'days') {
      const newPercentage = getPercentageForDays({
        remaining: itemData.days_remaining,
        visibleTolerance:
          itemData?.unit_of_time === 'months' ? itemData.months_visible_tolerance : itemData.days_visible_tolerance,
      });
      setPercentage(newPercentage);
      setTitle(
        itemData?.unit_of_time === 'months'
          ? formatMessage({ id: 'text.monthsLeft' })
          : formatMessage({ id: 'text.daysLeft' }),
      );
      let newValue =
        itemData?.days_remaining === null
          ? '-'
          : (
              itemData.days_remaining + (itemData.days_visible_tolerance ? itemData.days_visible_tolerance : 0)
            ).toString();
      if (itemData.unit_of_time === 'months') {
        newValue =
          itemData?.days_remaining === null
            ? '-'
            : Math.round(
                itemData.days_remaining / 30 +
                  (itemData.months_visible_tolerance ? itemData.months_visible_tolerance : 0),
              ).toString();
      }
      if (parseInt(newValue, 10) < 0) {
        newValue = '0';
      }
      setValue(newValue);
      let currentThreshold =
        itemData.days_visible_tolerance !== null &&
        itemData.days_remaining < 0 &&
        itemData.days_remaining + itemData.days_visible_tolerance > 0
          ? 'critical'
          : '';
      if (itemData.unit_of_time === 'months') {
        currentThreshold =
          itemData.months_visible_tolerance !== null &&
          Math.round(itemData.days_remaining) < 0 &&
          Math.round(itemData.days_remaining) + itemData.months_visible_tolerance * 30 > 0
            ? 'critical'
            : '';
      }
      getBarColour({
        tolerance:
          itemData?.unit_of_time === 'months' ? itemData.months_visible_tolerance : itemData.days_visible_tolerance,
        remaining: newValue,
        passedPercentage: newPercentage,
        threshold: currentThreshold,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData, type]);

  return (
    <div>
      <Label>{title}</Label>
      <ValueDiv barColour={barColour}>{value}</ValueDiv>
      <TFProgressBar colour={barColour} percentage={percentage < 0 ? 100 : percentage} />
    </div>
  );
};

export default BuildProgressBar;
