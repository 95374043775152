import { BaseModel } from '..';
import { OperatorSetting } from '../userSettings';

export enum PeopleActionTypes {
  SAVE = 'people/save',
  SAVE_ALL = 'people/saveAll',
  REMOVE = 'people/removePerson',
  SAVE_SINGLE = 'people/saveSingle',
}

interface SavePeopleActionType {
  type: PeopleActionTypes.SAVE;
  payload: Person;
}

interface SaveSinglePeopleActionType {
  type: PeopleActionTypes.SAVE_SINGLE;
  payload: Person;
}

interface SaveAllPeopleActionType {
  type: PeopleActionTypes.SAVE_ALL;
  payload: Person[];
}

interface RemovePeopleActionType {
  type: PeopleActionTypes.REMOVE;
  payload: { removedId };
}

type PeopleAction =
  | SavePeopleActionType
  | SaveAllPeopleActionType
  | RemovePeopleActionType
  | SaveSinglePeopleActionType;

const initialState: PeopleState = {
  peopleMap: new Map<string, Person>(),
  deletedPeopleMap: new Map<string, Person>(),
  lastFetched: 0,
  ttl: 30000,
  currentPerson: undefined,
};

export default function (state = initialState, action: PeopleAction): PeopleState {
  switch (action.type) {
    case PeopleActionTypes.SAVE: {
      const peopleMap = new Map(state.peopleMap.entries());
      peopleMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        peopleMap,
      };
    }
    case PeopleActionTypes.SAVE_ALL: {
      const peopleMap = new Map(state.peopleMap.entries());
      const deletedPeopleMap = new Map(state.deletedPeopleMap.entries());
      if (Array.isArray(action.payload)) {
        action.payload.forEach((person) => {
          if (person.is_deleted) {
            deletedPeopleMap.set(person.id, {
              ...deletedPeopleMap.get(person.id),
              ...person,
            });
          } else {
            peopleMap.set(person.id, {
              ...peopleMap.get(person.id),
              ...person,
            });
          }
        });
      }
      return {
        ...state,
        peopleMap,
        deletedPeopleMap,
        lastFetched: Date.now(),
      };
    }
    case PeopleActionTypes.SAVE_SINGLE: {
      return {
        ...state,
        currentPerson: action.payload,
      };
    }
    case PeopleActionTypes.REMOVE: {
      const peopleMap = new Map(state.peopleMap.entries());

      if (action.payload.removedId) {
        peopleMap.delete(action.payload.removedId);
      }
      return {
        ...state,
        peopleMap,
      };
    }
    default:
      return state;
  }
}

export interface PeopleState {
  peopleMap: Map<string, Person>;
  deletedPeopleMap: Map<string, Person>;
  lastFetched: number;
  ttl: number;
  currentPerson?: Person;
}

export interface Organisation {
  id: string;
  value?: string;
  name: string;
  address: string;
  aoc_number: string;
  date_format: string;
  ddl_template: string;
  logo_url: string;
  dark_logo_url: string;
  custom_signoff_text: {};
  operator_setting: OperatorSetting;
}

export interface Person extends BaseModel {
  id: string;
  last_updated: number;
  first_name: string;
  last_name: string;
  position: string;
  email: string;
  type: string;
  role: string;
  avatar_url: string;
  organisation: Organisation;
  has_login?: boolean;
  permission_groups?: any;
  employee_number?: string;
  amos_user_sign?: string;
  organisation_aircraft?: {
    aircraft_id: string;
    aircraft_registration: string;
  }[];
  is_deleted?: string;
  capabilities?: {
    id?: string;
    capability_id: string;
    _destroy?: boolean;
  }[];
}

export interface Capability {
  id: string;
  key: string;
  name: string;
  description: string;
}
