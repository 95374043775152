import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import getOperatorSetting from '../../utils/getOperatorSetting';
import { getPublicShareURL } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';

interface CRSButtonProps {
  crsItemId: string;
  status: string;
  type: 'scheduled_mx_items' | 'defects' | 'workpack';
}
const StyledLinkButton = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  color: ${({ theme }): string => theme.colours.blue};
  cursor: pointer;
`;

const CRSButton: React.FC<CRSButtonProps> = ({ crsItemId, status, type }) => {
  const [publicLinkLoading, setPublicLinkLoading] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const crsShortName = getOperatorSetting('crs_short_name', id);
  const approvalReleaseMode = getOperatorSetting('approval_release_mode', id);

  const getPublicUrlLink = async (): Promise<void> => {
    setPublicLinkLoading(true);
    getPublicShareURL({ type, id: crsItemId }).then((url: string) => {
      if (url) {
        setPublicLinkLoading(false);
        window.open(url, '_blank');
      } else {
        setPublicLinkLoading(false);
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'text.failedToGetCRS' }, { field: crsShortName }),
              message: formatMessage({ id: 'text.thereWasAnIssueWithNetwork' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
            },
          }),
        );
      }
    });
  };

  let buttonText = formatMessage({ id: 'form.button.addCRS' }, { field: crsShortName });
  if (approvalReleaseMode === 'after_any_work_completed_release') {
    buttonText = formatMessage({ id: 'form.button.submitResolution' });
    if (status === 'resolved') buttonText = formatMessage({ id: 'form.button.addCRS' }, { field: crsShortName });
  } else if (status === 'resolved') {
    buttonText = formatMessage({ id: 'form.button.viewCRS' }, { field: crsShortName });
  }
  if (publicLinkLoading) {
    return <Loading loading={publicLinkLoading} contain hasTransparentBackground size={20} />;
  }
  return (
    <StyledLinkButton data-testid="CRSButton" onClick={() => getPublicUrlLink()}>
      {buttonText}
    </StyledLinkButton>
  );
};

export default CRSButton;
