import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import useDefectDisplayDate from '../../../utils/useDefectDisplayDate';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface DeferUntilProps {
  defect: Defect | null;
  nonMEL?: boolean;
  formChanged: boolean;
}

const defaultProps = {
  nonMEL: false,
};

const DeferUntil: React.FC<DeferUntilProps> = ({ defect, nonMEL, formChanged }) => {
  const { formatMessage } = useIntl();

  const [displayDate, setDisplayDate] = useState(null);

  const newDate = useDefectDisplayDate({ defect, ignoreDatetimeDue: formChanged });

  useEffect(() => {
    if (defect) {
      setDisplayDate(newDate);
    }
  }, [defect, newDate]);

  return (
    <FlexWrapper marginRight={20} identifier="DeferUntil-Wrapper">
      {defect?.display_data?.category === 'Advisory only' ? null : (
        <>
          <FlexWrapper column marginRight={20} identifier="DeferUntil-InnerWrapper">
            <Label
              color="rgba(36, 45, 65, 0.4)"
              fontWeight={400}
              marginBottom={nonMEL ? 5 : 0}
              width="120px"
              identifier="DeferUntil-Label"
            >
              {formatMessage({ id: 'text.deferUntil' })}
            </Label>
            <Label height="30px" identifier="DeferUntil-Date" width="180px">
              {`${displayDate}z`}
            </Label>
          </FlexWrapper>
        </>
      )}
    </FlexWrapper>
  );
};

DeferUntil.defaultProps = defaultProps;

export default DeferUntil;
