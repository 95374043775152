import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DashboardState } from '../../../models';
import useDefectDisplayDate from '../../../utils/useDefectDisplayDate';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';

interface ViewDeferralOptionsProps {
  defect: Defect | null;
  aircraftId?: string;
  standardFields?: {
    deferral_type_mel?: { name_override?: string };
    deferral_type_cdl?: { name_override?: string };
    deferral_type_cas?: { name_override?: string };
    deferral_type_nef?: { name_override?: string };
    deferral_type_other?: { name_override?: string };
  };
  formChanged: boolean;
}

const defaultProps = {
  aircraftId: null,
  standardFields: null,
};

const ViewDeferralOptions: React.FC<ViewDeferralOptionsProps> = ({
  defect,
  aircraftId,
  standardFields,
  formChanged,
}) => {
  const {
    userSettings: { details },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const defectNameOverride =
    details?.operators?.find((op) => op?.id === aircraftMap.get(id)?.operator_id)?.operator_setting?.defect_name ||
    'Defect';

  const { formatMessage } = useIntl();
  let defect_type;
  let deferred;
  let ata;
  let ataSection;
  let reference;
  let interval;
  let subSection;
  let subSubSection;
  if (defect) {
    defect_type = defect?.defect_type;
    deferred = defect?.deferred;
    ata = defect?.display_data?.ata;
    interval = defect?.display_data?.category;
    ataSection = defect?.display_data?.ata_section;
    if (defect_type === 'MEL') ataSection = defect?.mel_item?.section_number;
    subSection = defect?.mel_item?.subsection_number;
    subSubSection = defect?.mel_item?.subsubsection;
    reference = defect?.reference;
  }

  if (standardFields) {
    if (standardFields?.deferral_type_mel?.name_override && defect_type === 'MEL')
      defect_type = standardFields?.deferral_type_mel?.name_override;
    if (standardFields?.deferral_type_cdl?.name_override && defect_type === 'CDL')
      defect_type = standardFields?.deferral_type_cdl?.name_override;
    if (standardFields?.deferral_type_cas?.name_override && defect_type === 'CAS')
      defect_type = standardFields?.deferral_type_cas?.name_override;
    if (standardFields?.deferral_type_nef?.name_override && defect_type === 'NEF')
      defect_type = standardFields?.deferral_type_nef?.name_override;
    if (standardFields?.deferral_type_other?.name_override && defect_type === 'Other')
      defect_type = standardFields?.deferral_type_other?.name_override;
  } else {
    if (aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override && defect_type === 'MEL')
      defect_type = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override;
    if (aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.name_override && defect_type === 'CDL')
      defect_type = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.name_override;
    if (aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.name_override && defect_type === 'CAS')
      defect_type = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.name_override;
    if (aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.name_override && defect_type === 'NEF')
      defect_type = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.name_override;
    if (
      aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other?.name_override &&
      defect_type === 'Other'
    )
      defect_type = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other?.name_override;
  }

  let defectDate = 'N/A';

  const newDeferredUntilDate = useDefectDisplayDate({ defect, ignoreDatetimeDue: formChanged });

  if (newDeferredUntilDate) {
    defectDate = newDeferredUntilDate;
  }

  const isMobile = window.innerWidth < 451;
  return (
    <FlexWrapper marginTop={20} column>
      <Label marginBottom={20}>{formatMessage({ id: 'text.deferralOptions' })}</Label>
      <GridWrapper columns={isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr'} rows="auto" rowGap={20}>
        <FlexWrapper column>
          <Label textTransform="uppercase" fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'text.defectTypeCaps' }, { field: defectNameOverride })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{deferred ? 'Deferred' : 'Not Deferred'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'text.deferralOptionsCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{defect_type || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.categoryCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{interval || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.ataChapterCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{ata || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.ataSectionCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{ataSection || ataSection === 0 ? ataSection : '-'}</Label>
        </FlexWrapper>
        {defect_type === 'MEL' ? (
          <FlexWrapper column>
            <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'text.subSection' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{subSection || subSection === 0 ? subSection : '-'}</Label>
          </FlexWrapper>
        ) : null}
        {defect_type === 'MEL' ? (
          <FlexWrapper column>
            <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'text.subSubSection' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{subSubSection || '-'}</Label>
          </FlexWrapper>
        ) : null}
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.referenceCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{reference || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.deferredUntil' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{`${defectDate}z`}</Label>
        </FlexWrapper>
      </GridWrapper>
      {interval === 'A' && (
        <GridWrapper columns="1fr 1fr 1fr 1fr" rows="1fr 1fr" marginTop={20} rowGap={20}>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightHourLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flight_seconds_limit / 3600 || '-'}</Label>
          </FlexWrapper>
          {defect?.aircraft?.apu_installed ? (
            <FlexWrapper column>
              <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
                {formatMessage({ id: 'form.labels.apuHoursLimit' })}
              </Label>
              <Label color="rgba(36, 45, 65, 0.7)">{defect?.apu_seconds_limit / 3600 || '-'}</Label>
            </FlexWrapper>
          ) : null}
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flight_days_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.calendarHoursLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.seconds_limit / 3600 || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.calendarDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.calendar_days_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.cyclesLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.cycles_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flights_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.otherLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.other_limit || '-'}</Label>
          </FlexWrapper>
        </GridWrapper>
      )}
    </FlexWrapper>
  );
};

ViewDeferralOptions.defaultProps = defaultProps;

export default ViewDeferralOptions;
