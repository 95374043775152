import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Badge } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import StatusBadge from '../../Status/StatusBadge';
import { DashboardState } from '../../../models';
import PublicShareLink from '../../PublicShareLink/PublicShareLink';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  justify-content: space-between;
  @media (max-width: 451px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.span`
  color: rgba(36, 45, 65, 0.4);
  font-size: 12px;
  text-transform: uppercase;
`;

const StatusDiv = styled.span`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
  color: #646464;
  span {
    margin-left: 4px;
  }
`;

const WorkpackInfo = ({ dateCreated, status, dateCompleted, publicLink }): JSX.Element => {
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();

  const {
    userSettings: {
      dateFormat,
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  let badgeValue = '';
  if (status === 'draft') badgeValue = 'grey';
  if (status === 'active') badgeValue = 'blue';
  if (status === 'pending') badgeValue = 'amber';

  return (
    <>
      <TitleWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.crsAcceptance' }, { field: operatorSettings?.crs_short_name })}</Heading>
          <StatusDiv>
            <StatusBadge status={status} /> {status}
          </StatusDiv>
        </ItemWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.engineeringWork' })}</Heading>
          <StatusDiv>
            <Badge color={status === 'pending' ? '' : badgeValue} />{' '}
            <span>{status === 'pending' ? 'completed' : status}</span>
          </StatusDiv>
        </ItemWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.dateCreated' })}</Heading>
          <StatusDiv>{moment(dateCreated, 'YYYY-MM-DD').format(dateFormat)}</StatusDiv>
        </ItemWrapper>
        <ItemWrapper>
          <Heading>{formatMessage({ id: 'text.dateCompleted' })}</Heading>
          <StatusDiv>
            {(dateCompleted && moment(dateCompleted.replace(/T.*/gm, ''), 'YYYY-MM-DD').format(dateFormat)) || '-'}
          </StatusDiv>
        </ItemWrapper>
        <div>{status !== 'draft' ? <PublicShareLink type="workpacks" passedPublicLink={publicLink} /> : null}</div>
      </TitleWrapper>
    </>
  );
};

export default WorkpackInfo;
