import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import calendarIcon from '../../assets/icon-small-calendar.svg';
import DocumentLink from '../DocumentLink/DocumentLink';

interface TripUpdateDetailsProps {
  trip: any;
  updateTripUpdateData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
  padding-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
  border-top: ${({ borderTop }): string => (borderTop ? '1px solid rgba(36, 45, 65, 0.05)' : null)};
`;

const ItemWrapper = styled.div`
  margin-right: 32px;
  margin-bottom: 16px;
`;

const Header = styled.div`
  color: ${({ editable }): string => (editable ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  letter-spacing: ${({ editable }): string => (editable ? 'normal' : '0.4px')};
  font-size: ${({ editable }): string => (editable ? '14px' : '12px')};
  text-transform: ${({ editable }): string => (editable ? 'capitalize' : 'uppercase')};
  font-weight: ${({ editable }): string => (editable ? '500' : 'normal')};
  margin-bottom: 4px;
`;

const DisplayText = styled.div`
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: ${({ cap }): string => (cap ? 'capitalize' : 'none')};
`;

const StyledInput = styled.input`
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  height: 34px;
  border-radius: 2px;
  border: 1px solid rgba(36, 45, 65, 0.2);
  padding: 0 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 200px;
  height: 34px;
  .ant-calendar-picker-input.ant-input {
    height: 34px;
  }
  @media (max-width: 451px) {
    width: 285px;
  }
`;

const TripUpdateDetails: React.FC<TripUpdateDetailsProps> = ({ trip, updateTripUpdateData }) => {
  const {
    drawer: { mode },
    userSettings: {
      dateFormat,
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [localDate, setLocalDate] = useState(moment.utc());

  const operatorSettings = operators?.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const handleInputChange = (value: any, key: string): void => {
    updateTripUpdateData([{ value, key }]);
  };

  const disabledDate = (current): boolean => {
    if (mode === 'add') {
      return current && current > moment().add(7, 'day').startOf('day');
    }
    return false;
  };

  useEffect(() => {
    if (trip?.date) {
      setLocalDate(moment.utc(trip.date));
    }
  }, [dateFormat, trip]);

  return (
    <SectionWrapper data-testid="TripUpdateDetails-SectionWrapper">
      <ItemWrapper data-testid="TripUpdateDetails-TripUpdateNumber">
        <Header editable={mode !== 'view'}>Trip update no.</Header>
        {mode === 'view' || mode === 'override' ? (
          <DisplayText>{trip?.number}</DisplayText>
        ) : (
          <StyledInput
            placeholder="-"
            value={trip?.number || undefined}
            onChange={(e): void => handleInputChange(e.target.value, 'number')}
            type="number"
            data-testid="TripUpdateDetails-NumberInput"
          />
        )}
      </ItemWrapper>
      <ItemWrapper data-testid="TripUpdateDetails-TripUpdateDate">
        <Header editable={mode !== 'view'}>Date</Header>
        {mode === 'view' || mode === 'override' ? (
          <DisplayText>{localDate ? moment.utc(localDate).format(dateFormat) : '-'}</DisplayText>
        ) : (
          <StyledDatePicker
            format={dateFormat}
            value={moment.utc(localDate)}
            suffixIcon={<img src={calendarIcon} alt="calendarIcon" />}
            allowClear={false}
            onChange={(dateMoment): void => handleInputChange(moment.utc(dateMoment).format('YYYY-MM-DD'), 'date')}
            disabledDate={disabledDate}
            data-testid="TripUpdateDetails-DateInput"
          />
        )}
      </ItemWrapper>
      {!operatorSettings?.hide_srp_links && (
        <ItemWrapper data-testid="TripUpdateDetails-TripUpdateSRPNumber">
          <Header editable={mode !== 'view'}>{`${operatorSettings?.srp} number`}</Header>
          {mode === 'view' || mode === 'override' ? (
            <DocumentLink docType="srp" id={trip?.id} number={trip?.srp_number} aircraftId={id} />
          ) : (
            <StyledInput
              placeholder="-"
              value={trip?.srp_number || undefined}
              onChange={(e): void => handleInputChange(e.target.value, 'srp_number')}
              type="number"
              data-testid="TripUpdateDetails-SRPInput"
            />
          )}
        </ItemWrapper>
      )}
    </SectionWrapper>
  );
};

export default TripUpdateDetails;
