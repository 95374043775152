import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col } from 'antd';
import { Button as TFButton, Modal } from '@arcflight/tf-component-library';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import { updateMxItemSkipAPI } from '../../../models/maintenance/actions';
import { updateMaintenance } from '../../../services/apiNew';
import { ToastCategories, ToastTypes, addToast } from '../../../models/toasts';
import Loading from '../../TFLoading';
import bigIcon from './assets/maintenance-item.svg';
import styles from './MXModal.module.less';
import ToleranceCounter from './ToleranceCounter';

const cookies = new Cookies();

class MXModalTolerance extends Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    item: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    aircraft: PropTypes.object,
    updateMxItemSkipAPI: PropTypes.func.isRequired,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
    userSettings: PropTypes.object.isRequired,
    addNewToast: PropTypes.func.isRequired,
  };

  static defaultProps = {
    item: {},
    aircraft: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      changedTolerances: {},
    };
  }

  handleSubmit = () => {
    const { changedTolerances } = this.state;
    const {
      item,
      aircraft,
      intl: { formatMessage },
      addNewToast,
    } = this.props;
    const id = item ? item.id : '';

    if (Object.keys(changedTolerances).length === 0) {
      addNewToast({
        title: formatMessage({ id: 'message.noToleranceAppliedByUser' }),
        type: ToastTypes.ERROR,
        category: ToastCategories.FLAG,
        message: '',
      });
      return;
    }
    const payload = {
      id,
      itemData: { ...changedTolerances },
      aircraft_id: aircraft.id,
      organisation_id: cookies.get('org'),
    };
    this.setState({ submitting: true }, async () => {
      try {
        const response = await updateMaintenance(payload);

        if (response && response.status >= 200 && response.status < 400) {
          addNewToast({
            title: formatMessage({ id: 'message.mxItemUpdated' }, { name: response.data.name }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          });
          this.props.updateMxItemSkipAPI(response.data);
          this.props.hideModal();
          this.setState({ submitting: false, changedTolerances: {} });
        }
      } catch (error) {
        this.setState({ submitting: false });
        addNewToast({
          title: 'Error updating tolerance',
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
          message: '',
        });
        return null;
      }
      this.setState({ submitting: false });
      return null;
    });
  };

  getItemAreaName = (item) => {
    const { mx_type, name } = item;
    let itemArea = mx_type.substring(0, mx_type.lastIndexOf('_'));
    if (itemArea === 'apu') itemArea = 'APU';
    return (
      <>
        <span>{itemArea.replace(/_/g, ' ')},</span>
        {name}
      </>
    );
  };

  toleranceChange = (field, value) => {
    const { changedTolerances } = this.state;
    this.setState({
      changedTolerances: {
        ...changedTolerances,
        [field]: value,
      },
    });
  };

  render() {
    const {
      visible,
      aircraft,
      item,
      intl: { formatMessage },
      hideModal,
      userSettings,
    } = this.props;
    const { submitting } = this.state;
    const hasAPU = aircraft ? aircraft.apu_installed : false;
    const itemName = item ? this.getItemAreaName(item) : null;

    return (
      <Modal handleClose={hideModal} isOpen={visible} width={785}>
        <Loading loading={submitting} contain />
        <Row gutter={16}>
          <Col md={8} className={styles.iconWrapper}>
            <img src={bigIcon} alt="sharingBigIcon" className={styles.bigIcon} data-test="bigIcon" />
          </Col>
          <Col lg={14} md={16}>
            <div className={styles.modalTitle} data-test="title">
              {formatMessage({ id: 'title.applyTolerance' })} {itemName}
            </div>
            <div className={styles.modalContent}>
              {item && (
                <>
                  {item.flight_seconds_due !== null && (
                    <Row gutter={16} className={styles.modalRow}>
                      <Col md={24}>
                        <div className={styles.timesTitle} data-test="flightHoursTitle">
                          <span>{formatMessage({ id: 'period.fltHours' })}</span>
                        </div>
                      </Col>
                      <Col md={24}>
                        <ToleranceCounter
                          itemTolerance={item.flight_seconds_tolerance}
                          itemVisibleTolerance={item.flight_seconds_visible_tolerance}
                          nextDue={item.flight_seconds_due}
                          currentItem={item}
                          remaining={item.flight_seconds_remaining}
                          unitSingular={formatMessage({ id: 'units.hourShort' })}
                          unitPlural={formatMessage({ id: 'units.hoursShort' })}
                          type="seconds"
                          onChange={(value) => this.toleranceChange('flight_seconds_visible_tolerance', value)}
                          data-test="toleranceCounterFlightHours"
                        />
                      </Col>
                    </Row>
                  )}
                  {item.cycles_due !== null && (
                    <Row gutter={16} className={styles.modalRow}>
                      <Col md={24}>
                        <div className={styles.timesTitle} data-test="cyclesTitle">
                          <span>{formatMessage({ id: 'text.cycles' })}</span>
                        </div>
                      </Col>
                      <Col md={24}>
                        <ToleranceCounter
                          itemTolerance={item.cycles_tolerance}
                          itemVisibleTolerance={item.cycles_visible_tolerance}
                          nextDue={item.cycles_due}
                          remaining={item.cycles_remaining}
                          currentItem={item}
                          unitSingular={formatMessage({ id: 'units.cycle' })}
                          unitPlural={formatMessage({ id: 'units.cycles' })}
                          onChange={(value) => this.toleranceChange('cycles_visible_tolerance', value)}
                          data-test="toleranceCounterCycles"
                        />
                      </Col>
                    </Row>
                  )}
                  {item.date_due !== null && item.unit_of_time === 'months' && (
                    <Row gutter={16} className={styles.modalRow}>
                      <Col md={24}>
                        <div className={styles.timesTitle} data-test="monthsTitle">
                          <span>{formatMessage({ id: 'title.months' })}</span>
                        </div>
                      </Col>
                      <Col md={24}>
                        <ToleranceCounter
                          dateFormat={userSettings?.dateFormat}
                          itemTolerance={item.months_tolerance}
                          itemVisibleTolerance={item.months_visible_tolerance}
                          nextDue={item.date_due}
                          remaining={item.days_remaining}
                          currentItem={item}
                          unitSingular={formatMessage({ id: 'units.monthShort' })}
                          unitPlural={formatMessage({ id: 'units.monthsShort' })}
                          type="months"
                          onChange={(value) => this.toleranceChange('months_visible_tolerance', value)}
                          data-test="toleranceCounterMonths"
                        />
                      </Col>
                    </Row>
                  )}
                  {item.date_due !== null && item.unit_of_time === 'days' && (
                    <Row gutter={16} className={styles.modalRow}>
                      <Col md={24}>
                        <div className={styles.timesTitle} data-test="daysTitle">
                          <span>{formatMessage({ id: 'title.days' })}</span>
                        </div>
                      </Col>
                      <Col md={24}>
                        <ToleranceCounter
                          dateFormat={userSettings?.dateFormat}
                          itemTolerance={item.days_tolerance}
                          itemVisibleTolerance={item.days_visible_tolerance}
                          nextDue={item.date_due}
                          remaining={item.days_remaining}
                          currentItem={item}
                          unitSingular={formatMessage({ id: 'units.day' })}
                          unitPlural={formatMessage({ id: 'units.days' })}
                          type="days"
                          onChange={(value) => this.toleranceChange('days_visible_tolerance', value)}
                          data-test="toleranceCounterDays"
                        />
                      </Col>
                    </Row>
                  )}
                  {hasAPU && item.apu_seconds_due !== null && (
                    <Row gutter={16} className={styles.modalRow}>
                      <Col md={24}>
                        <div className={styles.timesTitle} data-test="apuHoursTitle">
                          <span>{formatMessage({ id: 'period.APUhours' })}</span>
                        </div>
                      </Col>
                      <Col md={24}>
                        <ToleranceCounter
                          itemTolerance={item.apu_seconds_tolerance}
                          itemVisibleTolerance={item.apu_seconds_visible_tolerance}
                          nextDue={item.apu_seconds_due}
                          remaining={item.apu_seconds_remaining}
                          currentItem={item}
                          unitSingular={formatMessage({ id: 'units.apuHour' })}
                          unitPlural={formatMessage({ id: 'units.apuHours' })}
                          type="seconds"
                          onChange={(value) => this.toleranceChange('apu_seconds_visible_tolerance', value)}
                          data-test="toleranceCounterAPUHours"
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className={`${styles.rowSpaceTop} ${styles.rowSpaceTopBig}`}>
                    <Col md={24} className={styles.footerWrapper}>
                      <TFButton
                        onClick={() => this.handleSubmit()}
                        size={ButtonSize.MEDIUM}
                        data-test="submitButton"
                        loading={submitting}
                      >
                        {formatMessage({ id: 'form.button.applyTolerance' })}
                      </TFButton>
                      <div className={styles.cancelWrapper}>
                        <TFButton size={ButtonSize.MEDIUM} onClick={hideModal} primary={false}>
                          Cancel
                        </TFButton>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    ({ userSettings }) => ({ userSettings }),
    (dispatch) => ({
      updateMxItemSkipAPI: (payload) => {
        return dispatch(
          updateMxItemSkipAPI({
            payload,
          }),
        );
      },
      addNewToast: (payload) => {
        return dispatch(
          addToast({
            payload,
          }),
        );
      },
    }),
  ),
  Form.create(),
)(MXModalTolerance);
