import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DashboardState } from '../models';
import { Defect } from '../models/defects';
import { getNewDateDueBasedOnInterval } from '../components/DefectDrawer/components/utils';

interface UseDefectDisplayDateProps {
  defect: Defect;
  ignoreDatetimeDue: boolean;
}

const useDefectDisplayDate = ({ defect, ignoreDatetimeDue }: UseDefectDisplayDateProps): string => {
  const {
    userSettings: { details, dateFormat },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const getEndOfDay = (date?: string) => {
    let endOfDay = moment.utc().startOf('day').add(23, 'hours').add(59, 'minutes');
    if (date) {
      endOfDay = moment.utc(date).startOf('day').add(23, 'hours').add(59, 'minutes');
    }
    return endOfDay;
  };

  const formatDefectDate = (date: Moment): string => date.format(`${dateFormat} HH:mm`);

  const utcOffsetSeconds =
    details?.operators?.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting
      ?.utc_offset_seconds || 0;

  const calculateNewDate = (baseDate: Moment): Moment => {
    let newDate = baseDate;
    if (utcOffsetSeconds !== 0) {
      newDate = newDate.add(utcOffsetSeconds, 'seconds');
    }
    return newDate;
  };

  if (!defect) return '-';

  let defectDate;

  let baseDate;

  if (defect?.datetime_due && !ignoreDatetimeDue) {
    defectDate = moment.utc(defect?.datetime_due);
  } else if (defect?.display_data?.category === 'A') {
    baseDate = getEndOfDay(defect?.raised_at);
    if (defect?.calendar_days_limit && defect?.seconds_limit) {
      const dateWithCalendarDaysLimit = calculateNewDate(baseDate.add(defect.calendar_days_limit, 'days'));
      const dateWithSecondsLimit = moment.utc(defect.raised_at).add(defect.seconds_limit, 'seconds');
      if (dateWithCalendarDaysLimit.isBefore(dateWithSecondsLimit)) {
        defectDate = dateWithCalendarDaysLimit;
      } else {
        defectDate = dateWithSecondsLimit;
      }
    } else if (defect?.calendar_days_limit) {
      defectDate = calculateNewDate(baseDate.add(defect.calendar_days_limit, 'days'));
    } else if (defect?.seconds_limit) {
      defectDate = moment.utc(defect.raised_at).add(defect.seconds_limit, 'seconds');
    } else {
      defectDate = calculateNewDate(baseDate);
    }
  } else {
    const newDate = getNewDateDueBasedOnInterval(defect?.display_data?.category, defect?.raised_at);
    defectDate = calculateNewDate(getEndOfDay(newDate));
  }

  return formatDefectDate(defectDate);
};

export default useDefectDisplayDate;
