import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';
import TFInput from '../../components/TFInput/TFInput';
import TFDropdown from '../../components/TFDropdown/TFDropdown';
import TFRadioInput from '../../components/TFRadioInput/TFRadioInput';
import { CustomField } from '../../models/aircraft';

interface CustomFieldsEditMainDetailsProps {
  customFieldsData: CustomField;
  updateCustomFieldsData: (changes: { value: any; key: string }[]) => void;
  validationErrors: string[];
  keyGenerated: boolean;
  setKeyGenerated: (keyGenerated: boolean) => void;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const CUSTOM_FIELD_TYPES = [
  { title: 'Number', value: 'number' },
  { title: 'Text', value: 'text' },
  { title: 'Counter', value: 'counter' },
  { title: 'String', value: 'string' },
  { title: 'Boolean', value: 'boolean' },
  { title: 'Attachment', value: 'attachment' },
  { title: 'Date', value: 'date' },
  { title: 'Selector', value: 'selector' },
  { title: 'Duration', value: 'duration' },
];

const CUSTOM_FIELD_VIEWS = [
  { title: 'Preflight', value: 'preflight' },
  { title: 'Postflight', value: 'postflight' },
  { title: 'Trip', value: 'trip' },
];

const CustomFieldsEditMainDetails: React.FC<CustomFieldsEditMainDetailsProps> = ({
  customFieldsData,
  updateCustomFieldsData,
  validationErrors,
  keyGenerated,
  setKeyGenerated,
}) => {
  const [locationOptions, setLocationOptions] = useState(CUSTOM_FIELD_VIEWS);

  const { formatMessage } = useIntl();

  const capitaliseString = (str: string): string => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return '';
  };

  const generateUUID = (): string => {
    return uuidv4();
  };

  useEffect(() => {
    if (customFieldsData?.type === 'attachment') {
      const newOptions = CUSTOM_FIELD_VIEWS.filter((item) => item.value !== 'trip');
      setLocationOptions(newOptions);
    } else {
      setLocationOptions(CUSTOM_FIELD_VIEWS);
    }
  }, [customFieldsData]);

  useEffect(() => {
    if (!customFieldsData?.key && !keyGenerated) {
      const newKey = generateUUID();
      setKeyGenerated(true);
      updateCustomFieldsData([{ key: 'key', value: newKey }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldsData]);
  return (
    <Wrapper data-testid="CustomFieldEditMainDetails--Wrapper">
      <TFInput
        id="MainDetails-Title"
        value={customFieldsData?.title}
        onChange={(e): void => updateCustomFieldsData([{ key: 'title', value: e.target.value }])}
        label="Title"
        tooltip={formatMessage({ id: 'text.displayOnTheLeftSideOfIpad' })}
        status={validationErrors.includes('title') ? 'error' : null}
        statusMessage={validationErrors.includes('title') ? 'Title must be unique' : null}
      />
      <TFRadioInput
        options={[
          { title: 'Yes', value: true },
          { title: 'No', value: false },
        ]}
        onChange={(value): void => {
          updateCustomFieldsData([{ key: 'required', value }]);
        }}
        label="Required"
        id="MainDetails-Required"
        value={customFieldsData?.required || false}
      />
      <TFDropdown
        options={CUSTOM_FIELD_TYPES}
        onSelect={(value): void => updateCustomFieldsData([{ key: 'type', value: value.value }])}
        label="Type"
        tooltip={formatMessage({ id: 'text.dataTypeEnteredIntoField' })}
        id="MainDetails-Type"
        initialValue={
          customFieldsData?.type
            ? { title: capitaliseString(customFieldsData?.type), value: customFieldsData?.type }
            : { title: 'None', value: null }
        }
        status={validationErrors.includes('type') ? 'error' : null}
        statusMessage={validationErrors.includes('type') ? 'Type must be selected' : null}
      />
      <TFDropdown
        options={locationOptions}
        onSelect={(value): void => updateCustomFieldsData([{ key: 'view', value: value.value }])}
        label="Location"
        tooltip={formatMessage({ id: 'text.fieldDataDisplayedOn' })}
        id="MainDetails-Location"
        initialValue={
          customFieldsData?.view
            ? { title: capitaliseString(customFieldsData?.view), value: customFieldsData?.view }
            : { title: 'None', value: null }
        }
        status={validationErrors.includes('view') ? 'error' : null}
        statusMessage={validationErrors.includes('view') ? 'Location must be selected' : null}
      />
      <TFInput
        id="MainDetails-Heading"
        value={customFieldsData?.heading}
        onChange={(e): void => updateCustomFieldsData([{ key: 'heading', value: e.target.value }])}
        label="Heading"
        tooltip={formatMessage({ id: 'text.displayedAboveFieldOnIpad' })}
        optional
      />
      <TFInput
        id="MainDetails-Key"
        value={customFieldsData?.key}
        onChange={(e): void => updateCustomFieldsData([{ key: 'key', value: e.target.value }])}
        label="Key"
        tooltip={formatMessage({ id: 'text.uniqueIdentifierForTheField' })}
        status={validationErrors.includes('key') ? 'error' : null}
        statusMessage={validationErrors.includes('key') ? 'Key must be present and unique' : null}
      />
    </Wrapper>
  );
};

export default CustomFieldsEditMainDetails;
