import React from 'react';
import styled from 'styled-components';

const defaultProps: CardProps = {
  marginRight: 0,
  marginLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  maxHeight: 'none',
  identifier: 'styledCard',
  padding: 20, // Adjust to your preference
  display: 'block',
  id: 'id',
  width: 'auto',
};

interface CardProps {
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  maxHeight?: string;
  identifier?: string;
  padding?: number;
  display?: string;
  id?: string;
  width?: string;
}

const StyledCard = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: #fcfcfc;
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  padding: ${({ padding }): string => (padding ? `${padding}px` : '20px')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}px` : '0')};
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}px` : '0')};
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}px` : '0')};
  max-height: ${({ maxHeight }): string => (maxHeight ? `${maxHeight}` : 'unset')};
  overflow: ${({ maxHeight }): string => (maxHeight ? 'scroll' : 'unset')};
  display: ${({ display }): string => (display ? 'block' : 'none')};
`;

const Card: React.FC<CardProps> = ({
  children,
  marginRight = 0,
  marginLeft = 0,
  marginTop = 0,
  marginBottom = 0,
  maxHeight,
  identifier = 'styledCard',
  padding = 20,
  display = 'block',
  id = 'id',
  width = 'auto',
}) => {
  return (
    <StyledCard
      marginRight={marginRight}
      marginLeft={marginLeft}
      marginTop={marginTop}
      marginBottom={marginBottom}
      maxHeight={maxHeight}
      padding={padding}
      display={display}
      id={id}
      width={width}
      data-testid={`StyledCard--${identifier}`}
    >
      {children}
    </StyledCard>
  );
};

Card.defaultProps = defaultProps;

export default Card;
