import {
  createCabinIssue,
  deleteCabinIssue,
  getCabinIssueSVG,
  queryCabinIssues,
  querySingleCabinIssue,
  updateCabinIssue,
} from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import {
  getAircraftSublocationGroupList,
  getAircraftSublocationGroupLists,
  updateAircraftSublocationGroupList,
} from '../../services/apiNew';
import { CabinIssuesActionTypes } from '.';

export const saveAllCabinIssues =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryCabinIssues(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.SAVE_ALL,
        payload: response,
      });
    }
    dispatch({
      type: CabinIssuesActionTypes.UPDATE_PAYLOAD,
      payload,
    });
  };

export const saveCabinIssue =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await querySingleCabinIssue(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.SAVE,
        payload: response,
      });
    }
  };

export const addCabinIssue =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await createCabinIssue(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.ADD,
        payload: response,
      });
      return response;
    }
    return null;
  };

export const updateCabinIssues =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updateCabinIssue(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.UPDATE,
        payload: response,
      });
    }
  };

export const removeCabinIssue =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = (await deleteCabinIssue(payload)) as any;
    if (response.ok) {
      dispatch({
        type: CabinIssuesActionTypes.REMOVE,
        payload,
      });
    } else {
      dispatch(
        addToast({
          payload: {
            title: 'Error',
            message: 'Error deleting cabin issue',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    }
  };

export const getCabinSVG =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getCabinIssueSVG(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.GET_CABIN_ISSUE_SVG,
        payload: response,
      });
    }
  };
export const fetchAircraftSublocationGroupLists =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getAircraftSublocationGroupLists(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.GETSUBLOCATIONS,
        payload: response?.data,
      });
    }
  };

export const setSelectedCabinIssueLocation =
  ({ payload }) =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: CabinIssuesActionTypes.SELECTED_ISSUE_LOCATION,
      payload,
    });
  };

export const fetchAircraftSublocation =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getAircraftSublocationGroupList(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.GETINDIVIDUALSUBLOCATION,
        payload: response?.data,
      });
    }
  };

export const updateAircraftSubLocation =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updateAircraftSublocationGroupList(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.UPDATEINDIVIDUALSUBLOCATION,
        payload: response?.data,
      });
      return response;
    }
    return null;
  };

export const updateCabinIssuePayload =
  ({ payload }) =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: CabinIssuesActionTypes.UPDATE_PAYLOAD,
      payload,
    });
  };
