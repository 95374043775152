import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteCabinIssue } from '../../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../../models/toasts';

const useMutationDeleteCabinIssue = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const deleteIssue = useMutation({
    mutationFn: (payload: { id: string; aircraft_id: string }) => {
      return deleteCabinIssue(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cabinIssues'] });
      queryClient.invalidateQueries({ queryKey: ['cabinIssueTotals'] });
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.cabinIssueDeleted' }),
            message: '',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: () => {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.errorDeletingCabinIssue' }),
            message: '',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return deleteIssue;
};

export default useMutationDeleteCabinIssue;
