/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Defect } from '../../../models/defects';
import { DashboardState } from '../../../models';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface DefectNonMelItemsProps {
  defect: Defect | null;
  originalDefectData: Defect | null;
  deferred: boolean;
  setDateDue: (input) => void;
  apuInstalled: boolean;
  updateDefectData: (changes: any[]) => void;
  aircraftId?: string;
  poIntl: {
    po_short_name: string;
    po_long_name: string;
    po_suffix: string;
  };
  formChanged: boolean;
}

const DefectNonMelItems: React.FC<DefectNonMelItemsProps> = ({
  defect,
  deferred,
  setDateDue,
  apuInstalled,
  updateDefectData,
  aircraftId,
  poIntl,
  formChanged,
}) => {
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const cdlNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.name_override;
  const cdlLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.long_name_override;
  const nefNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.name_override;
  const nefLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.long_name_override;
  const casNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.name_override;
  const casLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.long_name_override;
  const otherNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other?.name_override;
  const otherLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other
    ?.long_name_override;

  const handleReferenceChange = (e): void => {
    const value = e?.target?.value;
    updateDefectData([{ value, key: 'reference' }]);
  };

  useEffect(() => {
    if (defect?.display_data?.category) {
      let recCat = defect?.display_data?.category;
      if (recCat === 'ADVISORYONLY') recCat = 'Advisory only';
      if (recCat === 'NONDEFERRED') recCat = 'A';
      if (recCat !== defect?.display_data?.category) {
        updateDefectData([
          { value: recCat, key: 'rectification_category' },
          { value: recCat, key: 'display_data.category' },
        ]);
      }
    }
    if (!deferred) {
      updateDefectData([
        { value: 'Non Deferred', key: 'rectification_category' },
        { value: 'Non Deferred', key: 'display_data.category' },
      ]);
    }
  }, [defect?.defect_type]);

  let displayOption;
  let displayDefectType = cdlNameOverride
    ? `${cdlNameOverride}${cdlLongNameOverride ? ` - ${cdlLongNameOverride}` : ''}`
    : 'CDL - Configuration Deviation List';
  if (defect?.defect_type === casNameOverride || defect?.defect_type === 'CAS')
    displayDefectType = casNameOverride
      ? `${casNameOverride}${casLongNameOverride ? ` - ${casLongNameOverride}` : ''}`
      : 'CAS - Crew Alerting System';
  if (defect?.defect_type === otherNameOverride || defect?.defect_type === 'Other')
    displayDefectType = otherNameOverride
      ? `${otherNameOverride}${otherLongNameOverride ? ` - ${otherLongNameOverride}` : ''}`
      : 'Other';
  if (
    defect?.defect_type === cdlNameOverride ||
    defect?.defect_type === 'CDL' ||
    defect?.defect_type === casNameOverride ||
    defect?.defect_type === 'CAS' ||
    defect?.defect_type === otherNameOverride ||
    defect?.defect_type === 'Other'
  )
    displayOption = (
      <>
        <FlexWrapper column>
          <Label marginBottom={15} fontWeight={500}>
            {!deferred ? 'Do Not Defer' : displayDefectType}
          </Label>
          <ManuallyEnterMELDetails
            defect={defect}
            setDateDue={setDateDue}
            apuInstalled={apuInstalled}
            updateDefectData={updateDefectData}
            poIntl={poIntl}
            formChanged={formChanged}
          />
        </FlexWrapper>
      </>
    );
  if (defect?.defect_type === nefNameOverride || defect?.defect_type === 'NEF')
    displayOption = (
      <FlexWrapper column>
        <Label marginBottom={15} fontWeight={500}>
          {nefNameOverride
            ? `${nefNameOverride}${nefLongNameOverride ? ` - ${nefLongNameOverride}` : ''}`
            : formatMessage({ id: 'text.nefExpanded' })}
        </Label>
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage(
            { id: 'text.pleaseEnterReferenceNumber' },
            { po: poIntl.po_short_name, suffix: poIntl.po_suffix },
          )}
        </Label>
        <Label marginBottom={5} fontWeight={500}>
          {formatMessage({ id: 'title.reference' })}
        </Label>
        <StyledInput
          id="reference"
          defaultValue={defect?.reference}
          type="text"
          width={280}
          onChange={handleReferenceChange}
        />
      </FlexWrapper>
    );
  return displayOption;
};

export default DefectNonMelItems;
