import moment from 'moment';
import { Defect } from '../../../models/defects';

const INTERVAL_MAP = { B: 3, C: 10, D: 120 };

export const getNewDateDueBasedOnInterval = (interval: string, passedDate: string): string => {
  const date = passedDate ? moment(passedDate) : moment();

  const daysToAdd = INTERVAL_MAP[interval] || 0;

  return date.add(daysToAdd, 'days').format('YYYY-MM-DD');
};

export const handleDeferralTimeChange = (
  dateTime: string,
  defect: Defect,
  updateFunction: (changes: { value: string; key: string }[]) => void,
): void => {
  let dateDue = '';
  if (defect?.date_due) dateDue = defect?.date_due;

  const daysVariance = dateDue
    ? moment.duration(moment.utc(dateTime).diff(moment.utc(dateDue)))
    : moment.duration(moment.utc(dateTime).diff(moment.utc()));

  let newDateVariance = moment.utc().add(daysVariance, 'days').format('YYYY-MM-DD');

  if (dateDue) newDateVariance = moment.utc(dateDue).add(daysVariance, 'days').format('YYYY-MM-DD');

  const newDate = getNewDateDueBasedOnInterval(defect?.display_data?.category, newDateVariance);

  updateFunction([
    { value: moment.utc(dateTime).toISOString(), key: 'deferred_at' },
    { value: newDate, key: 'date_due' },
  ]);
};

export const createMELChapters = (melChapters) => {
  const chaptersArray = [];
  Object.keys(melChapters).forEach((chapter) => {
    const childrenArray = [];
    if (melChapters[chapter]?.children && Object.keys(melChapters[chapter]?.children)?.length) {
      Object.keys(melChapters[chapter].children).forEach((section) => {
        childrenArray.push({
          value: section,
          title: `${section} - ${melChapters[chapter]?.children[section]}`,
        });
      });
    }
    chaptersArray.push({
      value: `${chapter} - ${melChapters[chapter]?.title}`,
      title: `${chapter} - ${melChapters[chapter]?.title}`,
      sections: childrenArray,
    });
  });
  return chaptersArray;
};
