import React, { ReactElement, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Badge, Button, Modal, Table } from '@arcflight/tf-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import CabinIssueDrawer from './CabinIssueDrawer';
import useMutationDeleteCabinIssue from './QueryCalls/useMutationDeleteCabinIssue';

interface CabinLogTableProps {
  data: any[];
  onPaginationChange: (currentPage, numberOfItems) => void;
  onSortChange: (input) => void;
  pageIndex: number;
  pageSize: number;
  total: number;
  resetToOne: boolean;
}

const TableContainer = styled.div`
  width: 100%;
  div,
  img,
  svg,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const ModalWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: ${({ theme }): string => theme.colors.black70Alpha};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubmitButton = styled.div`
  margin-right: 12px;
`;

const CabinLogTable: React.FC<CabinLogTableProps> = ({
  data,
  onPaginationChange,
  onSortChange,
  pageIndex,
  pageSize,
  total,
  resetToOne,
}) => {
  const {
    userSettings: { dateFormat },
    cabinIssues: { cabinIssueSVG },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalIssueId, setModalIssueId] = useState(null);

  const deleteCabinIssue = useMutationDeleteCabinIssue();

  const handleDeleteCabinIssue = (issueId: string): void => {
    setModalVisible(true);
    setModalIssueId(issueId);
  };

  const confirmCabinIssueDelete = (): void => {
    deleteCabinIssue.mutate({ id: modalIssueId, aircraft_id: id });
    setModalIssueId(null);
    setModalVisible(false);
  };

  const handleEditDrawer = (issue): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(setDrawerId({ payload: issue.id }));
    dispatch(changeDrawerMode({ payload: 'edit' }));
    dispatch(changeDrawerContent({ payload: { content: <CabinIssueDrawer issue={issue} /> } }));
  };

  const handleViewDrawer = (issue): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(setDrawerId({ payload: issue.original.id }));
    dispatch(changeDrawerContent({ payload: { content: <CabinIssueDrawer issue={issue.original} /> } }));
  };

  const onRowClick = (row): void => {
    handleViewDrawer(row);
  };

  const status = {
    open: {
      color: 'amber',
    },
    resolved: {
      color: 'green',
    },
  };

  const aircraftIssueTypes = aircraftMap.get(id)?.issue_types || [];
  const locationOptions = cabinIssueSVG?.aircraft_location_groups?.flatMap((outer) => outer?.aircraft_locations) || [];

  const columns = useMemo(
    () => [
      {
        accessor: 'status',
        Header: formatMessage({ id: 'title.status' }),
        width: 70,
        Cell: ({ value }): ReactElement => {
          if (value)
            return (
              <div>
                <Badge color={status[value].color} />
              </div>
            );
          return null;
        },
      },
      {
        Header: formatMessage({ id: 'title.numberShort' }),
        accessor: 'number',
        width: 70,
      },
      {
        Cell: ({ value }): string => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortable: true,
        Header: 'Details',
        accessor: 'details',
      },
      {
        Cell: ({ value }): string => {
          const locationName = locationOptions?.find(
            (location) => location?.id === value[0]?.aircraft_location_id,
          )?.element_identifier;
          let newName = '';
          if (locationName) {
            const match = locationName.match(/^([A-Za-z]+)(.*)/);
            const firstPart = match[1].charAt(0).toUpperCase() + match[1].slice(1);
            const secondPart = match[2];
            newName = `${firstPart} ${secondPart}`;
          } else {
            newName = 'Custom Location';
          }
          const severity = aircraftIssueTypes?.find((iss) => iss?.id === value[0]?.issue_type_id)?.name;
          const newValue = `${newName} (${severity})`;
          return newValue;
        },
        sortable: true,
        Header: 'Location (severity)',
        accessor: 'aircraft_location_issues',
      },
      {
        Cell: ({ value }): string => {
          if (value) {
            return moment(value).format(dateFormat);
          }
          return '-';
        },
        sortable: true,
        Header: 'Raised at',
        accessor: 'raised_at',
      },
      {
        Header: '',
        id: '3dot',
        width: 20,
        Cell: ({ row }): ReactElement => {
          return (
            <AuthDropdownMenu
              handleDelete={(): void => handleDeleteCabinIssue(row.original.id)}
              resource={AircraftResource.DEFECT}
              editCallback={(): void => handleEditDrawer(row.original)}
              aircraftId={id}
              options={{ read: false, update: true, delete: true }}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );

  return (
    <TableContainer>
      <Table
        tableInstance={tableInstance}
        total={total}
        handlePaginationChange={(currentPage, numberOfItems): void => onPaginationChange(currentPage, numberOfItems)}
        passedPageSize={pageSize}
        handleSortChange={(sort): void => onSortChange(sort)}
        handleRowClick={(row): void => onRowClick(row)}
        resetToOne={resetToOne}
      />
      <Modal isOpen={modalVisible} width={420} handleClose={(): void => setModalVisible(false)}>
        <ModalWrapper>
          <ModalTitle>{formatMessage({ id: 'text.deleteCabinIssue' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureDeleteCabinIssue',
            })} ${formatMessage({
              id: 'form.labels.cannotBeUndone',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubmitButton>
              <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={confirmCabinIssueDelete}>
                {formatMessage({ id: 'form.button.delete' })}
              </Button>
            </SubmitButton>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setModalVisible(false)}
            >
              {formatMessage({ id: 'form.button.cancel' })}
            </Button>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>
    </TableContainer>
  );
};

export default CabinLogTable;
