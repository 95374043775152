/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Trip } from '../../models/trips';
import { DisplayText, Header, StyledInput } from '../FlightDrawer/FlightDrawer';
import { DashboardState } from '../../models';
import DocumentLink from '../DocumentLink/DocumentLink';

interface SRPSectionProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any) => void;
}

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const DetailsWrapper = styled.div`
  margin-right: 40px;
  width: 100px;
  @media (max-width: 450px) {
    margin-right: 10px;
  }
`;

const SRPSection: React.FC<SRPSectionProps> = ({ trip, editable, updateTripData }) => {
  const [tripNo, setTripNo] = useState(trip?.number);
  const [SRPNumber, setSRPNumber] = useState(trip?.srp_number);

  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const operatorSettings = operators?.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const handleTripNumberUpdate = (value: string): void => {
    updateTripData('number', value);
  };

  const handleSRPUpdate = (value: string): void => {
    updateTripData('srp_number', value);
  };

  useEffect(() => {
    if (trip) {
      setTripNo(trip?.number);
      setSRPNumber(trip?.srp_number);
    }
  }, [trip]);

  return (
    <RowWrapper>
      <DetailsWrapper data-testid="SRPSection-Header">
        <Header editable={editable}>Trip No.</Header>
        {editable ? (
          <StyledInput width={100} value={tripNo} onChange={(e): void => handleTripNumberUpdate(e.target.value)} />
        ) : (
          <DisplayText>{trip?.number || '-'}</DisplayText>
        )}
      </DetailsWrapper>
      {trip?.status !== 'planned' && !operatorSettings?.hide_srp_links ? (
        <DetailsWrapper data-testid="SRPSection-SRP">
          <Header editable={editable}>{`Trip ${operatorSettings?.srp}`}</Header>
          {editable ? (
            <StyledInput width={100} value={SRPNumber} onChange={(e): void => handleSRPUpdate(e.target.value)} />
          ) : (
            <DocumentLink id={trip?.id} aircraftId={id} docType="srp" />
          )}
        </DetailsWrapper>
      ) : null}
      {trip?.status !== 'planned' ? (
        <DetailsWrapper data-testid="SRPSection-JourneyLog">
          <Header>{`${operatorSettings?.journey_log}`}</Header>
          <DocumentLink id={trip?.id} aircraftId={id} docType="jl" />
        </DetailsWrapper>
      ) : null}
    </RowWrapper>
  );
};

export default SRPSection;
