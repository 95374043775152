/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';

interface ViewMelDetailsProps {
  defect: Defect | null;
  isMelTableVisible?: boolean;
  edit?: boolean;
}

const Section = styled.div`
  width: ${({ width }): string => `${width}px`};
  height: ${({ height }): string => `${height}px`};
  border-left: ${({ borderLeft }): string => (borderLeft ? 'solid 2px rgba(36, 45, 65, 0.02)' : 'none')};
  border-top: ${({ borderTop }): string => (borderTop ? 'solid 2px rgba(36, 45, 65, 0.02)' : 'none')};
  display: ${({ isMelTableVisible }): string => (isMelTableVisible ? 'flex' : 'none')};
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 13px 0 20px 18px;
  flex-direction: column;
`;

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: max-content;
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    height: max-content;
    opacity: 0;
  }
  to {
    height: 0;
    opacity: 1;
  }
`;

const slideTableDown = css`
  animation: ${slideDown} 0.3s;
`;

const slideTableUp = css`
  animation: ${slideUp} 0.3s;
`;

const AnimationDiv = styled.div`
  ${({ isMelTableVisible }): string =>
    isMelTableVisible &&
    css`
      ${slideTableDown}
    `};
  ${({ isMelTableVisible }): string =>
    !isMelTableVisible &&
    css`
      ${slideTableUp}
    `};
  height: ${({ isMelTableVisible }): string => (isMelTableVisible ? 'auto' : '0')};
  overflow: scroll;
`;

const ViewMelDetails: React.FC<ViewMelDetailsProps> = ({ defect, isMelTableVisible, edit }) => {
  const section5Ref = useRef(null);

  const [extraHeight, setExtraHeight] = useState(0);
  const [chapterNumber, setChapterNumber] = useState(null);
  const [sectionNumber, setSectionNumber] = useState(null);
  const [SubSectionNumber, setSubSectionNumber] = useState(null);
  const [subSubSectionNumber, setSubSubSectionNumber] = useState(null);
  const [localTitle, setTitle] = useState(null);
  const [localInterval, setInterval] = useState(null);
  const [numberInstalled, setNumberInstalled] = useState(null);
  const [numberRequired, setNumberRequired] = useState(null);
  const [localRemarks, setRemarks] = useState(null);

  const { formatMessage } = useIntl();

  let chapterNumberTitle = ``;
  if (chapterNumber) chapterNumberTitle += chapterNumber;
  if (sectionNumber) chapterNumberTitle += `-${sectionNumber}`;
  if (SubSectionNumber) chapterNumberTitle += `-${SubSectionNumber}`;
  if (subSubSectionNumber) chapterNumberTitle += `-${subSubSectionNumber}`;

  const sectionTitle = `${chapterNumberTitle} ${localTitle}`;

  useEffect(() => {
    if (defect && defect?.mel_item) {
      const {
        mel_item: { chapter_number, section_number, subsection_number, subsubsection, mel_rectifications, title },
      } = defect;
      setChapterNumber(chapter_number);
      if (chapter_number?.toString() && chapter_number?.toString()?.length === 1) {
        setChapterNumber(`0${chapter_number}`);
      }
      setSectionNumber(section_number);
      if (section_number?.toString() && section_number?.toString()?.length === 1) {
        setSectionNumber(`0${section_number}`);
      }
      setSubSectionNumber(subsection_number);
      if (subsection_number?.toString() && subsection_number?.toString()?.length === 1) {
        setSubSectionNumber(`0${subsection_number}`);
      }
      if (subsubsection) setSubSubSectionNumber(subsubsection);
      setTitle(title);
      if (mel_rectifications && mel_rectifications.length > 0) {
        let melRects;
        if (defect?.mel_rectification_id) {
          const rect = mel_rectifications.find((item) => item.id === defect?.mel_rectification_id);
          melRects = rect;
        } else {
          melRects = mel_rectifications[0];
        }
        if (melRects) {
          const { interval, number_installed, number_required, remarks } = melRects;
          // eslint-disable-next-line @typescript-eslint/no-implied-eval
          setInterval(interval);
          setNumberInstalled(number_installed);
          setNumberRequired(number_required);
          setRemarks(remarks);
        }
      }
    }
  }, [defect]);

  useEffect(() => {
    const section5 = document.getElementById('section5');
    if (edit && section5) {
      setExtraHeight(0);
      const rect = section5.getBoundingClientRect();
      const { height } = rect;
      setExtraHeight(height - 35);
    } else if (section5) {
      setTimeout(() => {
        setExtraHeight(0);
        const rect = section5.getBoundingClientRect();
        const { height } = rect;
        setExtraHeight(height - 35);
      }, 350);
    }
  }, [section5Ref, defect, edit]);

  const isIpad = window.innerWidth < 7700;
  let wrapperHeight = 265;
  if (isIpad) wrapperHeight = 297;

  return (
    <AnimationDiv isMelTableVisible={isMelTableVisible}>
      <Card padding={0} marginTop={20} display={isMelTableVisible ? '' : 'display'} width="636px">
        <GridWrapper columns={isIpad ? '200px 1fr' : '1fr 1fr'}>
          <FlexWrapper height={isMelTableVisible ? wrapperHeight + extraHeight : 0} column>
            <Label marginTop={15} marginLeft={20} marginBottom={165}>
              {`1. ${formatMessage({ id: 'title.systemSequence' })}`}
            </Label>
            <Label marginLeft={20} marginBottom={40} color="rgba(36, 45, 65, 0.7)" fontWeight={300} width="190px">
              {sectionTitle}
            </Label>
          </FlexWrapper>
          <FlexWrapper position="relative">
            <Section
              isMelTableVisible={isMelTableVisible}
              height={265 + extraHeight}
              width={isIpad ? 400 : 470}
              borderLeft
            >
              <Label marginBottom={165}>{`2. ${formatMessage({ id: 'title.rectificationInterval' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                {localInterval}
              </Label>
            </Section>
            <Section
              isMelTableVisible={isMelTableVisible}
              height={218 + extraHeight}
              width={isIpad ? 360 : 420}
              borderLeft
              borderTop
            >
              <Label marginBottom={116}>{`3. ${formatMessage({ id: 'title.numberInstalled' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                {numberInstalled}
              </Label>
            </Section>
            <Section
              isMelTableVisible={isMelTableVisible}
              height={172 + extraHeight}
              width={isIpad ? 310 : 370}
              borderLeft
              borderTop
            >
              <Label marginBottom={70}>{`4. ${formatMessage({ id: 'title.numberRequiredDispatch' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                {numberRequired}
              </Label>
            </Section>
            <Section
              isMelTableVisible={isMelTableVisible}
              height={125 + extraHeight}
              width={isIpad ? 263 : 318}
              borderLeft
              borderTop
            >
              <Label marginBottom={20}>{`5. ${formatMessage({ id: 'title.remarksOrException' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                <div id="section5" ref={section5Ref}>
                  {localRemarks}
                </div>
              </Label>
            </Section>
          </FlexWrapper>
        </GridWrapper>
      </Card>
    </AnimationDiv>
  );
};

export default ViewMelDetails;
