import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { createCabinIssue } from '../../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../../models/toasts';

const useMutationAddCabinIssue = ({
  handleAddCabinIssueOnSuccess,
}: {
  handleAddCabinIssueOnSuccess: (data: any) => void;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const addCabinIssue = useMutation({
    mutationFn: (payload: any) => {
      return createCabinIssue(payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['cabinIssues'] });
      queryClient.invalidateQueries({ queryKey: ['cabinIssueTotals'] });
      handleAddCabinIssueOnSuccess(data);
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.cabinIssueAdded' }),
            message: '',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: () => {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.errorAddingCabinIssue' }),
            message: '',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return addCabinIssue;
};

export default useMutationAddCabinIssue;
