import React from 'react';
import styled, { keyframes } from 'styled-components';

interface TFProgressBarProps {
  colour: string;
  percentage: number;
}

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 5px;
  max-width: 100px;
  background-color: rgba(36, 45, 65, 0.05);
  border-radius: 100px;
  margin-top: 10px;
`;

const processbarFill = keyframes`
    from {
        width: 0;
    }

    to {
        width: width;
    }
`;

const ProgressBar = styled.div`
  width: ${({ percentage }): string => `${percentage}%`};
  height: 5px;
  border-radius: 100px;
  animation: ${processbarFill} 1s linear;
  background-image: ${({ colour }): string => {
    if (colour === 'green') {
      return 'linear-gradient(to right, rgb(79, 177, 132) 0%, rgb(53, 185, 109) 100%)';
    }
    if (colour === 'amber') {
      return 'linear-gradient(to right, rgb(242, 166, 80) 0%, rgb(250, 210, 136) 100%)';
    }
    if (colour === 'red') {
      return 'linear-gradient(to right, rgb(208, 30, 39) 0%, rgb(245, 34, 45) 100%)';
    }
    return 'none';
  }};
`;

const TFProgressBar: React.FC<TFProgressBarProps> = ({ colour, percentage }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBar colour={colour} percentage={percentage} />
    </ProgressBarWrapper>
  );
};

export default TFProgressBar;
