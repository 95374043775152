import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import calendarIcon from '../../assets/icon-small-calendar.svg';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import TFTimePicker from '../TFTimePicker/TFTimePicker';

const UnitWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Unit = styled.span`
  margin-left: 4px;
`;

const CustomFieldEditDatePicker = ({ value, handleDateChange, data }) => {
  const { userSettings } = useSelector((state: DashboardState) => state);

  const { dateFormat } = userSettings;

  const [localValue, setLocalValue] = useState(null);

  useEffect(() => {
    if (value) {
      if (data?.mode === 'time' && typeof value === 'number') {
        setLocalValue(value);
      } else if (data?.mode === 'date_time') {
        const newValue = moment(value);
        setLocalValue(newValue);
      } else {
        const newValue = moment(value);
        setLocalValue(newValue);
      }
    } else {
      setLocalValue(null);
    }
  }, [data?.mode, dateFormat, value]);

  return (
    <div data-testid="CustomFieldEditDatePicker--Wrapper">
      {data?.mode !== 'date_time' && data?.mode !== 'time' ? (
        <UnitWrapper>
          <DatePicker
            format={dateFormat}
            value={localValue}
            onChange={(dateMoment, dateString): void => handleDateChange(dateString)}
            suffixIcon={<img src={calendarIcon} alt="calendarIcon" />}
            placeholder="-"
          />
          {data?.unit ? <Unit>{data?.unit}</Unit> : null}
        </UnitWrapper>
      ) : null}
      {data?.mode === 'date_time' ? (
        <UnitWrapper>
          <DateTimePicker
            dateTime={localValue}
            handleDateTimeChange={(dateString): void => handleDateChange(dateString)}
            headerDate="Date"
            headerTime="Time"
          />
          {data?.unit ? <Unit>{data?.unit}</Unit> : null}
        </UnitWrapper>
      ) : null}
      {data?.mode === 'time' ? (
        <TFTimePicker
          initial={localValue}
          setValue={(returnedValue): void => handleDateChange(returnedValue)}
          id="CustomFieldTimePicker"
        />
      ) : null}
    </div>
  );
};

export default CustomFieldEditDatePicker;
