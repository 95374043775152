import React, { useEffect, useState } from 'react';
import { Button } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import exclamationMark from '../../assets/invalid-name.svg';
import arrow from '../../assets/icon-arrow.svg';
import { getWorkpackUrl } from '../../services/api';
import servers from '../../utils/servers';
import getOperatorSetting from '../../utils/getOperatorSetting';

interface StatusBannerProps {
  status: string;
  crs?: boolean;
  crsId?: string;
  aircraftId?: string;
}

const BannerPadding = styled.div`
  padding: ${({ scrolled }): string => (scrolled ? '20px 24px 20px' : '20px 24px 0')};
  background: #f6f8fb;
  margin-top: -6px;
`;

const BannerWrapper = styled.div`
  width: 100%;
  height: ${({ border }): string => (border ? 'auto' : '59px')};
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  border-radius: 4px;
  border: ${({ border }): string => (border ? '1px solid rgba(36, 45, 65, 0.1)' : 'none')};
  background-color: ${({ theme, status }): string => {
    if (status === 'Setup') {
      return theme.colours.sapphire;
    }
    if (status === 'Deleted') {
      return theme.colours.red;
    }
    if (status === 'Archived') {
      return theme.colors.black70Alpha;
    }
    if (status === 'Locked') {
      return theme.colours.bannerBlack;
    }
    if (status === 'Demo') {
      return theme.colours.scheduledBlue;
    }
    if (status === 'MEL Only') {
      return theme.colours.scheduledBlue;
    }
    return '';
  }};
  font-size: 16px;
`;

const Bold = styled.span`
  font-weight: bold;
  margin-left: 3px;
  text-transform: ${({ mel }): string => (mel ? 'none' : 'lowercase')};
`;

const RedSection = styled.div`
  height: 46px;
  width: 47px;
  background-image: linear-gradient(to top, #ac1717, #ff4040);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const TextSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: rgba(36, 45, 65, 0.9);
  width: 100%;
  height: 46px;
  background-color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const StatusBanner: React.FC<StatusBannerProps> = ({ status, crs, crsId, aircraftId }) => {
  const [scrolled, setScrolled] = useState(false);
  const [localDefectLink, setLocalDefectLink] = useState('');
  const approvalReleaseMode = getOperatorSetting('approval_release_mode', aircraftId);
  const approvalReleaseName = getOperatorSetting('approval_release_name', aircraftId);
  const CRSShortName = getOperatorSetting('crs_short_name', aircraftId);

  let releaseText = CRSShortName;
  if (['after_approval_release_required', 'after_each_approval_release_required'].includes(approvalReleaseMode)) {
    releaseText = approvalReleaseName;
  }

  const getPublicUrlLink = async (): Promise<void> => {
    getWorkpackUrl(crsId).then((response: any) => {
      if (response?.url) {
        const link = `${servers.pub === 'localhost:8000' ? 'http://' : 'https://'}${servers.pub + response.url}`;
        setLocalDefectLink(link);
        window.open(link, '_newtab');
      }
    });
  };

  const handlePublicLinkClick = (): void => {
    if (!localDefectLink) {
      getPublicUrlLink();
    } else {
      window.open(localDefectLink, '_newtab');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const div = document.getElementById('InnerContent');
      const rect = div?.getBoundingClientRect();
      if (rect?.top < 148) setScrolled(true);
      else setScrolled(false);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if ((!status || status === 'Active') && !crs) return null;

  return (
    <BannerPadding scrolled={scrolled}>
      {status !== 'Active' || !status || status === null ? (
        <BannerWrapper status={status} data-testId="StatusBanner--Wrapper">
          <span>{`This aircraft is ${status === 'Setup' ? 'being' : ' '}`} </span>
          <Bold mel={status === 'MEL Only'}>{` ${status}`}</Bold>
        </BannerWrapper>
      ) : null}
      {crs ? (
        <BannerWrapper data-testid="StatusBanner--Wrapper" border>
          <RedSection>
            <img src={exclamationMark} alt="exclamation" />
          </RedSection>
          <TextSection>
            <div data-testid="StatusBanner--Text">{`${releaseText} Required`}</div>
            {crsId ? (
              <Button height="32px" onClick={handlePublicLinkClick} primary={false}>
                {`${releaseText} Required`} <img src={arrow} alt="arrow" />
              </Button>
            ) : null}
          </TextSection>
        </BannerWrapper>
      ) : null}
    </BannerPadding>
  );
};

export default StatusBanner;
