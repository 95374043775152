import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

export const colours = {
  imageUpload: '#d9d9d9',
  switchChecked: '#77bbfa',

  green: '#12845b',
  greenSubtle: '#35b96d',
  greenThin: '#34ba6d',
  greenOpaque: '#e5ffef',
  greenMedium: 'rgba(74, 179, 22, 0.6)',
  greenExtraLight: 'rgba(74, 179, 22, 0.1)',
  greenTag: '#52c41a',
  greenTagBackground: '#f6ffed',
  greenTagBorder: '#b7eb8f',
  greenLinkBorder: '#75bd9a',
  greenLinearGradientStart: '#4fb184',
  greenLinearGradientEnd: '#35b96d',

  amber: '#f2a650',
  amberBright: '#ffbb18',
  amberMedium: 'rgba(250, 140, 22, 0.6)',
  amberLight: '#f9efd6',
  amberExtraLight: 'rgba(250, 140, 22, 0.1)',
  amberExtraLightTransparent: 'rgba(250, 140, 22, 0.05)',
  amberTag: '#fa8c16',
  amberTagBackground: '#fff7e6',
  amberTagBorder: '#ffd591',
  amberToast: '#f79833',

  red: '#ac1717',
  redStrong: '#bc0000',
  redMessage: '#ca2322',
  redSubtle: '#ff4347',
  redOpaque: '#d4696f',
  redMedium: 'rgba(245, 34, 45, 0.6)',
  veryLightRed: 'rgba(193, 66, 66, 0.3)',
  greyRedish: 'rgba(193, 66, 66, 0.1)',
  redLight: '#f5d3d3',
  redThin: '#ffeced',
  redExtraLight: '#f7f7f7',
  redExtraLightTransparent: 'rgba(253, 247, 247, 0.5)',
  redTag: '#f5222d',
  redBackgroundTag: '#fff1f0',
  redBorderTag: '#ffa39e',
  red02Alpha: 'rgba(172, 23, 23, 0.02)',
  red07Alpha: 'rgba(172, 23, 23, 0.07)',

  blue: '#126fd6',
  blueMedium: 'rgba(18, 111, 214, 0.6)',
  mediumLightBlue: 'rgba(18, 111, 214, 0.3)',
  blueExtraLight: 'rgba(18, 111, 214, 0.1)',
  radioButtonBlue: 'rgba(18, 111, 214, 0.1)',
  borderBlue: 'rgba(18, 111, 214, 0.3)',
  slate: '#314659',
  blueTagBackground: '#e6f777',
  blueTagBorder: '#91d5ff',
  brandBlue02Alpha: 'rgba(18, 111, 214, 0.02)',
  brandBlue03Alpha: 'rgba(18, 111, 214, 0.03)',
  brandBlue05Alpha: 'rgba(18, 111, 214, 0.05)',
  brandBlue10Alpha: 'rgba(18, 111, 214, 0.1)',
  brandBlue15Alpha: 'rgba(18, 111, 214, 0.15)',
  brandBlue20Alpha: 'rgba(18, 111, 214, 0.2)',
  brandBlue30Alpha: 'rgba(18, 111, 214, 0.3)',
  brandBlue40Alpha: 'rgba(18, 111, 214, 0.4)',
  brandBlue50Alpha: 'rgba(18, 111, 214, 0.5)',
  brandBlue60Alpha: 'rgba(18, 111, 214, 0.6)',
  brandBlue70Alpha: 'rgba(18, 111, 214, 0.7)',
  brandBlue80Alpha: 'rgba(18, 111, 214, 0.8)',
  brandBlue90Alpha: 'rgba(18, 111, 214, 0.9)',

  purple: '#9000ff',

  cyanTag: '#17C2C8',
  cyanTagBackground: '#e6fffb',
  cyanTagBoarder: '#87e8de',

  borderGray: '#d9d9d9',
  lightBlue: '#91d5ff',
  lightBlueLight: '#e6f7ff',

  thinGray: '#f7f7f7',
  lightGray: '#d3d3d3',
  lightGrayMedium: 'rgba(211, 211, 211, 0.6)',
  lightGrayLight: 'rgba(216, 216, 216, 0.2)',
  mediumLightGray: 'rgba(211, 211, 211, 0.3)',
  lightGrayExtraLight: 'rgba(211, 211, 211, 0.1)',
  semiTransparentGray: 'rgba(219, 219, 219, 0.5)',
  lightTransparentGray: 'rgba(230, 232, 237, 0.4)',
  fontGray: '#c0c0c0',

  gray: '#808080',
  grayMedium: 'rgba(128, 128, 128, 0.6)',
  newGray: 'rgba(250, 250, 250, 0.3)',
  grayExtraLight: 'rgba(128, 128, 128, 0.1)',
  lighterGray: '#9b9b9b',
  mediumGray: '#737373',
  darkGrey: '#666666',
  // borderGrey: '#e8e8e8',
  greyLine: '#dfe0eb',
  grayTagBackground: '#fafafa',
  grayPlusSign: 'rgba(202, 202, 202, 0.3)',
  grayBlue: '#8c96a9',
  softGray: '#f3f7fc',
  greyLinkText: '#9f9f9f',

  orange: '#c65001',
  orange02Alpha: 'rgba(216,87, 12, 0.02)',
  orange07Alpha: 'rgba(216, 87, 12, 0.07)',

  aqua: '#13c2c2',
  aquaMedium: 'rgba(19, 194, 194, 0.6)',
  aquaExtraLight: 'rgba(19, 194, 194, 0.1)',

  black: '#000000',
  titleBlack: '#313745',
  titleDarkBlack: '#181818',
  textBlack: '#373737',
  formEntries: '#181818',
  mxBlack: '#646464',
  blackMedium85: 'rgba(0, 0, 0, 0.85)',
  blackMedium: 'rgba(0, 0, 0, 0.65)',
  blackMedium60: 'rgba(0, 0, 0, 0.6)',
  blackLight: 'rgba(0, 0, 0, 0.1)',
  blackExtraLight: 'rgba(0, 0, 0, 0.05)',
  formBg: 'rgba(89, 89, 89, 0.05)',
  bannerBlack: '#343434',
  black02Alpha: 'rgba(36, 45, 65, 0.02)',
  black05Alpha: 'rgba(36, 45, 65, 0.05)',
  black10Alpha: 'rgba(36,45,65,0.1)',
  black20Alpha: 'rgba(36,45,65,0.2)',
  black30Alpha: 'rgba(36, 45, 65, 0.3)',
  black40Alpha: 'rgba(36, 45, 65, 0.4)',
  black50Alpha: 'rgba(36, 45, 65, 0.5)',
  black60Alpha: 'rgba(36, 45, 65, 0.6)',
  black70Alpha: 'rgba(36, 45, 65, 0.7)',
  black80Alpha: 'rgba(36, 45, 65, 0.8)',
  black90Alpha: 'rgba(36, 45, 65, 0.9)',

  graphite: '#242d41',

  white: '#ffffff',
  offWhite: '#fafafa',
  white80: 'rgba(255, 255, 255, 0.8)',
  innerMenuWhite: 'rgba(255, 255, 255, 0.65)',
  semiTransparentWhite: 'rgba(247, 248, 252, 0.5)',
  whiteShadow: '#dbe3ed',
  whiteLight: '#f4f4f5',
  whiteThin: '#fbfafe',
  whiteMedium55: 'rgba(232, 238, 244, 0.55)',
  whiteMedium50: 'rgba(251, 252, 253, 0.5)',
  whiteMedium40: '#fbfcfd',
  whiteMedium55Solid: '#f0f3f6',
  whiteBackground: '#f3f4f6',
  borderGrey: '#e6e8ed',
  snow: '#fafbfd',
  salt: '#fcfcfc',

  brown: '#7c7062',

  brandBoldBlue: '#0c1a2f',
  brandExtraDarkBlue: '#010306',
  brandExtraDarkBlueGradient: '#10294b',
  brandDarkBlue: '#001529',
  brandMediumBlue: '#083976',
  brandBlue: '#126FD6',
  contextMenuBlue: '#182644',
  brandAccentBlue: '#126fd6',
  brandAccentBlueDark: '#398fff',
  brandLightGray: '#e8e8e8',
  defectBlue: '#1e589f',
  scheduledBlue: 'rgb(71, 12, 254)',
  oopBlue: 'rgb(121, 78, 252)',
  llpBlue: 'rgb(186, 163, 255)',
  pkgBlue: '#0085ff',

  transparent: 'rgba(0, 0, 0, 0)',
  almostTransparent: 'rgba(0, 0, 0, 0.05)',

  tripHighlightRow: '#e6f7ff',
  tableAccent1: '#e2faed',
  tableAccent2: '#ffeac5',
  sapphire: '#1e5bb5',

  rowHighlighted: '#e6f2ff',

  // Overview Page colours

  overviewStatusTitle: '#4d4d4d',
  overviewSettingsButton: '#126FD6',
  oveviewShowTotals: '#126fd6',
  buttonBorderColour: '#e6e6e6',
  overviewBackgroundColour: 'rgba(255, 255, 255, 0)',
  overviewBoxShadow: 'rgba(219, 227, 237, 0.41)',
};

const colors = {
  brandBlue5Alpha: 'rgba(18, 111, 214, 0.05)',
  black: 'rgb(36, 45, 65)',
  black02Alpha: 'rgba(36, 45, 65, 0.02)',
  black05Alpha: 'rgba(36, 45, 65, 0.05)',
  black10Alpha: 'rgba(36, 45, 65, 0.1)',
  black20Alpha: 'rgba(36, 45, 65, 0.2)',
  black30Alpha: 'rgba(36, 45, 65, 0.3)',
  black40Alpha: 'rgba(36, 45, 65, 0.4)',
  black50Alpha: 'rgba(36, 45, 65, 0.5)',
  black60Alpha: 'rgba(36, 45, 65, 0.6)',
  black70Alpha: 'rgba(36, 45, 65, 0.7)',
  black80Alpha: 'rgba(36, 45, 65, 0.8)',
  black90Alpha: 'rgba(36, 45, 65, 0.9)',
  brandBlue: 'rgb(18, 111, 214)',
  brandBlue02Alpha: 'rgba(18, 111, 214, 0.02)',
  brandBlue03Alpha: 'rgba(18, 111, 214, 0.03)',
  brandBlue05Alpha: 'rgba(18, 111, 214, 0.05)',
  brandBlue10Alpha: 'rgba(18, 111, 214, 0.1)',
  brandBlue15Alpha: 'rgba(18, 111, 214, 0.15)',
  brandBlue20Alpha: 'rgba(18, 111, 214, 0.2)',
  brandBlue30Alpha: 'rgba(18, 111, 214, 0.3)',
  brandBlue40Alpha: 'rgba(18, 111, 214, 0.4)',
  brandBlue50Alpha: 'rgba(18, 111, 214, 0.5)',
  brandBlue60Alpha: 'rgba(18, 111, 214, 0.6)',
  brandBlue70Alpha: 'rgba(18, 111, 214, 0.7)',
  brandBlue80Alpha: 'rgba(18, 111, 214, 0.8)',
  brandBlue90Alpha: 'rgba(18, 111, 214, 0.9)',
  cadetBlueFaded: 'hsla(216,19%, 74%, 50%)',
  cobalt: 'rgb(2, 122, 255)',
  fog: 'rgb(238, 239, 243)',
  frostedSteel: 'rgb(219, 227, 237)',
  frostedSteel10Alpha: 'rgba(219, 227, 237, 0.1)',
  frostedSteel20Alpha: 'rgba(219, 227, 237, 0.2)',
  frostedSteel30Alpha: 'rgba(219, 227, 237, 0.3)',
  frostedSteel40Alpha: 'rgba(219, 227, 237, 0.4)',
  frostedSteel50Alpha: 'rgba(219, 227, 237, 0.5)',
  frostedSteel60Alpha: 'rgba(219, 227, 237, 0.6)',
  frostedSteel70Alpha: 'rgba(219, 227, 237, 0.7)',
  frostedSteel80Alpha: 'rgba(219, 227, 237, 0.8)',
  frostedSteel90Alpha: 'rgba(219, 227, 237, 0.9)',
  itemMel: '#194479',
  navy: 'rgb(0, 30, 61)',
  navyDark: 'rgb(0, 21, 43)',
  porcelain: 'rgb(251, 252, 253)',
  red: 'rgb(202, 35, 34)',
  red10Alpha: 'rgba(202, 35, 34, 0.1)',
  red5Alpha: 'rgba(202, 35, 34, 0.05)',
  red90Alpha: 'rgba(202, 35, 34, 0.9)',
  salt: 'rgb(252, 252, 252)',
  sapphire: '#1e5bb5;',
  softGrey: '#f6f8fb',
  snow: 'rgb(250, 251, 253)',
  white: 'rgb(255, 255, 255)',
  white70Alpha: 'rgba(255, 255, 255, 0.7)',
  white80Alpha: 'rgba(255, 255, 255, 0.8)',
  green: '#4AA91C',
  zircon: '#fcfdff',
};

const rootSizes = {
  // Media queries breakpoints
  // Extra small screen / phone
  'screen-xs': '480px',
  // Small screen / tablet
  'screen-sm': '576px',
  // Medium screen / desktop
  'screen-md': '768px',
  // Large screen / wide desktop
  'screen-lg': '992px',
  // Extra large screen / full hd
  'screen-xl': '1200px',
  // Extra extra large screen / large desktop
  'screen-xxl': '1600px',
};

const minSizes = {
  // Media queries breakpoints
  // Extra small screen / phone
  'screen-xs-min': rootSizes['screen-xs'],

  // Small screen / tablet
  'screen-sm-min': rootSizes['screen-sm'],

  // Medium screen / desktop
  'screen-md-min': rootSizes['screen-md'],

  // Large screen / wide desktop
  'screen-lg-min': rootSizes['screen-lg'],

  // Extra large screen / full hd
  'screen-xl-min': rootSizes['screen-xl'],

  // Extra extra large screen / large desktop
  'screen-xxl-min': rootSizes['screen-xxl'],
};

const maxSizes = {
  // provide a maximum
  'screen-xs-max': `calc(${minSizes['screen-sm-min']} - 1px)`,
  'screen-sm-max': `calc(${minSizes['screen-md-min']} - 1px)`,
  'screen-md-max': `calc(${minSizes['screen-lg-min']} - 1px)`,
  'screen-lg-max': `calc(${minSizes['screen-xl-min']} - 1px)`,
  'screen-xl-max': `calc(${minSizes['screen-xxl-min']} - 1px)`,
};

const gradients = {
  tfGradientGreen: 'linear-gradient(180deg, #7bc88d 0%, #4aa91c 100%)',
  tfGradientAmber: 'linear-gradient(180deg, #fad288 0%, #f2a650 100%)',
  tfGradientRed: 'linear-gradient(180deg, #ff7474 0%, #ff4040 100%)',
  tfGradientGrey: 'linear-gradient(0deg, #d2d2d2 0%, #e0e0e0 100%)',
  tfGradientBlue: 'linear-gradient(201deg, #81bfff 16%, #57aaff 73%)',
};

const font = {
  fontSize: '16px',
  fontFamily:
    // eslint-disable-next-line max-len
    'Lato, system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
};

const theme = {
  colours,
  colors,
  sizes: {
    ...rootSizes,
    ...minSizes,
    ...maxSizes,
  },
  gradients,
  font,
};

const CustomThemeProvider: FunctionComponent = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
export type Theme = typeof theme;
