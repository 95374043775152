import { useQuery } from '@tanstack/react-query';
import { queryCabinIssuesTotals } from '../../../services/api';

const useQueryCabinIssueTotals = ({
  aircraft_id,
}: {
  aircraft_id: string;
}): { data: { status?: { open?: number; resolved?: number } } } => {
  const { data } = useQuery({
    queryKey: ['cabinIssueTotals', aircraft_id],
    queryFn: () => queryCabinIssuesTotals(aircraft_id),
  });
  return { data };
};

export default useQueryCabinIssueTotals;
