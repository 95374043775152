/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Search } from '@arcflight/tf-component-library';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Loading from '../../TFLoading/index';
import TFTooltip from '../../TFTooltip/TFTooltip';
import { FeatureFlag } from '../../../models/userSettings';
import { Defect } from '../../../models/defects';
import { DashboardState } from '../../../models';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import { getMelItemsForAircraft } from '../../../models/mels/actions';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import ViewMelDetails from './ViewMelDetails';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import DefectMelItems from './DefectMelItems';
import DefectNonMelItems from './DefectNonMelItems';
import StyledRadioButton from './StyledRadioButton';
import DeferUntil from './DeferUntil';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';
import ViewNewLayoutMelDetails from './ViewNewLayoutMelDetails';
import { handleDeferralTimeChange } from './utils';

interface DeferralOptionsProps {
  defect: Defect | null;
  originalDefectData: Defect | null;
  updateDefectData: (changes: any[]) => void;
  editDefect?: boolean;
  aircraftId?: string;
  melItemsLoading?: boolean;
  setMelItemsLoading?: (input: boolean) => void;
  setDateDue?: (input) => void;
  apuInstalled?: boolean;
  setAircraftHasMEL?: (value: boolean) => void;
  poIntl: {
    po_short_name: string;
    po_long_name: string;
    po_suffix: string;
  };
  formChanged?: boolean;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const MelItemsWrapper = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  margin-bottom: 20px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const BoldDiv = styled.div`
  font-weight: 600;
  @media (max-width: 451px) {
    max-width: 165px;
  }
`;

const RegularText = styled.span`
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 4px;
  width: 100%;
  @media (max-width: 450px) {
    margin: 20px 0;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
`;

const DeferralOptions: React.FC<DeferralOptionsProps> = ({
  defect,
  originalDefectData,
  editDefect,
  aircraftId,
  melItemsLoading,
  setMelItemsLoading,
  setDateDue,
  apuInstalled,
  updateDefectData,
  setAircraftHasMEL,
  poIntl,
  formChanged,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredItems, setFilteredItems] = useState(null);
  const [filteredCasItems, setFilteredCasItems] = useState(null);
  const [melItems, setMelItems] = useState(null);
  const [splitMelItems, setSplitMelItems] = useState(null);
  const [casItems, setCasItems] = useState(null);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isMobile = window.innerWidth < 451;

  const {
    mels: { aircraftMelsMap, lastFetched, dirty },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  let list = [];

  const featureFlags = aircraftMap.get(aircraftId || id)?.feature_flags
    ? aircraftMap.get(id)?.feature_flags.map((flag) => flag.feature_name)
    : [];

  if (aircraftMelsMap && aircraftMelsMap.get(aircraftId || defect?.aircraft?.id)) {
    list = aircraftMelsMap.get(aircraftId || defect.aircraft.id).list;
  }
  const handleDefectTypeChange = (option): void => {
    updateDefectData([
      { value: option, key: 'defect_type' },
      { value: undefined, key: 'mel_item' },
      { value: undefined, key: 'mel_rectification_id' },
      { value: undefined, key: 'date_due' },
      { value: {}, key: 'display_data' },
    ]);
  };

  const showRichTextMel = aircraftMap.get(aircraftId || id)?.standard_fields?.rich_text_mel?.enabled;
  const melNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override;
  const melLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.long_name_override;
  const melTypeDisabled = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.enabled === false;
  const cdlNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.name_override;
  const cdlLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.long_name_override;
  const cdlTypeDisabled = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cdl?.enabled === false;
  const nefNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.name_override;
  const nefLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.long_name_override;
  const nefTypeDisabled = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_nef?.enabled === false;
  const casNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.name_override;
  const casLongNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.long_name_override;
  const casTypeDisabled = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_cas?.enabled === false;
  const otherNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other?.name_override;
  const otherTypeDisabled = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_other?.enabled === false;

  const deferralOptionsArray = [];
  if (!melTypeDisabled) deferralOptionsArray.push(melNameOverride || 'MEL');
  if (!cdlTypeDisabled) deferralOptionsArray.push(cdlNameOverride || 'CDL');
  if (!nefTypeDisabled) deferralOptionsArray.push(nefNameOverride || 'NEF');
  if (!casTypeDisabled) deferralOptionsArray.push(casNameOverride || 'CAS');
  if (!otherTypeDisabled) deferralOptionsArray.push(otherNameOverride || 'Other');
  const deferralOptions = deferralOptionsArray.map((option) => (
    <StyledRadioButton
      identifier={`DeferralOption${option}`}
      id={`DeferralOption${option}`}
      value={`${option}`}
      width="auto"
      group="defectTypeGroup"
      checked={option === defect?.defect_type}
      key={option}
      marginRight={option !== 'Other' ? 5 : 0}
      onClick={(): void => handleDefectTypeChange(option)}
    >
      {option}
    </StyledRadioButton>
  ));

  const usePreviousMelItems = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevMelItems = usePreviousMelItems(list);

  const handleLocalMelItemChange = (melItemId: string, rectId: string): void => {
    const melItem = melItems.find((item) => item.id === melItemId);
    updateDefectData([
      { value: melItem, key: 'melItem' },
      { value: melItem.mel_rectifications[0].operational_limitations, key: 'limitations' },
      { value: rectId, key: 'mel_rectification_id' },
    ]);
  };

  useEffect(() => {
    if (
      defect?.defect_type === 'MEL' ||
      (featureFlags.includes(FeatureFlag.MELINTEGRATION) ? defect?.defect_type === 'CAS' : false)
    ) {
      setMelItemsLoading(false);
    }
  }, [defect?.defect_type, setMelItemsLoading]);

  useEffect(() => {
    if (!defect?.deferred) {
      updateDefectData([{ value: 'Other', key: 'defect_type' }]);
    }
  }, [defect]);

  useEffect(() => {
    if (melItems) {
      const separatedItems = melItems.reduce(
        (obj, item) => {
          const newObj = obj;
          if (item?.type === 'CasMessage') {
            if (newObj?.casItems) {
              newObj.casItems.push(item);
            } else {
              newObj.casItems = [item];
            }
          } else if (newObj.melItems) {
            newObj.melItems.push(item);
          } else {
            newObj.melItems = [item];
          }
          return newObj;
        },
        { casItems: [], melItems: [] },
      );
      setCasItems(separatedItems.casItems);
      setFilteredCasItems(separatedItems.casItems);
      setSplitMelItems(separatedItems.melItems);
      setFilteredItems(separatedItems.melItems);
    }
  }, [melItems]);

  useEffect(() => {
    if (
      editDefect &&
      (defect?.defect_type === melNameOverride ||
        defect?.defect_type === 'MEL' ||
        (featureFlags.includes(FeatureFlag.MELINTEGRATION) ? defect?.defect_type === 'CAS' : false))
    ) {
      if (aircraftId) {
        if (!aircraftMelsMap.has(aircraftId) && !melItemsLoading) {
          setMelItemsLoading(true);
          dispatch(getMelItemsForAircraft({ payload: { id: aircraftId } }));
        } else if ((Date.now() - lastFetched >= 30000 || dirty) && !aircraftMelsMap.has(aircraftId)) {
          setMelItemsLoading(true);
          dispatch(getMelItemsForAircraft({ payload: { id: aircraftId } }));
        }
      }
    }
  }, [
    aircraftId,
    aircraftMelsMap,
    defect,
    defect?.defect_type,
    dirty,
    dispatch,
    editDefect,
    lastFetched,
    melItems,
    setMelItemsLoading,
  ]);

  useEffect(() => {
    if (!_.isEqual(list, prevMelItems)) {
      setMelItems(list);
      setMelItemsLoading(false);
      if (list?.length) {
        setAircraftHasMEL(true);
      }
    } else if (melItemsLoading && list?.length === 0) {
      setMelItemsLoading(false);
    }
  }, [list, prevMelItems]);

  const filterMelItems = useCallback(
    (input: string): void => {
      if (
        featureFlags.includes(FeatureFlag.MELINTEGRATION) &&
        (defect?.defect_type === casNameOverride || defect?.defect_type === 'CAS')
      ) {
        const filtered = casItems.filter((item) => {
          if (item.title.toLowerCase().includes(input)) return item;
          return null;
        });
        setFilteredCasItems(filtered);
      } else {
        const filtered = melItems.filter((item) => {
          let itemReference = `${item?.chapter_number}-${item?.section_number}`;
          if (item?.subsection_number) {
            itemReference = `${itemReference}-${item?.subsection_number}`;
          }
          if (itemReference.toString().toLowerCase().includes(input) || item.title.toLowerCase().includes(input))
            return item;
          return null;
        });
        setFilteredItems(filtered);
      }
    },
    [melItems],
  );

  useEffect(() => {
    if (searchInput) {
      filterMelItems(searchInput);
    } else {
      setFilteredItems(splitMelItems);
      setFilteredCasItems(casItems);
    }
  }, [searchInput]);

  return (
    <Wrapper id="DeferralOptions" data-testid="StyledCard--DeferralOptions">
      <FlexWrapper column marginBottom={20}>
        <>
          <FlexWrapper marginBottom={30}>
            <FlexWrapper column marginRight={80}>
              <FlexWrapper>
                <Label marginBottom={20} fontWeight={600} identifier="DeferralOptions" marginRight={4}>
                  {formatMessage({ id: 'text.deferralOptions' })}
                </Label>
                <TFTooltip>
                  {!melTypeDisabled ? (
                    <BoldDiv>
                      {melNameOverride || formatMessage({ id: 'text.mel' })}{' '}
                      <RegularText>
                        {melLongNameOverride || formatMessage({ id: 'text.minimumEquipmentList' })}
                      </RegularText>
                    </BoldDiv>
                  ) : null}
                  {!cdlTypeDisabled ? (
                    <BoldDiv>
                      {cdlNameOverride || formatMessage({ id: 'text.cdl' })}
                      <RegularText>
                        {cdlLongNameOverride || formatMessage({ id: 'text.configurationDeviationList' })}
                      </RegularText>
                    </BoldDiv>
                  ) : null}
                  {!nefTypeDisabled ? (
                    <BoldDiv>
                      {nefNameOverride || formatMessage({ id: 'text.nef' })}{' '}
                      <RegularText>
                        {nefLongNameOverride || formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' })}
                      </RegularText>
                    </BoldDiv>
                  ) : null}
                  {!cdlTypeDisabled ? (
                    <BoldDiv>
                      {casNameOverride || formatMessage({ id: 'text.cas' })}{' '}
                      <RegularText>
                        {casLongNameOverride || formatMessage({ id: 'text.crewAlertingSystem' })}
                      </RegularText>
                    </BoldDiv>
                  ) : null}
                </TFTooltip>
              </FlexWrapper>
              <FlexWrapper identifier="DeferralOptionsWrapper">{deferralOptions}</FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
          {defect?.defect_type &&
          (defect?.defect_type === melNameOverride ||
            defect?.defect_type === 'MEL' ||
            (featureFlags.includes(FeatureFlag.MELINTEGRATION) &&
              (defect?.defect_type === casNameOverride || defect?.defect_type === 'CAS'))) ? (
            <>
              <Label marginBottom={15} fontWeight={500}>
                {defect?.defect_type === melNameOverride || defect?.defect_type === 'MEL'
                  ? `${
                      melNameOverride
                        ? `${melNameOverride}${melLongNameOverride ? ` - ${melLongNameOverride}` : ''}`
                        : 'MEL - Minimum Equipment List'
                    }`
                  : `${
                      casNameOverride
                        ? `${casNameOverride}${casLongNameOverride ? ` - ${casLongNameOverride}` : ''}`
                        : 'CAS - Crew Alerting System'
                    }`}
              </Label>
              <MelItemsWrapper>
                <Loading loading={melItemsLoading} contain />
                {melItems && melItems.length > 0 ? (
                  <>
                    <SearchWrapper>
                      <Search
                        onClear={(): void => setSearchInput('')}
                        onChange={(e): void => setSearchInput(e.currentTarget.value.toLowerCase())}
                      />
                    </SearchWrapper>
                    <Card id="MELItemWrapper" maxHeight="500px" identifier="MelItemsWrapper">
                      <DefectMelItems
                        melItems={filteredItems}
                        casItems={filteredCasItems}
                        updateDefectData={updateDefectData}
                        handleMelItemChange={handleLocalMelItemChange}
                        defect={defect}
                        searchInput={searchInput}
                        cas={
                          featureFlags.includes(FeatureFlag.MELINTEGRATION) &&
                          (defect?.defect_type === casNameOverride || defect?.defect_type === 'CAS')
                        }
                      />
                    </Card>
                    <FlexWrapper column={isMobile} marginTop={30} marginBottom={30}>
                      <FlexWrapper marginRight={20}>
                        <DateTimePicker
                          dateTime={defect?.deferred_at}
                          headerDate="Deferral date"
                          headerTime="Deferral time"
                          handleDateTimeChange={(value) => handleDeferralTimeChange(value, defect, updateDefectData)}
                          disabled={!unlockDeferralTime}
                        />
                      </FlexWrapper>
                      <FlexWrapper marginTop={isMobile ? 20 : 0} width="100%">
                        <DeferUntil defect={defect} formChanged={formChanged} />
                        <ButtonWrapper>
                          {!unlockDeferralTime ? (
                            <Button
                              primary={false}
                              height="24px"
                              padding="0 12px"
                              onClick={(): void => setUnlockDeferralTime(true)}
                            >
                              <StyledIcon src={padlockIcon} alt="padlock icon" />
                              Unlock
                            </Button>
                          ) : null}
                        </ButtonWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                    {defect?.mel_item && !isMobile && (
                      <>
                        {showRichTextMel ? (
                          <ViewNewLayoutMelDetails defect={defect} isMelTableVisible />
                        ) : (
                          <ViewMelDetails defect={defect} isMelTableVisible edit />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <ManuallyEnterMELDetails
                    defect={defect}
                    setDateDue={setDateDue}
                    noMEL
                    apuInstalled={apuInstalled}
                    updateDefectData={updateDefectData}
                    aircraftId={aircraftId}
                    poIntl={poIntl}
                    formChanged={formChanged}
                  />
                )}
              </MelItemsWrapper>
            </>
          ) : null}
          {defect?.defect_type &&
          (defect?.defect_type === cdlNameOverride ||
            defect?.defect_type === 'CDL' ||
            defect?.defect_type === nefNameOverride ||
            defect?.defect_type === 'NEF' ||
            defect?.defect_type === otherNameOverride ||
            defect?.defect_type === 'Other' ||
            (!featureFlags.includes(FeatureFlag.MELINTEGRATION) &&
              (defect?.defect_type === casNameOverride || defect?.defect_type === 'CAS'))) ? (
            <DefectNonMelItems
              defect={defect}
              originalDefectData={originalDefectData}
              deferred={defect?.deferred}
              setDateDue={setDateDue}
              apuInstalled={apuInstalled}
              updateDefectData={updateDefectData}
              aircraftId={aircraftId}
              poIntl={poIntl}
              formChanged={formChanged}
            />
          ) : null}
        </>
      </FlexWrapper>
    </Wrapper>
  );
};

export default DeferralOptions;
