import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import FileViewer from '../../TFFileViewer/fileViewer';
import TFButton from '../../TFButton/TFButton';
import ResolutionDetails from '../../WorkPackDrawer/Components/ResolutionDetails';
import getOperatorSetting from '../../../utils/getOperatorSetting';
import { DashboardState } from '../../../models';
import TFSelect from '../../TFSelect/TFSelect';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';
import ViewDeferralOptions from './ViewDeferralOptions';
import ViewMelDetails from './ViewMelDetails';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';
import ViewNewLayoutMelDetails from './ViewNewLayoutMelDetails';
import ReportedByType from './ReportedByType';

interface DefectDetailsProps {
  defect: Defect;
  updateDefectData: (changes: any[]) => void;
  editDefect: boolean;
  signature: string;
  setDateDue?: (input) => void;
  apuInstalled?: boolean;
  aircraftId: string;
  eropsTitle: string;
  poIntl: {
    po_short_name: string;
    po_long_name: string;
    po_suffix: string;
  };
  formChanged: boolean;
}

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
`;

const BorderLine = styled.div`
  width: 680px;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-left: -22px;
  @media (max-width: 450px) {
    width: calc(100vw - 40px);
  }
`;

const StyledImg = styled.img`
  height: 70px;
  max-width: 240px;
`;

const StageOfFlightWrapper = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.div`
  font-size: ${({ edit }): string => (edit ? '14px' : '12px')};
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(35, 45, 65, 0.4)')};
  font-weight: ${({ edit }): string => (edit ? '400' : 'unset')};
  margin-bottom: 4px;
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
`;

const DefectDetails: React.FC<DefectDetailsProps> = ({
  defect,
  editDefect,
  updateDefectData,
  signature,
  setDateDue,
  apuInstalled,
  aircraftId,
  eropsTitle,
  poIntl,
  formChanged,
}) => {
  const [isMelTableVisible, setIsMelTableVisible] = useState(true);
  const [localStageOfFlight, setLocalStageOfFlight] = useState(null);
  const [stageOfFlightOptions, setStageOfFlightOptions] = useState([]);

  const { formatMessage } = useIntl();
  const { userSettings } = useSelector((state: DashboardState) => state);
  const {
    userSettings: { dateFormat },
    aircraft: { aircraftMap },
    drawer: { mode, editResolved },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const showRichTextMel = aircraftMap.get(id)?.standard_fields?.rich_text_mel?.enabled;
  const melNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override;
  const troubleShootingStepsRename = aircraftMap.get(aircraftId || id)?.standard_fields?.troubleshooting_steps
    ?.name_override;
  const deferralApprovalReferenceRename = getOperatorSetting('defer_approval_reference_name', id);

  const operatorSettings = userSettings?.details?.operators.find(
    (op) => op.id === aircraftMap.get(id)?.operator_id,
  )?.operator_setting;

  const lastMXEvent = defect?.mx_events && defect?.mx_events[defect?.mx_events.length - 1];

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const newAttachments = defect?.attachments?.concat(...base64Attachments);
      updateDefectData([{ value: newAttachments, key: 'attachments' }]);
    }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList: any[] = defect?.attachments.filter(
      (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    updateDefectData([{ value: newAttachmentsList, key: 'attachments' }]);
  };

  const handleMELTableClick = (): void => {
    setIsMelTableVisible(!isMelTableVisible);
  };

  const handleStageOfFlightChange = (option): void => {
    setLocalStageOfFlight(option?.value);
    updateDefectData([{ value: option?.value, key: 'stage_of_flight' }]);
  };

  useEffect(() => {
    if (defect) {
      setLocalStageOfFlight(defect?.stage_of_flight);
    } else {
      setLocalStageOfFlight(null);
    }
  }, [defect]);

  useEffect(() => {
    if (operatorSettings?.stage_of_flight_options?.length) {
      const newOptions = operatorSettings?.stage_of_flight_options?.map((option) => {
        return {
          title: option,
          value: option,
        };
      });
      setStageOfFlightOptions(newOptions);
    }
  }, [operatorSettings]);

  const isMobile = window.innerWidth < 451;

  const extendedOn = (): string => {
    if (!defect || !defect.rectification_interval_extension) {
      return '-';
    }
    return moment.utc(defect.rectification_interval_extension.extension_date).format(`${userSettings.dateFormat}`);
  };

  const newDueDate = (): string => {
    if (
      !defect ||
      !defect.rectification_interval_extension ||
      !defect.rectification_interval_extension.extension_date_due
    ) {
      return '-';
    }

    return moment.utc(defect.rectification_interval_extension.extension_date_due).format(`${userSettings.dateFormat}`);
  };

  const release = { ...lastMXEvent };
  return (
    <Card marginBottom={20} identifier="DefectDetailsWrapper">
      <Label fontWeight={600} identifier="DefectDetailsLabel">
        {formatMessage({ id: 'form.labels.defectDetails' }, { field: operatorSettings?.defect_name })}
      </Label>
      <GridWrapper columns={isMobile ? '1fr' : '1fr 1fr'} marginBottom={20} marginTop={20} columnGap={20}>
        <FlexWrapper column colSpan={0}>
          <Label
            fontSize={editDefect ? '14' : '12'}
            color={editDefect ? '' : 'rgba(36, 45, 65, 0.4)'}
            textTransform={editDefect ? '' : 'uppercase'}
            identifier="NotesLabel"
          >
            Details
          </Label>
          {editDefect || editResolved ? (
            <StyledTextarea
              id="details"
              name="details"
              data-testid="DefectDetails--TextArea"
              cols={30}
              rows={5}
              onChange={(e): void => {
                updateDefectData([{ value: e.target.value, key: 'details' }]);
              }}
              value={defect?.details}
            />
          ) : (
            <span data-testid="DefectDetails--Details">{defect?.details || '-'}</span>
          )}
        </FlexWrapper>
        <FlexWrapper width="100%" column marginTop={isMobile ? 20 : 0}>
          <Label
            fontSize={editDefect ? '14' : '12'}
            color={editDefect ? '' : 'rgba(36, 45, 65, 0.4)'}
            textTransform={editDefect ? '' : 'uppercase'}
            identifier="AttachmentsLabel"
          >
            {formatMessage({ id: 'title.attachments' })}
          </Label>
          <FileViewer
            attachments={defect?.attachments}
            handleDeleteClick={handleDelete}
            addAttachment={handleAttachmentUpload}
            uploaderVisible={editDefect}
            allowDelete={editDefect}
          />
        </FlexWrapper>
      </GridWrapper>
      {operatorSettings?.defect_reported_by_type ? (
        <ReportedByType aircraftId={aircraftId} defect={defect} updateDefectData={updateDefectData} />
      ) : null}
      {operatorSettings?.stage_of_flight_enabled ? (
        <StageOfFlightWrapper>
          <Header edit={mode !== 'view'}>{operatorSettings?.stage_of_flight_text}</Header>
          {mode === 'view' ? (
            <span>{localStageOfFlight || '-'}</span>
          ) : (
            <TFSelect
              options={stageOfFlightOptions}
              initial={{ title: localStageOfFlight, value: localStageOfFlight }}
              handleSelectChange={(option): void => handleStageOfFlightChange(option)}
            />
          )}
        </StageOfFlightWrapper>
      ) : null}
      {editDefect && !defect?.deferred ? (
        <>
          <ManuallyEnterMELDetails
            defect={defect}
            setDateDue={setDateDue}
            noMEL
            apuInstalled={apuInstalled}
            updateDefectData={updateDefectData}
            poIntl={poIntl}
            formChanged={formChanged}
          />
        </>
      ) : null}
      {!editDefect && (defect?.reporter?.first_name || defect?.reporter?.last_name || defect?.signature_image_url) ? (
        <>
          <BorderLine />
          <FlexWrapper
            justifyContent="space-between"
            alignItems={isMobile ? '' : 'center'}
            marginTop={mode === 'view' ? 20 : 35}
            marginBottom={20}
            column={isMobile}
            height={isMobile ? null : 40}
          >
            <FlexWrapper alignItems="center">
              <Label fontWeight={500} marginRight={4}>
                {formatMessage({ id: 'text.reportedBy' })}
              </Label>
              <Label fontWeight={600} marginRight={15}>
                {defect?.reporter?.first_name || '-'} {defect?.reporter?.last_name || null}
              </Label>
              {defect?.signature_image_url && (
                <StyledImg alt="Signature" src={`data:image/png;base64, ${defect?.signature_data}`} />
              )}
            </FlexWrapper>
            <FlexWrapper flexShrink="0">
              <Label fontWeight={500} marginRight={4}>
                {formatMessage({ id: 'form.labels.date' })}:
              </Label>
              <Label fontWeight={600}>{moment(defect?.raised_at).utc().format(dateFormat) || '-'}</Label>
            </FlexWrapper>
          </FlexWrapper>
        </>
      ) : null}
      {!editDefect && (
        <>
          <BorderLine />
          <ViewDeferralOptions defect={defect} aircraftId={aircraftId} formChanged={formChanged} />
          {(defect?.defect_type === melNameOverride || defect?.defect_type === 'MEL') && defect?.mel_item?.id ? (
            <>
              <TFButton padding="0" clear handleClick={handleMELTableClick}>
                {isMelTableVisible
                  ? `Hide ${melNameOverride || 'MEL'} Table`
                  : `Show ${melNameOverride || 'MEL'} Table`}
              </TFButton>
              {showRichTextMel ? (
                <ViewNewLayoutMelDetails defect={defect} isMelTableVisible={isMelTableVisible} />
              ) : (
                <ViewMelDetails defect={defect} isMelTableVisible={isMelTableVisible} />
              )}
            </>
          ) : null}
          <FlexWrapper column marginBottom={30}>
            <Label marginBottom={20} marginTop={defect?.defect_type === 'MEL' ? 30 : 1} fontWeight={600}>
              {formatMessage({ id: 'form.labels.aircraftLimitations' })}
            </Label>
            <GridWrapper columns={isMobile ? '1fr' : '1fr 1fr'}>
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'form.labels.aircraftLimitations' })}
                </Label>
                <span>{defect?.limitations || '-'}</span>
              </FlexWrapper>
              {defect?.limitations ? (
                <FlexWrapper column>
                  <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                    {formatMessage({ id: 'form.labels.eropsPrevented' }, { field: eropsTitle })}
                  </Label>
                  <span>{defect?.erops_prevented ? 'Yes' : 'No'}</span>
                </FlexWrapper>
              ) : null}
            </GridWrapper>
          </FlexWrapper>
          <BorderLine />
          <FlexWrapper column marginBottom={30}>
            <Label marginBottom={20} marginTop={30} fontWeight={600}>
              {formatMessage({ id: 'title.additionalInformation' })}
            </Label>
            <FlexWrapper column marginBottom={20}>
              <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                {formatMessage(
                  { id: 'text.troubleshootingSteps' },
                  { field: troubleShootingStepsRename || 'Troubleshooting steps' },
                )}
              </Label>
              <span>{defect?.troubleshooting_steps || '-'}</span>
            </FlexWrapper>
            <GridWrapper columns={isMobile ? '1fr' : '1fr 1fr'}>
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'text.reasonToDefer' })}
                </Label>
                <span>{defect?.reason_to_defer || '-'}</span>
              </FlexWrapper>
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {deferralApprovalReferenceRename}
                </Label>
                <span>{defect?.defer_approval_reference || '-'}</span>
              </FlexWrapper>
            </GridWrapper>
            {defect?.deferral_approval_number || defect?.deferral_licence_number ? (
              <GridWrapper columns={isMobile ? '1fr' : '1fr 1fr'} marginTop={20}>
                <FlexWrapper column>
                  <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                    {operatorSettings?.part_145_approval_number}
                  </Label>
                  <span>{defect?.deferral_approval_number || '-'}</span>
                </FlexWrapper>
                <FlexWrapper column>
                  <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                    <span>
                      {operatorSettings?.engineer} {operatorSettings?.engineer_licence_number}
                    </span>
                  </Label>
                  <span>{defect?.deferral_licence_number || '-'}</span>
                </FlexWrapper>
              </GridWrapper>
            ) : null}
          </FlexWrapper>
          {!editDefect &&
          defect?.deferred &&
          defect?.display_data?.category &&
          defect?.display_data?.category !== 'A' ? (
            <>
              <BorderLine />
              <FlexWrapper column marginBottom={30}>
                <Label marginBottom={20} marginTop={30} fontWeight={600}>
                  {formatMessage({ id: 'form.labels.rectificationIntervalExtension' })}
                </Label>
                <GridWrapper columns="1fr 1fr 1fr">
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extensionAdded' })}
                    </Label>
                    <span>{defect?.rectification_interval_extension ? 'YES' : 'NO'}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extendedOn' })}
                    </Label>
                    <span>{extendedOn()}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extensionDue' })}
                    </Label>
                    <span>{newDueDate()}</span>
                  </FlexWrapper>
                </GridWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.deferred_by?.id ? (
            <>
              <BorderLine />
              <FlexWrapper
                justifyContent="space-between"
                alignItems={isMobile ? '' : 'center'}
                marginTop={20}
                marginBottom={20}
                column={isMobile}
                height={isMobile ? null : 40}
              >
                <FlexWrapper alignItems="center">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.deferredBy' })}
                  </Label>
                  <Label fontWeight={600} marginRight={15}>
                    <span>
                      {defect?.deferred_by?.first_name} {defect?.deferred_by?.last_name}
                    </span>
                  </Label>
                  {(signature || defect?.deferral_signature_data || defect?.approval_signature_data) && (
                    <StyledImg alt="Signature" src={`data:image/png;base64, ${defect?.deferral_signature_data}`} />
                  )}
                </FlexWrapper>
                <FlexWrapper flexShrink="0">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.deferredOn' })}
                  </Label>
                  <Label fontWeight={600}>
                    {(defect?.deferred_at && moment(defect?.deferred_at).utc().format(`${dateFormat} HH:mm`)) || '-'}
                  </Label>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.edited_by?.first_name ? (
            <>
              <BorderLine />
              <FlexWrapper
                justifyContent="space-between"
                alignItems={isMobile ? '' : 'center'}
                marginTop={20}
                marginBottom={20}
                column={isMobile}
                height={isMobile ? null : 40}
              >
                <FlexWrapper alignItems="center">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.editedBy' })}
                  </Label>
                  <Label fontWeight={600} marginRight={15}>
                    {defect?.edited_by?.first_name ? defect?.edited_by?.first_name : ''}{' '}
                    {defect?.edited_by?.last_name || null}
                  </Label>
                  {(signature || defect?.deferral_signature_data || defect?.approval_signature_data) && (
                    <StyledImg alt="Signature" src={`data:image/png;base64, ${defect?.approval_signature_data}`} />
                  )}
                </FlexWrapper>
                <FlexWrapper flexShrink="0">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.editedOn' })}
                  </Label>
                  <Label fontWeight={600}>
                    {(defect?.edited_date && moment(defect?.edited_date).utc().format(`${dateFormat} HH:mm`)) || '-'}
                  </Label>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.mx_events && defect?.mx_events.length > 0 ? (
            <>
              <BorderLine />
              <ResolutionDetails release={{ release }} poNum={lastMXEvent.workpack_reference} noCard />
            </>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default DefectDetails;
