import { Layout } from 'antd';
import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { TopNav } from '@arcflight/tf-component-library';
import servers from '../utils/servers';
import logout from '../utils/logout';
import isScreenMobile from '../utils/isScreenMobile';
import LogoutIcon from '../assets/topNav/log-out.svg';
import ProfileSettings from '../assets/topNav/profile-settings.svg';
import StatusBanner from '../components/StatusBanner/StatusBanner';
import planeIcon from '../assets/title-aircraft.svg';
import { changeLayoutCollapsed } from '../models/menu';
import { getAllAircraft } from '../models/aircraft/actions';
import SwipeableDrawer from '../components/SwipeableDrawer';
import BasicLayout from './BasicLayout';
import styles from './InnerMenuLayout.module.less';
import AircraftMenu from './menus/AircraftMenu';
import AircraftDropdown from './menus/AircraftDropdown';

const { Header, Sider } = Layout;
const cookies = new Cookies();

class InnerMenuLayout extends React.PureComponent {
  handleContentOnClick = throttle(() => {
    this.setState({
      searchFocussed: false,
    });
  }, 600);

  handleTopMenuOnClick = throttle(() => {
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    this.setState({
      drawerOpen: true,
      drawerContent: <AircraftMenu selectedKeys={[path]} onClick={this.handleTopMenuOnClick} />,
    });
  }, 800);

  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    collapsed: PropTypes.bool.isRequired,
    bannerVisible: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    lastFetched: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    ttl: PropTypes.number.isRequired,
    userSettings: PropTypes.object.isRequired,
    history: PropTypes.object,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    history: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      aircraftMenuStuck: false,
      filter: '',
      isSmallScreen: false,
      searchFocussed: false,
      drawerOpen: false,
      drawerContent: null,
    };
    this.filterInput = React.createRef();
  }

  componentDidMount() {
    const {
      dispatch,
      lastFetched,
      match: {
        params: { id },
      },
      location,
      ttl,
      aircraftMap,
    } = this.props;
    if ((id && Date.now() - lastFetched >= ttl) || aircraftMap.size === 0) {
      dispatch(getAllAircraft());
    }
    dispatch(
      changeLayoutCollapsed({
        payload: true,
      }),
    );
    let path = location.pathname.replace(`/aircraft/${id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    this.setState({
      drawerContent: (
        <AircraftMenu
          selectedKeys={[path]}
          onClick={this.handleTopMenuOnClick}
          intermittentTitle={this.getIntermittentFaultName()}
          dentAndBuckleTitle={this.getDentAndBuckleTitle()}
        />
      ),
    });
  }

  getAircraft = () => {
    const { selectedAircraft } = this.props;
    return selectedAircraft.id ? selectedAircraft : null;
  };

  getSideNavLayoutStyle() {
    const { aircraftMenuStuck } = this.state;
    let paddingLeft = '';
    let paddingTop = '';
    if (aircraftMenuStuck) {
      paddingLeft = '128px';
      paddingTop = '64px';
    }
    return {
      minHeight: '0',
      maxWidth: '100vw',
      paddingLeft,
      paddingTop,
      position: 'relative',
    };
  }

  getDentAndBuckleTitle = () => {
    const {
      userSettings,
      match: {
        params: { id },
      },
      aircraftMap,
    } = this.props;
    let name = 'Dent & Buckle';
    const currentOrgId = aircraftMap.get(id)?.operator_id;
    if (userSettings.details) {
      const currentOrg = userSettings.details.operators.find((o) => o.id === currentOrgId);
      name = currentOrg?.operator_setting?.dent_and_buckle;
    }
    return name;
  };

  getIntermittentFaultName = (singular) => {
    const {
      userSettings,
      match: {
        params: { id },
      },
      aircraftMap,
    } = this.props;
    let name = singular ? 'Intermittent Fault' : 'Intermittent Faults';
    const currentOrgId = aircraftMap.get(id)?.operator_id;
    if (userSettings.details) {
      const currentOrg = userSettings.details.operators.find((o) => o.id === currentOrgId);

      name = singular
        ? currentOrg?.operator_setting?.intermittent_fault_name_singular
        : currentOrg?.operator_setting?.intermittent_fault_name_plural;
    }
    return name;
  };

  getTopMenuStyle() {
    const { collapsed } = this.props;
    const menuStyles = [styles.topMenu];
    if (!collapsed) {
      menuStyles.push(styles.topMenuWhenSideCollapsed);
    }
    return menuStyles;
  }

  getAircraftList() {
    const {
      aircraftMap,
      match: {
        params: { id },
      },
      location: { pathname },
    } = this.props;
    const { filter } = this.state;

    const aircraftList = Array.from(aircraftMap.values());
    const path = pathname.replace(`/aircraft/${id}/`, '');

    const slashIndex = path.indexOf('/');
    let commonPath = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    let selectedAircraftObj = {};
    const commonPathRedirects = ['settings'];
    if (commonPathRedirects.includes(commonPath)) {
      commonPath = 'overview';
    }
    const aircraftListArray = aircraftList.reduce((arr, item) => {
      const acItem = { ...item };
      const aircraftMatchesFilter =
        (item.fleet_number + item.registration).toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      const selectedAircraft = acItem.id === id;
      if (selectedAircraft) {
        selectedAircraftObj = acItem;
      }
      if (aircraftMatchesFilter) {
        acItem.path = `/aircraft/${item.id}/${commonPath}`;
        arr.push(acItem);
      }
      return arr;
    }, []);
    return {
      aircraftList: aircraftListArray,
      selectedAircraft: selectedAircraftObj,
    };
  }

  toggle = () => {
    const { collapsed } = this.props;
    this.handleMenuCollapse(!collapsed);
    for (let i = 1; i < 6; i += 1) {
      setTimeout(() => {
        this.triggerResizeEvent();
      }, i * 50);
    }
  };

  handleMenuCollapse = (collapsed) => {
    const { dispatch } = this.props;
    dispatch(
      changeLayoutCollapsed({
        payload: collapsed,
      }),
    );
  };

  handleFilterChange = (e) => {
    const { userSettings } = this.props;
    this.setState(
      {
        filter: e.target.value,
      },
      () => {
        const { drawerOpen, filter } = this.state;
        if (drawerOpen) {
          this.setState({
            drawerContent: (
              <AircraftDropdown
                aircraftList={this.getAircraftList().aircraftList}
                selectedAircraft={this.getAircraftList().selectedAircraft}
                onSearchChange={this.handleFilterChange}
                searchValue={filter}
                menuOnly
                userSettings={userSettings}
              />
            ),
          });
        }
      },
    );
  };

  handleStateChange = (status) => {
    this.setState({
      aircraftMenuStuck: status.status === Sticky.STATUS_FIXED,
    });
  };

  triggerResizeEvent = () => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  };

  updateMediaScreenSize = () => {
    this.setState({ isSmallScreen: isScreenMobile() });
  };

  renderSideNavBar = () => {
    const { userSettings } = this.props;
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    return (
      <Sider width={200} className={styles.scrollableSider} collapsed={false}>
        <AircraftDropdown
          aircraftList={this.getAircraftList().aircraftList}
          selectedAircraft={this.getAircraftList().selectedAircraft}
          onSearchChange={this.handleFilterChange}
          searchValue={this.state.filter}
          userSettings={userSettings}
        />
        <AircraftMenu
          selectedKeys={[path]}
          onClick={this.handleTopMenuOnClick}
          intermittentTitle={this.getIntermittentFaultName()}
          dentAndBuckleTitle={this.getDentAndBuckleTitle()}
        />
      </Sider>
    );
  };

  renderTopNavBar = () => {
    const {
      userSettings,
      history,
      intl: { formatMessage },
    } = this.props;
    const smallScreen = this.state.isSmallScreen;
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    const aircraft = this.getAircraft();
    const { people } = userSettings?.details || {};
    const role = people ? people[0].type : '-';
    const avatar = userSettings?.details?.avatar_url
      ? `${servers.api}/logins/${cookies.get('userId')}/avatar?${userSettings.details.avatar_url}`
      : null;
    const menuItems = [
      {
        label: `${formatMessage({ id: 'menu.account.settings', defaultMessage: 'Profile Settings' })}`,
        icon: ProfileSettings,
        onSelect: () => {
          history.push('/settings/account');
        },
      },
      {
        label: ` ${formatMessage({ id: 'menu.account.logout' })}`,
        icon: LogoutIcon,
        onSelect: () => {
          logout();
        },
      },
    ];
    let aircraftTitle = aircraft ? aircraft.registration.substring(0, 8) : '';
    if (aircraft?.fleet_number) aircraftTitle = `${aircraft?.fleet_number} (${aircraft?.registration.substring(0, 8)})`;
    return !smallScreen ? (
      <span className={styles.topNavWrapper}>
        <TopNav
          title={aircraftTitle}
          titleIcon={planeIcon}
          isLoading={false}
          userRole={role}
          username={
            userSettings?.displayName?.length
              ? userSettings?.displayName
              : `${userSettings?.details?.first_name} ${userSettings?.details?.last_name}`
          }
          menuItems={menuItems}
          userIcon={avatar}
        />
      </span>
    ) : null;
  };

  renderSecondNavBar = () => {
    const { userSettings } = this.props;
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    return (
      <Header className={`${styles.headerLayout} ${styles.secondaryLayout}`} data-test="headerLayout">
        <AircraftDropdown
          aircraftList={this.getAircraftList().aircraftList}
          selectedAircraft={this.getAircraftList().selectedAircraft}
          onSearchChange={this.handleFilterChange}
          searchValue={this.state.filter}
          forMobile
          userSettings={userSettings}
          toggleDrawer={() =>
            this.drawerToggle({
              drawerContent: (
                <AircraftDropdown
                  aircraftList={this.getAircraftList().aircraftList}
                  selectedAircraft={this.getAircraftList().selectedAircraft}
                  onSearchChange={this.handleFilterChange}
                  searchValue={this.state.filter}
                  userSettings={userSettings}
                  menuOnly
                />
              ),
            })
          }
        />
        <AircraftMenu
          selectedKeys={[path]}
          onClick={this.handleTopMenuOnClick}
          isMobile
          intermittentTitle={this.getIntermittentFaultName()}
          dentAndBuckleTitle={this.getDentAndBuckleTitle()}
        />
      </Header>
    );
  };

  drawerToggle = (additionalState) => {
    const { drawerOpen } = this.state;
    this.setState({
      drawerOpen: !drawerOpen,
      ...additionalState,
    });
  };

  render() {
    const { children, bannerVisible, aircraftMap, match } = this.props;
    const { searchFocussed, isSmallScreen, drawerOpen, drawerContent } = this.state;
    this.updateMediaScreenSize();

    const currentAircraft = aircraftMap.get(match.params.id);
    let aircraftBillingStatus = currentAircraft?.billing_status || 'Active';
    if (currentAircraft?.locked) aircraftBillingStatus = 'Locked';
    const topValue = bannerVisible ? 56 : 0;
    matchMedia('(max-width: 767px)').addListener(this.updateMediaScreenSize);
    return !isSmallScreen ? (
      <BasicLayout>
        <Layout className={bannerVisible ? styles.innerMenuLayout : styles.innerMenuLayoutNoBanner}>
          <Layout style={this.getSideNavLayoutStyle()}>
            <div aria-hidden className={styles.affixSpacer} />
            <div className={styles.contextAffix} style={{ top: bannerVisible ? '56px' : '0' }}>
              {this.renderSideNavBar()}
            </div>
            <Layout>
              <Sticky innerZ={200} className={styles.topNavBar} top={topValue} id="stickyHeader">
                {this.renderTopNavBar()}
                <StatusBanner
                  status={aircraftBillingStatus}
                  crs={currentAircraft?.maintenance_status_reason === 'missing_crs'}
                  crsId={currentAircraft?.missing_crs_workpack_id}
                  aircraftId={currentAircraft?.id}
                />
              </Sticky>
              <Layout
                id="InnerContent"
                className={styles.innerContent}
                style={{ paddingTop: bannerVisible ? '80px' : '24px' }}
              >
                <div className={styles.contentWrapper} data-test="contentWrapper">
                  {children}
                </div>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </BasicLayout>
    ) : (
      <BasicLayout>
        <Layout className={styles.innerMenuLayout}>
          <Sticky innerZ={200} top={64} className={styles.topNavBarMobile}>
            {this.renderTopNavBar()}
            {this.renderSecondNavBar()}
          </Sticky>

          <SwipeableDrawer
            open={drawerOpen}
            onToggle={() => this.drawerToggle()}
            data-test="swipeableDrawer"
            swipeDrawerContent={drawerContent}
          />
          <Layout style={this.getSideNavLayoutStyle()}>
            <Layout>
              <Layout className={searchFocussed ? [styles.innerContent, styles.searchFocussed] : styles.innerContent}>
                {children}
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </BasicLayout>
    );
  }
}

export default withRouter(
  injectIntl(
    connect(
      (
        { aircraft, menu, userSettings },
        {
          match: {
            params: { id },
          },
        },
      ) => ({
        aircraftMap: aircraft.aircraftMap,
        selectedAircraft: aircraft.aircraftMap.get(id) || {},
        collapsed: menu.collapsed,
        bannerVisible: menu.bannerVisible,
        layout: userSettings.ui.layout,
        lastFetched: aircraft.lastFetched,
        menu,
        ttl: aircraft.ttl,
        ...userSettings.ui,
        userSettings,
      }),
    )(InnerMenuLayout),
  ),
);
