export default {
  hoursToSeconds: (hours: undefined | null | number | string): number | null => {
    if (!hours) return null;

    const hoursAsNumber = Number(hours);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(hoursAsNumber) || typeof hoursAsNumber !== 'number') return null;

    return hoursAsNumber * 60 * 60;
  },

  secondsToHours: (seconds: undefined | null | number | string): number | null => {
    if (!seconds) return null;

    const secondsAsNumber = Number(seconds);

    if (Number.isNaN(secondsAsNumber) || typeof secondsAsNumber !== 'number') return null;

    return secondsAsNumber / 60 / 60;
  },

  getOnlyHoursFromSeconds: (seconds: undefined | null | number | string): number | null => {
    if (!seconds) return null;

    const secondsAsNumber = Number(seconds);

    if (Number.isNaN(secondsAsNumber) || typeof secondsAsNumber !== 'number') return null;

    return Math.floor(secondsAsNumber / 60 / 60);
  },

  getOnlyMinutesFromSeconds: (seconds: undefined | null | number | string): number | null => {
    if (!seconds) return null;

    const secondsAsNumber = Number(seconds);

    if (Number.isNaN(secondsAsNumber) || typeof secondsAsNumber !== 'number') return null;

    return Math.floor((secondsAsNumber % 3600) / 60);
  },

  minutesToSeconds: (minutes: undefined | null | number | string): number | null => {
    if (!minutes) return null;

    const minutesAsNumber = Number(minutes);

    if (Number.isNaN(minutesAsNumber) || typeof minutesAsNumber !== 'number') return null;

    return minutesAsNumber * 60;
  },

  getTotalSecondsFromHoursAndMinutes: (
    hours: undefined | null | number | string,
    minutes: undefined | null | number | string,
  ): number | null => {
    if (!hours && !minutes) return null;

    const hoursAsNumber = Number(hours);
    const minutesAsNumber = Number(minutes);

    if (Number.isNaN(hoursAsNumber) || typeof hoursAsNumber !== 'number') return null;
    if (Number.isNaN(minutesAsNumber) || typeof minutesAsNumber !== 'number') return null;

    return hoursAsNumber * 60 * 60 + minutesAsNumber * 60;
  },

  getSecondsFromDateTime: (value: string): number => {
    if (!value) {
      return null;
    }
    const newDate = new Date(value);
    const hours = newDate.getUTCHours();
    const minutes = newDate.getUTCMinutes();

    const totalSeconds = hours * 3600 + minutes * 60;
    return totalSeconds;
  },

  getSecondsFromDays: (days: string | number): number => {
    if (!days) {
      return null;
    }
    const daysAsNumber = Number(days);
    if (Number.isNaN(daysAsNumber) || typeof daysAsNumber !== 'number') return null;

    return daysAsNumber * 24 * 60 * 60;
  },
};
