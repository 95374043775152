/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie-player';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import LoadingLight from './loadingLight.json';
import LoadingDark from './loadingDark.json';

const colourThemes = {
  light: css`
    background: rgba(255, 255, 255, 0.9);
  `,
  dark: css`
    background: rgba(0, 30, 61, 0.35);
  `,
  transparent: css`
    background: transparent;
  `,
};

const containOverlay = css`
  position: absolute;
`;

const fullPageOverlay = css`
  position: fixed;
`;

const fullPageLottie = css`
  height: 100vh;
`;

const setVisible = css`
  z-index: 1000;
`;

const setInvisible = css`
  z-index: -1;
`;

const lottieOptions = {
  dark: {
    loop: true,
    autoplay: true,
    animationData: LoadingLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },

  light: {
    loop: true,
    autoplay: true,
    animationData: LoadingDark,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  transparent: {
    loop: true,
    autoplay: true,
    animationData: LoadingDark,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
};

const Wrapper = styled.div`
  .fadeIn {
    opacity: 1;
  }
`;
interface LoadingDivProps {
  height?: number;
  width?: number;
  loading: boolean;
  theme?: string;
  contain?: boolean;
  topPosition?: number;
  belowDrawer?: boolean;
}

interface Contained {
  contain?: boolean;
  theme: string;
  loading: boolean;
  belowDrawer?: boolean;
}

const LoadingOverlay = styled.div<Contained>`
  ${({ theme }): FlattenSimpleInterpolation => colourThemes[theme]};
  ${({ contain }): FlattenSimpleInterpolation => (contain ? containOverlay : fullPageOverlay)};
  ${({ loading, belowDrawer }): FlattenSimpleInterpolation => {
    if (loading) {
      return belowDrawer
        ? css`
            z-index: 299;
          `
        : setVisible;
    }
    return setInvisible;
  }};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s;
  opacity: 0;
`;

const LottieWrapper = styled.div<Contained>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ contain, topPosition }): FlattenSimpleInterpolation => {
    if (contain) {
      return css`
        height: ${topPosition}%;
      `;
    }
    return fullPageLottie;
  }};
`;

const TFLoading: React.FC<LoadingDivProps> = ({
  height = 80,
  width = 80,
  topPosition = 100,
  loading,
  children,
  theme = 'light',
  contain = false,
  belowDrawer = false,
}) => {
  const overlayRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (loading) {
      overlayRef.current.classList.add('fadeIn');
    } else if (!loading) {
      overlayRef.current.classList.remove('fadeIn');
    }
  }, [loading]);

  return (
    <>
      <Wrapper>
        <LoadingOverlay ref={overlayRef} contain={contain} theme={theme} loading={loading} belowDrawer={belowDrawer}>
          {loading && (
            <LottieWrapper contain={contain} topPosition={topPosition}>
              <Lottie
                loop
                play
                animationData={lottieOptions[theme].animationData}
                style={{ height, width }}
                className="lottie"
              />
            </LottieWrapper>
          )}
        </LoadingOverlay>
      </Wrapper>
      {children && !loading && children}
    </>
  );
};

TFLoading.defaultProps = {
  height: 80,
  width: 80,
  theme: 'light',
  contain: false,
  topPosition: 100,
  belowDrawer: false,
};

export default TFLoading;
