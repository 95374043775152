import React from 'react';
import styled from 'styled-components';
import ToolTip from '../NewTooltip/NewTooltip';

const Label = styled.label`
  display: flex;
  font-size: 12px;
  letter-spacing: -0.4px;
  color: ${({ theme, readOnly }): string => (readOnly ? 'rgba(36, 45, 65, 0.4)' : theme.colours.graphite)};
  align-items: center;
  margin-bottom: 4px;
  text-transform: ${({ readOnly }): string => (readOnly ? 'uppercase' : 'none')};
  font-weight: ${({ readOnly }): string => (readOnly ? '400' : '600')};
  line-height: ${({ readOnly }): string => (readOnly ? 'normal' : '1.33')};
`;

const defaultProps = {
  optional: false,
  tooltip: null,
  readOnly: false,
};

interface TFFormLabelProps {
  id: string;
  label: string;
  optional?: boolean;
  tooltip?: string;
  readOnly?: boolean;
}

const TFFormLabel: React.FC<TFFormLabelProps> = ({ id, label, optional, tooltip, readOnly }) => {
  return (
    <Label data-testid={`TFFormLabel-${id}`} htmlFor={id} readOnly={readOnly}>
      {`${label}`} {optional ? ' (optional)' : ''}
      {tooltip && <ToolTip text={tooltip} />}
    </Label>
  );
};

TFFormLabel.defaultProps = defaultProps;

export default TFFormLabel;
