import { Loading } from '@arcflight/tf-component-library';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TFTag from '../TFTag/TFTag';
import locationMarker from '../../assets/location-marker.png';
import skyImage from '../../assets/sky.jpg';
import plusIcon from '../../assets/icon-favourite-add.svg';
import removeIcon from '../../assets/icon-favourite-remove.svg';
import estDueTimeOfAircraft from '../../utils/aircraftEstDueTime';
import config from '../../utils/config';
import ProgressBarsAircraft from '../MXCard/ProgressBarsAircraft';
import { fetchAircraftCardHoverData } from '../../services/apiNew';
import { updateAircraftInFleet } from '../../models/aircraft/actions';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { FeatureFlag } from '../../models/userSettings';
import styles from './AircraftCard.module.less';

class AircraftCard extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    aircraft: PropTypes.object,
    clickable: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    preAircraft: PropTypes.object.isRequired,
    operators: PropTypes.array.isRequired,
    handleFavouriteButtonClick: PropTypes.func.isRequired,
    isFavourite: PropTypes.bool,
    favouriteAircraftIds: PropTypes.array,
  };

  static defaultProps = {
    clickable: false,
    aircraft: undefined,
    isFavourite: false,
    favouriteAircraftIds: [],
  };

  state = {
    localAircraft: null,
    showSummary: false,
    airport: null,
    maintenanceForCRS: false,
  };

  constructor(props) {
    super(props);
    this.widgetCooldownMilliseconds = 400;
    this.mounted = true;
  }

  componentDidMount() {
    const { aircraft } = this.props;
    this.updateStateWithNewAircraftData(aircraft);
  }

  componentDidUpdate(prevProps) {
    const { aircraft, isFavourite } = this.props;
    if (isFavourite) {
      if (prevProps.aircraft !== aircraft) {
        this.updateStateWithNewAircraftData(aircraft);
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onMouseEnter = () => {
    if (!this.state.localAircraft) {
      return;
    }

    if (this.hasPreviouslyHovered()) {
      this.setState({ showSummary: true });
    } else {
      this.setState({ timeoutID: setTimeout(this.fetchHoverData, this.widgetCooldownMilliseconds) });
    }
  };

  onMouseLeave = () => {
    const setStateOpts = { showSummary: false };

    if (this.state.timeoutID) {
      clearTimeout(this.state.timeoutID);
      setStateOpts.timeoutID = null;
    }

    this.setState(setStateOpts);
  };

  getAirport(aircraft) {
    const MAX_NAME_LENGTH = 35;
    const airport = { ...aircraft?.departure_airport };
    if (airport?.name?.length > MAX_NAME_LENGTH) {
      airport.name = `${airport.name.substring(0, MAX_NAME_LENGTH)}...`;
    }

    this.setState({
      airport,
    });
  }

  getAircraftCardStyles() {
    const { clickable } = this.props;
    const { localAircraft, showSummary } = this.state;

    const cardStyles = [styles.aircraftCard];
    if (!localAircraft) {
      return cardStyles;
    }
    if (localAircraft.maintenance_status && localAircraft.maintenance_status === 'critical') {
      cardStyles.push(styles.criticalCard);
    }
    if (
      localAircraft.maintenance_status &&
      (localAircraft.maintenance_status === 'overdue' || localAircraft.maintenance_status === 'unserviceable')
    ) {
      cardStyles.push(styles.overdueCard);
    }
    if (localAircraft?.billing_status === 'Setup') {
      cardStyles.push(styles.setupCard);
    }
    if (localAircraft?.billing_status === 'Deleted') {
      cardStyles.push(styles.deletedCard);
    }
    if (localAircraft?.billing_status === 'Archived') {
      cardStyles.push(styles.archivedCard);
    }
    if (localAircraft?.billing_status === 'Demo') {
      cardStyles.push(styles.demoCard);
    }
    if (localAircraft?.locked) {
      cardStyles.push(styles.lockedCard);
    }
    if (!showSummary) {
      cardStyles.push(styles.mapDisplaying);
    }
    if (clickable) {
      cardStyles.push(styles.clickable);
    }
    return cardStyles;
  }

  getAirportNameMargin = () => {
    const { airport } = this.state;
    const PROPORTION_OF_WIDTH_TO_PAD = 0.35;
    const WIDTH_TO_CHARACTER_LENGTH_RATIO = 5.75;
    const padding = airport?.name?.length
      ? airport?.name?.length * WIDTH_TO_CHARACTER_LENGTH_RATIO * PROPORTION_OF_WIDTH_TO_PAD
      : 0;
    return {
      marginLeft: `-${padding}px`,
    };
  };

  getGoogleMapUrl() {
    const { airport } = this.state;
    const mapStyle = [
      'style=element:labels.icon%7Cvisibility:off',
      'style=element:labels.text.fill%7Ccolor:0x333333%7Csaturation:36%7Clightness:40',
      'style=element:labels.text.stroke%7Ccolor:0xffffff%7Clightness:16%7Cvisibility:on',
      'style=feature:administrative%7Celement:geometry.fill%7Ccolor:0xfefefe%7Clightness:20',
      'style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0xfefefe%7Clightness:17%7Cweight:1.2',
      'style=feature:administrative.land_parcel%7Celement:labels%7Cvisibility:off',
      'style=feature:landscape%7Celement:geometry%7Ccolor:0xf5f5f5%7Clightness:20',
      'style=feature:poi%7Celement:geometry%7Ccolor:0xf5f5f5%7Clightness:21',
      'style=feature:poi%7Celement:labels.text%7Cvisibility:off',
      'style=feature:poi.park%7Celement:geometry%7Ccolor:0xdedede%7Clightness:21',
      'style=feature:road.arterial%7Celement:geometry%7Ccolor:0xffffff%7Clightness:18',
      'style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0xffffff%7Clightness:17',
      'style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xffffff%7Clightness:29%7Cweight:0.2',
      'style=feature:road.local%7Celement:geometry%7Ccolor:0xffffff%7Clightness:16',
      'style=feature:road.local%7Celement:labels%7Cvisibility:off',
      'style=feature:transit%7Celement:geometry%7Ccolor:0xf2f2f2%7Clightness:19',
      'style=feature:water%7Celement:geometry%7Ccolor:0xe9e9e9%7Clightness:17',
    ].join('&');
    const apiKey = config.get('googleApiKey');
    const mapType = 'terrain';
    const zoom = '6';
    const scale = '2';
    const size = '500x310';
    const queryParams = [
      `center=${airport.latitude},${airport.longitude}`,
      `zoom=${zoom}`,
      `size=${size}`,
      `scale=${scale}`,
      `maptype=${mapType}`,
      `${mapStyle}`,
      `key=${apiKey}`,
    ].join('&');
    return `https://maps.googleapis.com/maps/api/staticmap?${queryParams}`;
  }

  static getStatusStyle(status) {
    let style = {};
    switch (status) {
      case 'on_ground':
        style = { background: '#002140' };
        break;
      case 'in_flight':
        style = { background: '#126fd6' };
        break;
      default:
        break;
    }
    return style;
  }

  updateStateWithNewAircraftData = (aircraft) => {
    this.setState({
      localAircraft: aircraft,
      maintenanceForCRS: aircraft?.maintenance_status_reason === 'missing_crs',
    });
    this.getAirport(aircraft);
  };

  hasPreviouslyHovered = () => {
    const { localAircraft } = this.state;

    if (!localAircraft.widgets) {
      return false;
    }

    return Object.keys(localAircraft.widgets).length > 0;
  };

  fetchHoverData = async () => {
    const {
      dispatch,
      intl: { formatMessage },
    } = this.props;
    const { localAircraft, timeoutID } = this.state;

    try {
      const widgetsResponse = await fetchAircraftCardHoverData(localAircraft.id);
      const hoverData = widgetsResponse.data;
      const updatedAircraft = {
        ...localAircraft,
        ...hoverData,
      };

      await dispatch(updateAircraftInFleet(updatedAircraft));

      this.setState({ localAircraft: updatedAircraft, showSummary: !!timeoutID, timeoutID: null });
    } catch (e) {
      console.error(e);
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.dataFetchError' }),
            message: formatMessage({ id: 'text.thereWasAnIssueWithNetwork' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      this.setState({
        showSummary: false,
        timeoutID: setTimeout(() => {}, this.widgetCooldownMilliseconds),
      });
    }
  };

  renderFavouriteButton = () => {
    const { aircraft, operators, isFavourite, handleFavouriteButtonClick, favouriteAircraftIds } = this.props;
    const operatorFeatureFlags = [];
    if (operators) {
      operators.forEach((operator) => {
        if (operator.feature_flags) {
          operator.feature_flags.forEach((flag) => {
            operatorFeatureFlags.push(flag.feature_name);
          });
        }
      });
    }
    const ffEnabled = operatorFeatureFlags.includes(FeatureFlag.FAVOURITEAIRCRAFT);
    if (!ffEnabled) return null;
    const isCurrentlyFavourite = favouriteAircraftIds?.includes(aircraft.id) || isFavourite;
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
      <img
        src={isCurrentlyFavourite ? removeIcon : plusIcon}
        alt="plus icon"
        onClick={(e) => {
          e.preventDefault();
          handleFavouriteButtonClick(isCurrentlyFavourite, aircraft);
        }}
      />
    );
  };

  getAircraftStatusTag = (status) => {
    const statusMap = {
      setup: {
        text: 'SETUP',
        style: 'statusTagSetup',
      },
      locked: {
        text: 'LOCKED',
        style: 'statusTagLocked',
      },
      archived: {
        text: 'ARCHIVED',
        style: 'statusTagArchived',
      },
      deleted: {
        text: 'DELETED',
        style: 'statusTagDeleted',
      },
      demo: {
        text: 'DEMO',
        style: 'statusTagDemo',
      },
      'mel only': {
        text: 'MEL ONLY',
        style: 'statusTagDemo',
      },
    };
    if (!status || status === 'Active') {
      return this.renderFavouriteButton();
    }
    return (
      <div className={styles.actionsWrapper}>
        {this.renderFavouriteButton()}
        <div className={styles[statusMap[status.toLowerCase()].style]}>
          <div>{statusMap[status.toLowerCase()].text}</div>
        </div>
      </div>
    );
  };

  renderSummary() {
    const {
      intl: { formatMessage },
      operators,
    } = this.props;

    const { localAircraft: summary } = this.state;
    const aircraftAPUEnabled = summary ? summary.apu_installed : false;

    summary.flight_seconds_remaining = summary.widgets?.flight_hours?.remaining;
    summary.cycles_remaining = summary.widgets?.cycles?.remaining;
    summary.days_remaining = summary?.estimated_next_due_date;
    summary.apu_seconds_remaining = summary.widgets?.apu_hours?.remaining;

    const defectsNameOverride = operators.find((op) => op?.id === summary?.operator_id)?.operator_setting
      ?.defect_name_plural;

    return (
      <Col xs={12} className={styles.xxsColParent}>
        <Row className={styles.dataRow}>
          <Col xs={13}>
            <div className={styles.remainingTitle}>{formatMessage({ id: 'title.remaining' })}</div>
            <ProgressBarsAircraft mxItem={summary} hasAPU={aircraftAPUEnabled} unitsOnly data-test="progressBars" />
          </Col>
          <Col xs={{ span: 10, offset: 1 }}>
            <div className={styles.summaryItemWrapperUpper}>
              <span className={summary.open_defects_count > 0 ? styles.defectCount : styles.marginRight}>
                {summary.open_defects_count}
              </span>
              <span className={styles.statusTitle}>
                {defectsNameOverride || formatMessage({ id: 'title.defects' })}
              </span>
            </div>
            <div className={styles.summaryItemWrapper}>
              <span className={summary.limitations_count > 0 ? styles.limitationsCount : styles.marginRight}>
                {summary.limitations_count}
              </span>
              <span className={styles.statusTitle}>{formatMessage({ id: 'title.limitations' })}</span>
            </div>
          </Col>
        </Row>
        <Row className={styles.aircraftLocation} data-test="aircraftLocation">
          {formatMessage({ id: 'title.location' })}:{' '}
          <TFTag color="blue" margin="0 0 0 6px">
            {summary.flight_status === 'on_ground'
              ? summary.last_airport || null
              : formatMessage({ id: 'status.inFlight' })}
          </TFTag>
        </Row>
      </Col>
    );
  }

  render() {
    const { localAircraft: summary, showSummary, airport, maintenanceForCRS, timeoutID } = this.state;
    const {
      aircraft,
      clickable,
      intl: { formatMessage },
      preAircraft,
      isFavourite,
      operators,
    } = this.props;
    let estDueTime = 0;
    let estDueDisplay;
    if (summary) {
      estDueTime = estDueTimeOfAircraft(summary.estimated_next_due_date);
      estDueDisplay = `${estDueTime} ${formatMessage({ id: 'text.days' })}`;
      if (estDueTime === '<1' || estDueTime === 1) estDueDisplay = `${estDueTime} ${formatMessage({ id: 'text.day' })}`;
    }
    let aircraftBillStatus = summary?.billing_status;
    if (summary?.locked) aircraftBillStatus = 'Locked';
    const isMobile = window.innerWidth < 451;

    const operatorSetting = operators?.find((op) => op?.id === summary?.operator_id)?.operator_setting;
    const approvalReleaseMode = operatorSetting?.approval_release_mode;
    const approvalReleaseName = operatorSetting?.approval_release_name;
    const crsShortName = operatorSetting?.crs_short_name;

    let releaseText = crsShortName;
    if (['after_approval_release_required', 'after_each_approval_release_required'].includes(approvalReleaseMode)) {
      releaseText = approvalReleaseName;
    }

    const aircraftCard = (
      <Card
        title={
          summary && (
            <div className={styles.titleWrapper}>
              <div className={styles.statusWrapper}>
                <div className={styles.aircraftRegWrapper}>
                  {summary?.fleet_number ? (
                    <span className={styles.aircraftRegText}>
                      {summary?.fleet_number} ({summary.registration})
                    </span>
                  ) : (
                    <span className={styles.aircraftRegText}>{summary.registration}</span>
                  )}
                  {summary.flight_status === 'in_flight' && (
                    <TFTag colour="blue" margin="0 0 0 10px">
                      {`• ${formatMessage({ id: 'status.inFlight' })} ${
                        summary?.departure_airport &&
                        summary?.departure_airport?.ident &&
                        summary?.destination_airport &&
                        summary?.destination_airport?.ident
                          ? `(${summary.departure_airport.ident} - ${summary.destination_airport.ident})`
                          : ''
                      }`}
                    </TFTag>
                  )}
                  {summary.flight_status === 'on_ground' && (
                    <TFTag margin="0 0 0 10px">•{formatMessage({ id: 'status.onGround' })}</TFTag>
                  )}
                </div>
                <div>
                  <span className={styles.designatorTextNoMargin}>
                    {summary.aircraft_type.manufacture} {summary.aircraft_type.model} (
                    {summary.aircraft_type.designator})
                  </span>
                </div>
              </div>
              {this.getAircraftStatusTag(aircraftBillStatus)}
            </div>
          )
        }
        className={this.getAircraftCardStyles()}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {!aircraft && (
          <div className={styles.loadRegWrapper}>
            <div className={styles.loadingWrapper}>
              <Loading loading contain />
            </div>
            <span className={styles.preAircraftReg}>
              {preAircraft?.fleet_number
                ? `${preAircraft?.fleet_number} (${preAircraft?.registration})`
                : preAircraft?.registration}
            </span>
          </div>
        )}
        {summary && (
          <div>
            <Row>
              <Col xs={12} className={`${styles.lftCol} ${styles.xxsCol}`}>
                <Row>
                  <div
                    className={styles.aircraftImg}
                    style={{
                      backgroundImage: `url(${config.get('api')}${summary.aircraft_type.aircraft_image_url})`,
                    }}
                    alt={summary.registration}
                  />
                  <div className={styles.aircraftStatus}>
                    <Col xs={21}>
                      <div className={styles.statusLabel}>{formatMessage({ id: 'title.statusCaps' })}</div>
                      {(summary.maintenance_status === 'serviceable' || summary.maintenance_status === 'ok') && (
                        <TFTag colour="green">{formatMessage({ id: 'status.serviceable' })}</TFTag>
                      )}
                      {summary.maintenance_status === 'critical' && (
                        <TFTag colour="orange">{formatMessage({ id: 'status.inTolerance' })}</TFTag>
                      )}
                      {(summary.maintenance_status === 'overdue' || summary.maintenance_status === 'unserviceable') &&
                        maintenanceForCRS && (
                          <div className={styles.tagUnserviceable}>
                            {isMobile ? (
                              <TFTag colour="red">{formatMessage({ id: 'status.unserviceable' })}</TFTag>
                            ) : (
                              <TFTag dataTestId="unserviceableMissingCRS" colour="red">
                                {formatMessage({ id: 'status.unserviceable_missing_crs' }, { releaseText })}
                              </TFTag>
                            )}
                          </div>
                        )}
                      {(summary.maintenance_status === 'overdue' || summary.maintenance_status === 'unserviceable') &&
                        !maintenanceForCRS && (
                          <div className={styles.tagUnserviceable}>
                            <TFTag colour="red">{formatMessage({ id: 'status.unserviceable' })}</TFTag>
                          </div>
                        )}
                      {summary.maintenance_status === 'unknown' && (
                        <TFTag colour="grey">{formatMessage({ id: 'status.unknown' })}</TFTag>
                      )}
                    </Col>
                    {summary.estimated_next_due_date !== null && (
                      <Col xs={3}>
                        <div className={styles.statusLabel}>{formatMessage({ id: 'title.estimatedDueCaps' })}</div>
                        <TFTag className={styles.estimateLabel}>{estDueDisplay}</TFTag>
                      </Col>
                    )}
                  </div>
                </Row>
              </Col>
              {(showSummary && !timeoutID) || isFavourite ? this.renderSummary() : null}
              {timeoutID && (
                <div className={styles.loaderOpacity}>
                  <Loading className={styles.mapImage} loading contain />
                </div>
              )}
            </Row>
            {!showSummary &&
              !timeoutID &&
              !isFavourite &&
              (airport && summary.flight_status === 'on_ground' ? (
                <div>
                  <img
                    src={this.getGoogleMapUrl()}
                    alt="Last Airport"
                    className={
                      summary.maintenance_status === 'ok' ? `${styles.mapImage} ${styles.okMapImage}` : styles.mapImage
                    }
                  />
                  <span className={styles.locationWrapper}>
                    <div className={styles.locationName} style={this.getAirportNameMargin()}>
                      <TFTag colour="blue" margin="0 8px 0 0">
                        {airport.name}
                      </TFTag>
                    </div>
                    <img src={locationMarker} alt="location" />
                  </span>
                </div>
              ) : (
                <img
                  src={skyImage}
                  alt="Unknown Location"
                  className={
                    summary.maintenance_status === 'ok' ? `${styles.mapImage} ${styles.okMapImage}` : styles.mapImage
                  }
                />
              ))}
          </div>
        )}
      </Card>
    );
    return clickable && (aircraft || preAircraft?.id) ? (
      <>
        <Link to={`/aircraft/${aircraft?.id || preAircraft?.id}/overview`}>{aircraftCard}</Link>
      </>
    ) : (
      aircraftCard
    );
  }
}

export default injectIntl(
  connect(({ airports, userSettings }) => ({
    airportsMap: airports.airportsMap,
    operators: userSettings?.details?.operators,
    favouriteAircraftIds: userSettings?.details?.dash_preferences?.favourite_aircraft_ids,
  }))(AircraftCard),
);
