import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Loading } from '@arcflight/tf-component-library';
import { useDispatch } from 'react-redux';
import getOperatorSetting from '../../utils/getOperatorSetting';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import FlexWrapper from '../DefectDrawer/components/FlexWrapper';
import { fetchGeneratedPdfDocument } from '../../services/api';
import convertPdfResponseToBlob from '../../utils/pdf';
import { ToastTypes, ToastCategories, addToast } from '../../models/toasts';

interface DocumentLinkProps {
  id: string;
  aircraftId: string;
  number?: string;
  showLoadingText?: boolean;
  docType: 'srp' | 'jl';
}

const defaultProps = {
  number: '',
  showLoadingText: false,
};

const SRPIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 2px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledLinkWrapper = styled.div`
  position: relative;
  a {
    color: #126fd6;
    flex-shrink: 1;
  }
`;

const StyledButton = styled.button`
  color: #126fd6;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
`;

const DocumentLink: React.FC<DocumentLinkProps> = ({ id, number, aircraftId, showLoadingText, docType }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [isFetching, setIsFetching] = useState(false);
  const hideSRPLink = getOperatorSetting('hide_srp_links', aircraftId);
  const linkText =
    docType === 'srp' ? getOperatorSetting('srp', aircraftId) : getOperatorSetting('journey_log', aircraftId);
  if (docType === 'srp' && hideSRPLink) return null;

  const handleDocClick = async (e, docId): Promise<void> => {
    e.stopPropagation();
    try {
      setIsFetching(true);
      const res = await fetchGeneratedPdfDocument(docId, docType);
      const blob = convertPdfResponseToBlob(res);
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');

      setIsFetching(false);
    } catch (err) {
      const jsonObject = JSON.parse(atob(err.error));
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.generateDocError' }),
            message: jsonObject.error || formatMessage({ id: 'message.generateDocumentError' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
      setIsFetching(false);
    }
  };
  if (isFetching)
    return (
      <SpinnerWrapper data-testid="spinner-wrapper">
        <Loading hasTransparentBackground loading={isFetching} contain size={20} />
        {showLoadingText && <span data-testid="loading-text">Generating PDF, please wait</span>}
      </SpinnerWrapper>
    );
  return (
    <StyledLinkWrapper data-testid="DocumentLink">
      <StyledButton
        data-testid="DocumentLink-button"
        type="button"
        onClick={(e): Promise<void> => handleDocClick(e, id)}
        tabIndex={0}
      >
        <FlexWrapper alignItems="center">
          <span data-testid="DocumentLink-text">{linkText}</span>
          <SRPIcon data-testid="DocumentLink-icon" src={pdfIcon} alt="pdf icon" />
          {number && <span data-testid="DocumentLink-number">{number}</span>}
        </FlexWrapper>
      </StyledButton>
    </StyledLinkWrapper>
  );
};

DocumentLink.defaultProps = defaultProps;

export default DocumentLink;
